import React, { Component } from 'react';
import '../../assets/css/SearchBar.css';
import styled from "@emotion/styled";
import SearchBar from './SearchBar';
import ExportRecoveryAgents from './ExportRecoveryAgents';

class TabSearchBar extends Component {
  constructor() {
    super();
    this.state = {};
    this.initialState = this.state;
  }

  render() {
    const { tabs, className, children } = this.props;
    return (
      <div className={className}>
        <div className="Tabs">
          {
            tabs?.map(({ title, subtitle, onClick, active }) => <div
              key={title}
              onClick={() => onClick(title)} className={"Tab " + (active ? "active" : "")}>
              <h6>{title}</h6>
              <p>
                {
                  typeof subtitle === 'function' ? subtitle()
                    : (typeof subtitle === 'string' ? subtitle : null)
                }
              </p>
              {active && <div className='TabIndicator' />}
            </div>)
          }
        </div>
        {children}
        <div style={{ display: 'flex' }}>
          <ExportRecoveryAgents
            agentList={this.props.agentList}
            fromDate={this.props.fromDate}
            toDate={this.props.toDate}
          />
        </div>

        <SearchBar
          count={3}
          disableTitle
          search={this.props.fetchAgentsListHandler}
          setFromDate={this.props.setFromDate}
          setToDate={this.props.setToDate}
          campaignsList={this.props.campaignsList}
        />

        {/* {this.props.type === "dndList" && <div style={{ display: 'flex' }}>
          <PopTwo refreshList={this.props.refreshList} />
        </div>}
        {this.props.type === 'BlackList' && (
          <div style={{ display: 'flex' }}>
            <AddBlackList refreshList={this.props.refreshList} />
          </div>
        )}  */}
      </div >
    );
  }
}

export default styled(TabSearchBar)`
  height: 54px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;  
  align-items: center;
  flex-direction: row;

  .Tabs {
    display: flex;
    flex-direction: row;
  }

  .Tab {
    cursor: pointer;
    position: relative;
    min-width: 136px;
    height: 54px;
    padding: 13px 16px;
    background-color: ${props => props.theme.colors.tabColor};
    display: flex;
    flex-direction: column;
    justify-content: center;

    h6 {
      margin: 0;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: ${props => props.theme.colors.tabTextColor};
    }

    p {
      font-size: 11px;
      font-weight: bold;
      margin: 0;
      letter-spacing: 1.2px;
      color: ${props => props.theme.colors.tabTextColor};
      margin: 0;
      opacity: 0.8;
      font-family: Montserrat;
      font-weight: 600;
      line-height: 1.4;
      text-align: left;
    }

    &.active {
      background-color: ${props => props.theme.colors.tabSelectedColor};

      h6, p {
        color: ${props => props.theme.colors.tabSelectedTextColor};
      }
    }
  }

  .TabIndicator {
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.colors.tabSelectedTextColor};
  }

  .exportbutton {
    border-radius: 4px;
    border: 2px solid #07aa3f;
    font-size: 14px;
    font-weight: bold;
    color: #07aa3f;
    background-color: #fff;
    padding: 3px 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }
  
  .exportbutton:hover {
    background-color: #07aa3f;
    color: #fff;
  }
`;
