import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import UpgradePlanPopup from '../upgradePlanPopup';
import { useLocation } from 'react-router-dom';
import { GET } from '../../../services/HttpRequests';

const role = localStorage.getItem('doosra-biz-user-role');
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

export default function TrialPeriod() {
  const location = useLocation();
  const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
  const [tenure, setTenure] = useState(null);
  const [plan, setPlan] = useState(null);
  const [product, setProduct] = useState(null);
  const [channelPartner, setChannelPartner] = useState(null);
  let daysCount = 30;

  const isKYCCompleted = () =>
    JSON.parse(localStorage.getItem('is_kyc_completed'));
  const isSuperAdmin = () =>
    localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';
  let isFreemiumUsed = () =>
    JSON.parse(localStorage.getItem('is_freemium_plan_used'));
  daysCount = localStorage.getItem('organisation_expiry_date');
  const now = moment();
  const end = moment(daysCount);
  const days = Math.ceil(end.diff(now, 'hours')/24);

  const fetchPlanDetails = async () => {
    try {
      const res = await GET(`${orgUrl}v1/organisation/plan`, {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      });

      setPlan(res?.data?.response?.planDetails?.plan);
      setProduct(res?.data?.response?.planDetails?.product);
      setTenure(res?.data?.response?.planDetails?.tenure);
      setChannelPartner(res?.data?.response?.planDetails?.channelPartner);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  if (isSuperAdmin() || daysCount === 'undefined') {
    return null;
  }
  console.log('---------isFreemiumUsed----------', isFreemiumUsed);
  if (!isFreemiumUsed()) {
    return null;
  }

  if (location.pathname === '/payment') {
    return null;
  }

  return (
    <>
      <UpgradePlanPopup
        open={isUpgradePlanPopupOpen}
        close={() => setIsUpgradePlanPopupOpen(false)}
        currentPlan={plan || null}
        currentTenure={tenure}
        currentProduct={product}
        channelPartner={channelPartner}
      />
      <div className="free-trial-banner">
        <span className="free-trial-text">
          Your freemium access will expire in {days} days
          {role !== 'USER' && (
            <span
              className="free-trial-upgrade"
              onClick={() => {
                setIsUpgradePlanPopupOpen(true);
              }}
            >
              Upgrade now
            </span>
          )}
        </span>
      </div>
      <div style={{ height: '36px' }}></div>
    </>
  );
}
