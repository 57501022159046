import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/alohaaLogoAndBottomText.png';
import failIcon from '../../assets/json/fail.json';
import successIcon from '../../assets/json/success.json';
import loaderIcon from '../../assets/json/loader.json';
import Lottie from 'react-lottie';
import { POST } from '../../services/HttpRequests';
import { getTopBarData } from '../../actions/topBarActions';
import { connect } from 'react-redux';

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id')
const failOption = {
  loop: true,
  autoplay: true,
  animationData: failIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const successOption = {
  loop: true,
  autoplay: true,
  animationData: successIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const loadingOption = {
  loop: true,
  autoplay: true,
  animationData: loaderIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ShipRocketSubscriptionApproval = (props) => {
  const [paymentStatus, setPaymentStatus] = useState('processing');
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const maxRetries = 10;

  const history = useHistory();

  useEffect(() => {
    // const referrerURL = document.referrer;
    // if (referrerURL.includes('zoho.in')) {
    //   setRedirectedFromZoho(true);
    // }
    // if (!redirectedFromZoho) {
    //   if (localStorage.getItem('doosra-business-sessionToken')) {
    //     history.push('/');
    //   } else {
    //     history.push('/login');
    //   }
    // }
  }, []);

  const getShipRocketToken = () => {
    const queryParams = new URLSearchParams(props.location.search);
    return queryParams.get('token');
  }

  const fetchStatus = async (retryCount = 0) => {
    if (organisationId) {
      try {

        // const confirmationData = {
        //   organisationId,
        // };
        // if (localStorage.getItem('add-on-purchase')) {
        //   confirmationData.addonType = localStorage.getItem('add-on-purchase');
        // }

        // if (localStorage.getItem('plan_before_upgrade')) {
        //   confirmationData.currentPlanId = localStorage.getItem(
        //     'plan_before_upgrade'
        //   );
        // }
        const currentRoute = window.location?.pathname;
        if (currentRoute?.trim()?.replace(/\/$/, '') === "/ship-rocket/subscription/retry") {
          await POST(
            orgUrl + `v1/external/ship-rocket/retry-payment`,
          );
        } else {
          const reqBody = { token: getShipRocketToken() }
          await POST(
            orgUrl + `v1/external/ship-rocket/process-payment`,
            reqBody
          );
          // const data = response?.data.response;
        }
        // if (data.paymentSuccess) {
          // localStorage.removeItem('plan_before_upgrade');
          // localStorage.removeItem('add-on-purchase');
          setPaymentStatus('success');
          props.getTopBarData();
        // } else {
        //   if (retryCount < maxRetries) {
        //     setTimeout(() => fetchStatus(retryCount + 1), 10000); // Retry after 10 seconds
        //   } else {
        //     setPaymentStatus('failed');
        //   }
        // }
      } catch (error) {
        // if (retryCount < maxRetries) {
        //   setTimeout(() => fetchStatus(retryCount + 1), 10000); // Retry after 10 seconds
        // } else {
          setPaymentErrorMessage(error?.response?.data?.error?.reason ?? "Something went wrong!")
          setPaymentStatus('failed');
        // }
      }
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <div style={{ width: '100%', minHeight: '100vh' }}>
      <div>
        <div
          style={{
            minHeight: '175px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img className="login-container-logo" alt="" src={Logo} />
        </div>
        {!organisationId && (<div
          style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '50px'
          }}
        >
          <Lottie options={successOption} height={300} width={300} />
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '34px',
              fontWeight: 'bold',
              letterSpacing: '-1.02px',
              color: '#07aa3f',
            }}
          >
            Payment Successful!
          </p>
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '20px',
              letterSpacing: '-1.02px',
              padding: '25px'
            }}
          >
            Thank you for choosing Alohaa! An email with your login link has been sent. Please check your inbox to access your account.
          </p>
        </div>)}
        {organisationId && paymentStatus === 'processing' && (
          <div
            style={{
              minHeight: '600px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Lottie options={loadingOption} height={400} width={400} />
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '34px',
                fontWeight: 'bold',
                letterSpacing: '-1.02px',
                color: '#454545',
              }}
            >
              Processing payment
            </p>
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '500',
                letterSpacing: '-0.72px',
                color: '#454545',
              }}
            >
              This may take a moment
            </p>
          </div>
        )}
        {organisationId && paymentStatus === 'success' && (
          <div
            style={{
              minHeight: '600px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Lottie options={successOption} height={400} width={400} />
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '34px',
                fontWeight: 'bold',
                letterSpacing: '-1.02px',
                color: '#07aa3f',
              }}
            >
              Payment Successful!
            </p>
            <button
              style={{
                width: '240px',
                height: '54px',
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '500',
                letterSpacing: '-0.72px',
                backgroundColor: '#07aa3f',
                color: '#fff',
                borderRadius: '8px',
                border: 'none',
              }}
              onClick={() => {
                history.push('/');
              }}
            >
              Go to Dashboard
            </button>
          </div>
        )}
        {organisationId && paymentStatus === 'failed' && (
          <div
            style={{
              minHeight: '600px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Lottie options={failOption} height={400} width={400} />
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '34px',
                fontWeight: 'bold',
                letterSpacing: '-1.02px',
                textAlign: 'center',
                color: '#ed3833',
              }}
            >
              {paymentErrorMessage}
            </p>
            <p>
              <br /> Please contact <a href="mailto:support@alohaa.ai">support@alohaa.ai</a> for further help.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, { getTopBarData })(ShipRocketSubscriptionApproval);
