import React, { useCallback, useEffect } from "react";
import { Handle, Position } from "reactflow";
import Cancel from "../../assets/images/cancel.png";
import Dialpad from "../../assets/images/dialpad.png";
import { SET_SIDEBAR_OPEN } from "../../actions/callFlowActions";
import { useDispatch } from 'react-redux';
import Settings from "../../assets/images/settings.png"

const handleStyle = { left: 10 };


const Menu = (props) => {
  const data = props?.data;
  const id = props?.id;
  const onDelete = props?.onDelete;
  const updateNodeData = props?.updateNodeData;
    const menuOptions = data?.label || [];
    const dispatch = useDispatch();
    useEffect(() => {
      if(props?.data?.additionalInfo) {
        updateNodeData(props)
      }
    }, [props?.data?.additionalInfo])
 return (
    <>
      <Handle type="target" position={Position.Top} />
      <div style={{ minHeight: '104px', minWidth: "248px", backgroundColor: "#F3FBF5",  borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        <div style={{ minHeight: '52px', minWidth: "170px", backgroundColor: '#EAEAEA', display: "flex", justifyContent: "space-between",  alignItems: "center" }}>
            <div style={{marginLeft: 10 }}>
            <img style={{ width: "20px", height: "20px",  }} src={Dialpad} /> 
            <text style={{ fontSize: "20px", color: "#5C5C5C", marginLeft: 10, fontWeight: "600" }}>Menu</text>
            </div>
            <div>
              <img onClick={() => {
                dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: true, menuName: "MENU", id, data: menuOptions  }})
              }} style={{ width: "20px", height: "20px", marginRight: 10 }} src={Settings} />
              <img onClick={() => onDelete(id)} style={{ width: "20px", height: "20px", marginRight: 10 }} src={Cancel} />
            </div>
        </div>
        <div onClick={() => {
          dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: true, menuName: "MENU", id, data: menuOptions  }})
        }} style={{ minHeight: '52px', minWidth: "248px", backgroundColor: 'white', display: 'flex', alignItems: menuOptions?.length > 0 ? "flex-end" : "center", justifyContent: "space-evenly" }}> 
          {menuOptions?.length <= 0 && <text style={{ fontSize: "16px", fontWeight: '500', color: "#9B9B9B" }}>{"Configure menu options"}</text> }
          {
            menuOptions?.map((option, index) => (
              <div key={index} className="MenuOptions"
              style={{ position: "relative" }}
              >
                {option}
                <Handle 
                type="source"
                id={`${id}-${index}`} // Unique handle ID // Ensure key remains unique for dynamic rendering
                style={{
                  background: '#555',
                  position: 'absolute',  // Position absolute relative to the option div
                  left: '50%',
                  transform: 'translateX(-50%)', // Center the handle
                  // zIndex: 1
                }} 
                
                position={Position.Bottom} />
              </div>
            ))
          }
        </div>
      </div>
    </>
 )

};

export default Menu;