import React, { useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import Notes from './Notes';
import Tags from './Tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import EditContactPopup from '../component/EditContactPopup';
import Tooltip from './Tooltip';
import {
  VIEW_CONTACT_BUTTON,
  ADMIN_VIEW_CONTACT_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
// import { isSuperAdminWithPermission } from '../../../utility/utility';
const userRole = localStorage.getItem('doosra-biz-user-role');

const ContactInput = (props) => {
  return (
    <div key={props.index} className="PopupInput" style={{ marginTop: 8 }}>
      {props.number}
    </div>
  );
};

export default function ViewContactPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [contactName, setContactName] = useState(props?.contactInfo?.name);
  const [contactNumbers, setContactNumbers] = useState(
    props?.contactInfo?.phoneNumbers
  );
  const [error, setError] = useState(null);

  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className="PopupContainer"
        style={{
          maxHeight: '600px',
          overflow: 'scroll',
          marginBottom: 30,
        }}
      >
        <div className="PopupHeaderContainer">
          <p>View Contact Profile</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setPopupOpen(false)}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">Contact Name</label>
            <div className="PopupInput">{contactName}</div>
          </div>
          <div className="PopupInputContainer" style={{ overflow: 'auto' }}>
            <label className="PopupInputLabel">Contact Numbers</label>
            {contactNumbers?.length > 0 &&
              contactNumbers.map((item, index) => (
                <ContactInput number={item} index={index} />
              ))}
          </div>
          {error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {error}
            </div>
          ) : null}

          <Tags
            contactId={props?.contactInfo?._id}
            action="VIEW"
            userId={props?.userId}
          />

          <Notes
            contactId={props?.contactInfo?._id}
            action="VIEW"
            userId={props?.userId}
          />
          <div style={{ marginTop: '30px' }}>
            {userRole === 'USER' && (
              <EditContactPopup
                key={props.key}
                contactInfo={props.contactInfo}
                editFrom="callLog"
                getList={props.getList}
                stopViewPopup={setPopupOpen}
              />
            )}
            <button
              className="ButtonCancelFullWidth"
              onClick={() => setPopupOpen(false)}
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );

  return (
    <React.Fragment>
      {props?.userName ? (
        <a
          className="AddContactNo"
          style={{ cursor: 'pointer' }}
          onClick={() => setPopupOpen(true)}
        >
          {props?.userName}
        </a>
      ) : (
        // <Tooltip
        //     tooltipText="View Contact"
        //     position="left"
        // >
        <button
          onClick={() => {
            logPostHogEvent(
              userRole === 'ADMIN'
                ? ADMIN_VIEW_CONTACT_BUTTON
                : VIEW_CONTACT_BUTTON
            );
            setPopupOpen(true);
          }}
          className="LightBlueButton"
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 17 }} />
        </button>
        // </Tooltip>
      )}

      {popper}
    </React.Fragment>
  );
}
