import React, {useState, useEffect} from "react";
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { alpha, styled } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { PUT, GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
const role = localStorage.getItem('doosra-biz-user-role');
const isFreeTrail = localStorage.getItem('is_free_trial_enabled');
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const planAvailable = localStorage.getItem('is_plan_available');
const showPaymentHistory = localStorage.getItem('show_payment_history');
export const TOPBAR_DATA_SUCCESS = 'TOPBAR_DATA_SUCCESS';

const AgentSettingsPage = (props) => {
    const dispatch = useDispatch();
    const [isToggled, setIsToggled] = useState(props.agentEmailVerification);

    useEffect(() => {
      setIsToggled(props.agentEmailVerification);
    }, [props.agentEmailVerification]);

    const tabs = [
        {
          title: 'Account',
          active: false,
          subtitle: ``,
          onClick: () => props.history.push('/account-settings'),
        },
        {
          title: 'Operating Schedule',
          active: false,
          subtitle: ``,
          onClick: () => props.history.push('/off-hours'),
        },
        {
          title: 'Call Settings',
          active: false,
          subtitle: ``,
          onClick: () => props.history.push('/call-settings'),
        },
        {
          title: 'Agent Settings',
          active: true,
          subtitle: ``,
          onClick: () => props.history.push('/agent-settings'),
        },
    ];
    if (planAvailable === true || planAvailable === 'true') {
        if (showPaymentHistory) {
          tabs.push({
            title: 'Payment History',
            active: false,
            subtitle: ``,
            onClick: () => props.history.push('/payment-history'),
          });
        }
        tabs.push({
          title: 'KYC',
          active: false,
          subtitle: ``,
          onClick: () => props.history.push('/kyc'),
        });
      }
  const updateAgentEmailVerification = async (value) => {
    const body = {
      isAgentEmailVerificationRequired: value
    }
    PUT(`${orgUrl}v1/organisation/update-agent-email-verification`, {}, body).then((res) => {
        if (res?.data?.success) {
          toast.success('Successfully Updated');
          GET(`${orgUrl}v1/organisation/basic/details`, {
            organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
          })
            .then((res) => {
              return res.data.response;
            })
            .then((res) => {
              // console.log('data is', res.organisation);
              let balance = 0;
              if (
                res.organisation &&
                res.organisation.balance &&
                res.organisation.balance.amount
              ) {
                balance = res.organisation.balance.amount;
              }
              const data = { ...res.organisation, ...res.globalConfigurations };
              dispatch({
                type: TOPBAR_DATA_SUCCESS,
                data: data,
                balance: balance,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }).catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      })
  }
  const handleToggle = () => {
    setIsToggled((prevState) => {
      const newState = !prevState;
      updateAgentEmailVerification(newState);
      return newState;
    });
  };
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: green[800],
      '&:hover': {
        backgroundColor: alpha(green[800], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[800],
    },
  }));
  const label = { inputProps: { 'aria-label': 'Color switch demo' } };
  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          {role !== 'USER' && !isFreeTrail && <TabSearchBar tabs={tabs} />}
          <div style={{ width: "40vw", height: "auto", border: "1px solid #d2d3d4", boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.16)", marginTop: "40px", marginLeft: "20px", padding: "10px" }}>
          <h2 style={{ fontSize: "18px", fontFamily: "Montserrat", fontWeight: "bold", marginLeft: "20px", marginTop: "20px" }}>Agent Email Verification</h2>
            <div style={{ fontSize: "14px", color: "#666", fontFamily: 'Montserrat', fontWeight: "normal", fontStretch: "normal", lineHeight: "1.5", letterSpacing: "0.5px" , marginLeft: "20px", marginTop: "20px"}}>
            Enabling this option sends a verification email during onboarding to prevent incorrect email entries. If disabled, accounts activate automatically without verification.
            </div>
            <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", marginTop: "20px" }}>
              <div style={{ marginLeft: "40px"  }}>
                <GreenSwitch 
                  onChange={handleToggle}
                  checked={isToggled}
                />
              </div>
                <h2 style={{ fontSize: "18px", fontFamily: "Montserrat", color: "#666", fontWeight: "normal", marginLeft: "40px" }}>Is agent email verification required?</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


// border: solid 1px #d2d3d4;
//     border-radius: 4px;
//     // max-width:300px;
//     padding:12px;
//     flex:1;
//     box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
const mapStateToProps = (state) => ({
    sidebarClosed: state.sidebar.sidebarClosed,
    agentEmailVerification: state.topbar.agentEmailVerification,
});
  
export default commonStyle(connect(mapStateToProps, {})(AgentSettingsPage));

