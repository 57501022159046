import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PersonWhite from '../../../assets/images/personWhite.png';
import ContentCopy from '../../../assets/images/content_copy.svg';
import { toast } from 'react-toastify';
import { useTheme } from '../../../providers/customeThemeProvider';
import { connect, useSelector } from 'react-redux';
import { setIsOpen } from '../../../actions/dailPadActions';

const ProfileButton = styled.button`
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileInfo = styled.p`
  position: absolute;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: black;
`;

const Profile = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isDailPadOpen = useSelector((state) => state.dailpad.isOpen);

  useEffect(() => {
    if (isDailPadOpen) {
      setIsOpen(false);
    }
  }, [isDailPadOpen]);
  return (
    <>
      <ProfileButton
        className="TopbarLogo"
        onClick={() => {
          setIsOpen(!isOpen);
          props.setIsOpen({ isOpen: false });
        }}
      >
        <img src={PersonWhite} width={28} height={28} />
      </ProfileButton>
      <div
        style={{
          background: theme.themeType === 'light' ? 'white' : '#282828',
          display: isOpen ? 'block' : 'none',
          width: '250px',
          height:
            localStorage.getItem('doosra-biz-user-doosra-number') !== 'NA' &&
              localStorage.getItem('doosra-biz-user-doosra-number') !== 'null' &&
              localStorage.getItem('doosra-biz-user-doosra-number') !==
              'undefined'
              ? '100px'
              : '79px',
          borderRadius: '4px',
          background: '#FFF',
          boxShadow: '0px 0px 4px 0px black',
          position: 'absolute',
          right: '1%',
          top: '100%',
          zIndex: 10
        }}
      >
        {localStorage.getItem('doosra-biz-user-role') === 'USER' ? (
          <>
            <ProfileInfo
              style={{
                margin: '16px 72px 41px 16px',
                color: '#9B9B9B',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
              }}
            >
              {localStorage.getItem('doosra-biz-user-name') || ''}
            </ProfileInfo>
            <ProfileInfo
              style={{
                margin: '39px 49px 18px 16px',
                color: theme.themeType === 'light' ? '#9B9B9B' : '#9B9B9B',
                fontFamily: 'Montserrat',
                fontSize: '11px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
              }}
            >
              {localStorage.getItem('doosra-biz-user-email') || ''}
              <img
                src={ContentCopy}
                style={{
                  position: 'absolute',
                  left: '200px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    localStorage.getItem('doosra-biz-user-email')
                  );
                  toast.success('copied');
                }}
              ></img>
            </ProfileInfo>
            {localStorage.getItem('doosra-biz-user-doosra-number') !== 'NA' &&
              localStorage.getItem('doosra-biz-user-doosra-number') !==
              'null' &&
              localStorage.getItem('doosra-biz-user-doosra-number') !==
              'undefined' && (
                <ProfileInfo
                  style={{
                    margin: '60px 49px 18px 16px',
                    color: theme.themeType === 'light' ? '#9B9B9B' : '#9B9B9B',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                  }}
                >
                  {localStorage.getItem('doosra-biz-user-doosra-number') || ''}
                  <img
                    src={ContentCopy}
                    style={{
                      position: 'absolute',
                      left: '200px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        localStorage.getItem('doosra-biz-user-doosra-number')
                      );
                      toast.success('copied');
                    }}
                  ></img>
                </ProfileInfo>
              )}
          </>
        ) :
          <>
            <ProfileInfo
              style={{
                margin: '16px 72px 41px 16px',
                color: '#9B9B9B',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                width: "220px"
              }}
            >
              {localStorage.getItem('doosra-biz-organisation-name') || ''}
            </ProfileInfo>
            <ProfileInfo
              style={{
                margin: '50px 49px 18px 16px',
                color: theme.themeType === 'light' ? '#9B9B9B' : '#9B9B9B',
                fontFamily: 'Montserrat',
                fontSize: '11px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
              }}
            >
              {localStorage.getItem('doosra-biz-user-email') || ''}
              <img
                src={ContentCopy}
                style={{
                  position: 'absolute',
                  left: '200px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    localStorage.getItem('doosra-biz-user-email')
                  );
                  toast.success('copied');
                }}
              ></img>
            </ProfileInfo>
            {localStorage.getItem('doosra-biz-user-number') !== 'NA' &&
              localStorage.getItem('doosra-biz-user-number') !==
              'null' &&
              localStorage.getItem('doosra-biz-user-number') !==
              'undefined' && (
                <ProfileInfo
                  style={{
                    margin: '72px 49px 18px 16px',
                    color: theme.themeType === 'light' ? '#9B9B9B' : '#9B9B9B',
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                  }}
                >
                  {localStorage.getItem('doosra-biz-user-number') || ''}
                  <img
                    src={ContentCopy}
                    style={{
                      position: 'absolute',
                      left: '200px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        localStorage.getItem('doosra-biz-user-number')
                      );
                      toast.success('copied');
                    }}
                  ></img>
                </ProfileInfo>
              )}
          </>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { setIsOpen })(Profile);
