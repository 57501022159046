import React, { Component } from 'react';
import styled from '@emotion/styled';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import { connect } from 'react-redux';
import { ThemeContext } from '../../../providers/customeThemeProvider';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
const role = localStorage.getItem('doosra-biz-user-role');
const KycCompleted = JSON.parse(localStorage.getItem('is_kyc_completed'));
const KycCompletedStatus = KycCompleted ? true : false;
const kycStatus = KycCompletedStatus ? 'VERIFIED' : 'PENDING';
let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
const showPaymentHistory = localStorage.getItem('show_payment_history');

class KYCPage extends Component {
  constructor() {
    super();
    this.state = {
      userRole: role,
    };
  }

  static contextType = ThemeContext;

  render() {
    const tabs = [
      {
        title: 'Account',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/account-settings'),
      },
      {
        title: 'Operating Schedule',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/off-hours'),
      },
      {
        title: 'Call Settings',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/call-settings'),
      },
      {
        title: 'Agent Settings',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/agent-settings'),
      },
    ];
    if (showPaymentHistory) {
      tabs.push({
        title: 'Payment History',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/payment-history'),
      });
    }
    tabs.push({
      title: 'KYC',
      active: true,
      subtitle: ``,
      onClick: () => console.log(''),
    });
    if (this.props.isCampaignEnabled) {
      // this.props.history.push('/account-settings');
    }

    return (
      <div className={`${this.props.className}`}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />

          <div className="ContentContainer">
            {role !== 'USER' && !isFreeTrail && <TabSearchBar tabs={tabs} />}

            <div style={{ width: '90%', margin: '16px auto' }}>
              <span style={{ fontSize: 24, fontWeight: 500, color: '#196cca' }}>
                {' '}
                {/* eKYC details */}
              </span>
              <div className="PlanBox">
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <span>eKYC Status </span>
                  <span
                    style={{
                      fontSize: '12px',
                      color: '#fff',
                      backgroundColor: KycCompletedStatus
                        ? '#07aa3f'
                        : '#f07038',
                      borderRadius: '4px',
                      padding: '4px',
                    }}
                  >
                    {kycStatus}
                  </span>
                </div>

                <p style={{ margin: 0 }}>
                  {KycCompletedStatus
                    ? `Your eKYC has been successfully verified, and your account is now active. You can now enjoy full access to all the features available in your plan.`
                    : `Your eKYC is pending. Our support executives will be getting in touch with you shortly to collect the necessary documents.`}
                </p>
                <p>
                  If you haven’t heard from anyone yet please contact us at
                  <a href="mailto:support@alohaa.ai">support@alohaa.ai</a>
                </p>
              </div>
            </div>

            <p style={{ textAlign: 'center', paddingTop: '42px' }}>
              Contact our support team at{' '}
              <a href="mailto:support@alohaa.ai">support@alohaa.ai</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isHideCustomerEnabled: state.topbar.isHideCustomerEnabled,
  isCampaignEnabled: state.topbar.isCampaignEnabled,
});

export default styled(connect(mapStateToProps, {})(KYCPage))`
  .ContentContainer {
    /* width: 98%; */
    /* margin: 1%; */
    background: ${(props) => props.theme.colors.acccountsettingBgColor};
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    overflow: auto;
  }

  .DarkModeContainer {
    display: flex;
    margin-top: 23px;
  }
  .DarkText {
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    width: 270px;
    height: 24px;
  }

  .Content {
    margin-left: 230px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .ContentFull {
    margin-left: 72px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .SMSTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns:
      110px minmax(100px, 170px) minmax(80px, 100px) minmax(200px, auto)
      60px 110px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .SMSDataTableAlignment {
    display: grid;
    grid-template-columns:
      110px minmax(100px, 170px) minmax(80px, 100px) minmax(200px, auto)
      60px 110px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .CallsTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns: 110px 110px 175px 130px 140px 100px auto 60px 110px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .CallsDataTableAlignment {
    display: grid;
    grid-template-columns: 110px 110px 175px 130px 140px 100px auto 60px 110px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .DoosraNumbersTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns: 150px 100px 200px auto 100px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .DoosraNumbersDataTableAlignment {
    display: grid;
    grid-template-columns: 150px 100px 200px auto 300px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .UsersTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 122px 116px 90px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .UsersTableAlignmentAdj {
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 130px 110px;
  }

  .UsersDataTableAlignment {
    display: grid;
    /* grid-template-columns: 48px 248px 250px 350px auto auto 100px; */
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 122px 116px 90px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .UsersDataTableAlignmentAdj {
    grid-template-columns:
      90px 80px minmax(100px, 170px) 110px 110px 110px 150px minmax(100px, auto)
      auto 130px 110px;
  }

  .TableHeader {
    font-size: 12px;
    font-weight: 600;
    margin: auto 0;
    color: var(--secondary-color1);
  }

  .TableContent {
    font-size: 13px;
    font-weight: 500;
    /* padding: 5px; */
    word-break: break-word;
    margin-bottom: 0;
    /* margin: 5px 0; */
    align-self: center;
  }

  .TableEndElement {
    text-align: end;
    padding-right: 10px;
  }

  .HR {
    border-top: 1px solid var(--mid-grey-color);
    width: 98%;
    margin: auto;
  }

  .HR.TableRowDivider {
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  .ReassignButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--blue-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .UnassignButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--yellowish-orange-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .DeactivateButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .ActivateButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--text-green-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .TabelContainer {
    /* min-height: 82%; */
  }

  .NotFoundContainer {
    text-align: center;
    text-align: -webkit-center;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
  }

  .NotFound {
    font-size: 10vw;
    color: ${(props) => props.theme.colors.primaryColor};
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  .NotFoundText {
    font-size: 3vw;
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  .RedirectLink {
    font-size: 1vw;
    color: #32abbc;
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-decoration: underline;
  }

  @media only screen and (max-width: 900px) {
    .ContentContainer {
      width: 98%;
      margin: 1%;
      height: calc(100vh - 100px);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
    .SMSTableAlignment {
      width: fit-content;
      grid-template-columns: 48px 100px 100px 150px 200px 100px 100px;
    }
    .SMSDataTableAlignment {
      width: fit-content;
      grid-template-columns: 48px 100px 100px 150px 200px 100px 100px;
    }
    .DoosraNumbersTableAlignment {
      grid-template-columns: 48px 100px 100px 100px 150px 300px;
    }
    .DoosraNumbersDataTableAlignment {
      grid-template-columns: 48px 100px 100px 100px 150px 300px;
    }
    .UsersTableAlignment {
      grid-template-columns: 48px 60px 125px 125px 200px 100px 100px;
    }
    .UsersDataTableAlignment {
      grid-template-columns: 48px 60px 125px 125px 200px 100px 100px;
    }
    .TabelContainer {
      height: auto;
    }
  }

  .rectangle {
    width: 260px;
    min-height: 356px;
    margin: 40px 0px 0px 17px;
    border-radius: 4px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f5f8fa;
    background-color: #fff;
  }

  .exmptyrectangle {
    flex-direction: column;
    width: 260px;
    display: flex;
    min-height: 356px;
    margin: 40px 0px 0px 17px;
    border-radius: 4px;
    box-shadow: 0 3px 11px 0 rgb(0 0 0 / 4%);
    border: solid 1px #f5f8fa;
    background-color: #fff;
    justify-content: center;
    align-items: center;
  }

  .create-a-group {
    width: 126px;
    height: 19px;
    margin: 0 0 9px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #00515a;
  }

  .showme {
    display: none;
  }

  .showhim:hover .showme {
    display: block;
    position: absolute;
    background: rgb(255, 255, 255);
    padding: 12px;
    width: 165px;
    order: solid 1px #f5f8fa;
    border-radius: 4px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.04);
  }

  .showMenu {
    display: flex;
    width: 144px;
    height: 28px;
    margin: 0 0 4px;
    padding: 7px 8px 7px;
    border-radius: 8px;
    background-color: #f4f5f6;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #777;
    cursor: pointer;
  }

  .showDeleteMenu {
    display: flex;
    width: 144px;
    height: 28px;
    cursor: pointer;
    /* margin: 0 0 4px; */
    padding: 7px 8px 7px;
    border-radius: 8px;
    background-color: #ffe6ea;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ee3048;
  }
  .showMenuText {
    width: 120px;
    height: 13px;
    margin: 0 18px 0 0;
  }
  .column {
    float: left;
    width: 25%;
    padding: 0 0px;
  }

  .row {
    margin: 0 0;
    padding-bottom: 36px;
  }

  .row:after {
    content: '';
    display: table;
    clear: both;
  }

  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 0px;
    }
  }

  .dot {
    height: 35px;
    width: 35px;
    background-color: #f2f5f6;
    border-radius: 50%;
    display: inline-block;
  }

  .menudot {
    height: 25px;
    width: 25px;
    background-color: #f2f5f6;
    border-radius: 50%;
    display: inline-block;
  }

  .BackgroundCircleOne {
    border-radius: 50%;
    width: 490px;
    height: 490px;
    z-index: 0;
    position: fixed;
    top: 5%;
    left: 45%;
    opacity: 0.1;
    background-color: var(--white-color);
  }

  .BackgroundCircleTwo {
    border-radius: 50%;
    width: 235px;
    height: 235px;
    z-index: 0;
    position: fixed;
    top: 72%;
    left: 34%;
    opacity: 0.1;
    background-color: var(--white-color);
  }

  .LoginDiv {
    position: absolute;
    width: 300px;
    height: 300px;
    /* Center form on page horizontally & vertically */
    top: 45%;
    left: 50%;
    margin-top: -300px;
    margin-left: -200px;
  }

  .LoginDiv40 {
    top: 40%;
  }

  .LoginDiv50 {
    top: 50%;
  }

  .LoginContentHolder {
    width: 448px;
    /* height: 300px; */
    background: ${(props) => props.theme.colors.acccountsettingBgColor};
    border-radius: 10px;
    margin: 0;
    padding: 80px 56px;
  }

  .AuthLogoContainer {
    margin-bottom: 15px;
  }

  .AuthHeadContainer {
    margin-bottom: 40px;
  }

  .DoosraBusinessHeader {
    /* font-family: Montserrat; */
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.07; */
    margin: 0;
    margin-bottom: 10px;
    letter-spacing: normal;
  }

  .titleText {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .DoosraDefaultColor {
    color: var(--primary-color1);
  }
  .DoosraBussinessDefaultColor {
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .InputBox {
    padding-left: 5%;
    width: 100%;
    height: 44px;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px #939598;
    margin-top: 7px;
    margin-bottom: 7px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .AuthButton {
    width: 100%;
    padding: 10px;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.75px;
    text-align: center;
    color: var(--white-color);
    border-radius: 4px;
    border-width: 0;
    background-color: ${(props) => props.theme.colors.tabSelectedColor};
  }

  .LogoutAllDevices {
    border: solid 1px red;
    color: red;
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .AuthRememberOnDeviceContainer {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .AuthRememberOnDevice {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 0;
    letter-spacing: normal;
    text-align: left;
    color: #777777;
  }

  .AuthCheckbox {
    position: relative;
    top: 2px;
    right: 10px;
  }

  .PasswordInputHolder {
    display: grid;
    grid-template-columns: 92% 8%;
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 15px 10px 14px 10px;
    border-radius: 4px;
    border: solid 1px var(--light-grey-input-bg-color);
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .PasswordInput {
    border: none;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding-right: 45px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .PasswordVisibleToggle {
    // position: relative;
    // top: 5px;
  }

  .PasswordInvisibleToggle {
    // position: relative;
    // top: 3px;
  }

  .PasswordInput:focus,
  .PasswordInput:focus {
    outline: none;
  }

  .TextCenter {
    text-align: center;
  }

  .QRCodeText {
    font-size: 15px;
    font-weight: bold;
    color: var(--white-color);
    padding-left: 20px;
    width: 215px;
    float: left;
  }

  .QRCodeIcon {
    float: right;
    margin: 12px;
  }

  .SignIntoDashboard {
    font-size: 15px;
    font-weight: bold;
    color: var(--white-color);
    padding-left: 20px;
    width: 230px;
    float: left;
    /* ------------- */
    width: 250px;
    margin: 13px;
  }

  .SignIntoRightArrowIcon {
    float: right;
    margin: 15px;
  }

  .AuthSuccessHolder {
    text-align: center;
    margin-bottom: 56px;
  }

  .PasswordCheckContainer {
    background-color: var(--light-grey-bg-color);
    border-radius: 4px;
    margin: 16px auto;
    padding: 5px 20px;
  }

  .PasswordCheckContainer h1 {
    font-size: 14px;
    font-weight: 600;
  }

  /* .q {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
} */

  .PasswordCheckContentContainer {
    display: grid;
    grid-template-columns: 8% 92%;
    grid-template-rows: auto auto auto auto;
  }

  .PasswordCheckContentContainer img {
    padding-top: 3px;
  }

  .GreyText {
    margin: 0;
    font-size: 13px;
    color: var(--text-grey-color);
  }

  .GreenText {
    margin: 0;
    font-size: 13px;
    color: var(--text-green-color);
  }

  .ErrorContainer {
    min-height: 19px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 3.92; */
    letter-spacing: normal;
    text-align: center;
    color: var(--red-color);
  }

  .ErrorContainerMargin {
    margin-top: 16px;
    margin-bottom: 18px;
  }

  .Error {
    width: fit-content;
    margin: 0;
  }

  .OTPResent {
    display: flex;
    justify-content: center;
  }
  .OTPResent img {
    height: 14px;
    width: 14px;
    position: relative;
    top: 3px;
    margin-right: 4px;
  }
  .OTPResent p {
    color: green;
    margin: 0;
    font-size: 14px;
  }
  .PlanBox {
    padding: 20px;
    margin-top: 15px;
    background-color: #fafbfc;
    border-radius: 4px;
    border: solid 1px #f2f3f6;
  }
  .whiteBox {
    display: flex;
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #f2f3f6;
    border-radius: 4px;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1500px) {
    .PasswordInput {
      border: none;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      padding-right: 0;
      color: ${(props) => props.theme.colors.topBarAccTextColor};
    }
    .PasswordVisibleToggle {
      float: right;
      padding: 6px 4px;
    }
    .PasswordInvisibleToggle {
      float: right;
      padding: 4px 4px;
    }

    .planText {
      font-size: 18;
      font-weight: bold;
    }
    .planSubText {
      font-size: 14;
      color: #454545;
      font-weight: 500;
    }
    .greenFont {
      color: #07aa3f;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;
