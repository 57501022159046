import React, { useEffect, useState } from 'react';
import Close from '../../../assets/images/popupClose.png';
import Popup from '../../../common/component/popups/popups';
// import filterIcon from '../../../assets/images/Icon-filter.svg';
import filterIcon from "../../../assets/images/filterIcon.svg";
import './TableHeaders.css';
import { useRef } from 'react';

export default function TableHeaders(props) {
  const [distanceToTop, setDistanceToTop] = useState(0);
  const [distanceToLeft, setDistanceToLeft] = useState(0)
  const buttonRef = useRef();

  const calculateDistanceToTop = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const distance = buttonRect.top;
      console.log('top', distance);
      setDistanceToTop(distance);
    }
  };

  const calculateDistanceToLeft = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const distance = buttonRect.left;
      console.log('left', distance);
      setDistanceToLeft(distance);
    }
  };

  useEffect(() => {
    calculateDistanceToTop();
    calculateDistanceToLeft()
  }, [])


  const [isSelectHeadersPopUpOpen, setIsSelectHeadersPopUpOpen] = useState(
    props.isPopUpOpen || false
  );
  const buttonJSX = (
    <button
      className="Otherbutton"
      ref={buttonRef}
      style={{
        width: '32px',
        height: '32px',
        padding: '8px 8.1px 8px 8px',
        color: 'black',
        backgroundColor: 'rgba(25, 108, 202, 0.1)',
        borderRadius: '4px',
      }}
      onClick={() => {
        setIsSelectHeadersPopUpOpen((isPopUpOpen) => !isPopUpOpen);
        if (props.openHeadersPopUp) {
          props.openHeadersPopUp();
        }
      }}
    >
      <img src={filterIcon} alt="Filter icon"></img>
    </button>
  );
  const popUpJSX = isSelectHeadersPopUpOpen ? (
    <div
      className="main-container"
      style={{ top: distanceToTop, left: distanceToLeft }}
      open={isSelectHeadersPopUpOpen}
      onClose={() => {
        setIsSelectHeadersPopUpOpen((isPopUpOpen) => !isPopUpOpen);
      }}
    >
      <div className="header-box">
        <label className="header-text" style={{ top: '9px', left: '17px' }}>
          COLUMNS
        </label>
        <label className="header-text" style={{ top: '9px', right: '5px' }}>
          ENABLE/DISABLE
        </label>
        <div style={{
          borderRadius: '50%',
          overflow: 'hidden',
          position: 'absolute',
          top: '-10px',
          right: '-10px',
          width: '22px',
          height: '22px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.23)'
        }}>
          <img
            onClick={() => setIsSelectHeadersPopUpOpen((isPopUpOpen) => !isPopUpOpen)}
            src={Close}
            style={{
              cursor: 'pointer',
              zIndex: '10',
              width: '20px',
              height: '20px'
            }}
            alt="Close"
          />
        </div>
      </div>

      <div style={{
        overflowY: "scroll",
        width: '400px',
        height: '275px'
      }}>
        {props.headers.map((header) => (
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #d1d3d4',
              alignItems: 'center',
            }}
          >
            <p className='label' style={{ marginLeft: '10px', width: '200px' }}>{header}</p>
            <label className="switch">
              <input
                type="checkbox"
                style={{ marginRight: '10px' }}
                value={header}
                onChange={(e) =>
                  props.updateFilteredHeader(header, e.target.checked)
                }
                checked={
                  props.filteredHeaders.indexOf(header) === -1 ? false : true
                }
              />
              <span class="slider round"></span>
            </label>
          </div>
        ))}
      </div>
    </div>
  ) : null;
  return isSelectHeadersPopUpOpen ? popUpJSX : buttonJSX;
}
