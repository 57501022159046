import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';

class PopThree extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      an: '',
      appn: '',
      aapn: '',
    };
  }

  componentDidMount() {
    this.setState({
      an: this.props.name,
      appn: this.props.userNumber,
      aapn: this.props.userDoosraNumber,
    });
  }

  openPopup = () => {
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState({ popupOpen: false });
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  deleteUserAndClosePopup = (userId) => {
    this.props.deleteUser(userId);
    this.setState({ popupOpen: false });
  };

  render() {
    console.log(this.props.group);
    let popper = !this.props.group.length ? (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer" style={{ overflow: 'auto' }}>
          <div className="PopupHeaderContainer">
            <p>Delete Agent Profile</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupBodyTitle">
              Are you sure you want to remove this agent?
            </p>

            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Name</label>
              <input
                className="PopupInput ReadOnlyInput"
                id="an"
                readOnly={true}
                value={this.state.an}
                onChange={this.inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Personal Phone Number</label>
              <input
                className="PopupInput ReadOnlyInput"
                id="appn"
                readOnly={true}
                value={this.state.appn}
                onChange={this.inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Assigned Virtual Number</label>
              <input
                className="PopupInput ReadOnlyInput"
                id="aapn"
                readOnly={true}
                value={this.state.aapn}
                onChange={this.inputHandler}
              />
            </div>

            <p className="PopupNote">
              Note: The agent assigned vitual number will not have any agent to
              forward calls to once they are removed.
            </p>

            <button
              className="ButtonFullWidth BgRed"
              onClick={() => this.deleteUserAndClosePopup(this.props.userId)}
            >
              REMOVE
            </button>
          </div>
        </div>
      </Popup>
    ) : (
      <>
        <Popup
          open={this.state.popupOpen}
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Delete Agent Profile</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={this.closePopup}
              />
            </div>
            <div className="PopupBody">
              <p className="PopupBodyTitle">
                {`This agent is part of a call routing groups(s). Do you still want to proceed with the deletion?`}
              </p>
              <p className="PopupNote">
                {`Note: If deleted, the agent shall be removed from the routing groups(s) and this might affect the call routing flow.`}
              </p>

              <button
                className="ButtonFullWidth BgRed"
                onClick={() => this.deleteUserAndClosePopup(this.props.userId)}
              >
                REMOVE
              </button>
            </div>
          </div>
        </Popup>
      </>
    );

    return (
      <React.Fragment>
        <div style={{ padding: '5px' }}>
          <img alt="" onClick={this.openPopup} src={Delete} />
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

export default PopThree;
