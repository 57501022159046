import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import MoreMenuIcon, {
  DraggableIcon,
  EditGroupIcon,
  GroupDeleteIcon,
  MinusRedIcon,
  PlusGreenIcon,
  PlusIcon,
  RoutingSVG,
} from '../../../assets/Icons/Icons';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { Redirect } from 'react-router-dom';
import { commonStyle } from '../../../assets/css/css';
const userUrl = process.env.REACT_APP_USER_API;
const priorityRoutingUrl = process.env.REACT_APP_PRIORITY_ROUTING_API;
const grid = 8;

export default function AddAgent(props) {
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [remainUser, setRemainUser] = useState([]);
  const [arrangePriority, setArrangePriority] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    let agentIDs = [];
    if (props?.agents?.length > 0) {
      agentIDs = props.agents;
    }
    GET(userUrl + `v1/user`, {
      pageSize: 100000000,
      search: '',
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        const data = res?.data?.response?.users?.docs;
        if (data) {
          setAllUsers(data.filter((item) => item?.organisation?.is_onboard));
          const agentsData = [];
          const selectedAgents = data.filter((item) => {
            if (
              agentIDs.indexOf(item._id) !== -1 &&
              item?.organisation?.is_onboard
            ) {
              agentsData.push(item.zvr_name);
              return true;
            }
            return false;
          });
          props.setAgentsData(agentsData);
          const remainedAgents = data.filter(
            (item) =>
              agentIDs.indexOf(item._id) === -1 &&
              item?.organisation?.is_onboard
          );
          setSelectedUsers(selectedAgents);
          setRemainUser(remainedAgents);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const onClickAddUserToSelectedUser = (item) => {
    const newSelectedUsers = [...selectedUsers, item];
    const index = remainUser.findIndex((user) => user.user_id === item.user_id);
    const newRemainUser = [...remainUser];
    newRemainUser.splice(index, 1);

    setSelectedUsers(newSelectedUsers);
    setRemainUser(newRemainUser);
  };

  const onClickSelectedUserToAddUser = (item) => {
    const newRemainUser = [...remainUser, item];
    const index = selectedUsers.findIndex(
      (user) => user.user_id === item.user_id
    );
    const newSelectedUsers = [...selectedUsers];
    newSelectedUsers.splice(index, 1);

    setSelectedUsers(newSelectedUsers);
    setRemainUser(newRemainUser);
  };

  const remainUsersListJSX = () => {
    const result = searchValue
      ? remainUser.filter((item) =>
          item.zvr_name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : remainUser;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <PlusGreenIcon onClick={() => onClickAddUserToSelectedUser(item)} />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  const selectedUsersListJSX = () => {
    const result = searchValue
      ? selectedUsers.filter((item) =>
          item.zvr_name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : selectedUsers;

    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <MinusRedIcon onClick={() => onClickSelectedUserToAddUser(item)} />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  const onClickAssignUserNext = () => {
    setError('');
    const SelectedUsers = selectedUsers || [];
    if (SelectedUsers?.length <= 0) {
      setError('Please add agents');
    } else {
      setArrangePriority(true);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedUsers,
      result.source.index,
      result.destination.index
    );
    setSelectedUsers(items);
  };

  const onClickPriorityNext = () => {
    setArrangePriority(false);
    props.setAgents(selectedUsers.map((item) => item._id));
    props.setAgentsData(selectedUsers.map((item) => item.zvr_name));
    props.close();
  };

  return (
    <Popup open={props.open} closeOnDocumentClick={false} closeOnEscape={false}>
      {!arrangePriority && (
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Add agents to the Campaign</p>
            <img className="PopupClose" src={Close} onClick={props.close} />
          </div>
          <div className="PopupBody">
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                placeholder="Search Agent"
                className="PopupSearchInput"
                id="searchValue"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
            {selectedUsers.length > 0 && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    textAlign: 'center',
                    fontSize: '12px',
                    'letter-spacing': '1.2px',
                  }}
                  className="PopupColumnHeaders"
                >
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    SELECTED AGENTS
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ADD/REMOVE
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {selectedUsersListJSX()}
                </div>
              </>
            )}
            {remainUser.length > 0 && (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '60% 40%',
                    textAlign: 'center',
                    fontSize: '12px',
                    'letter-spacing': '1.2px',
                  }}
                  className="PopupColumnHeaders"
                >
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    AVAILABLE AGENTS
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ADD/REMOVE
                  </p>
                </div>
                <div
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  {remainUsersListJSX()}
                </div>
              </>
            )}
            <div className="PopupInputContainer" style={{ color: 'red' }}>
              {error}
            </div>
            {newGroup.routing_type === 'ROUND_ROBIN' ? (
              <button
                className={`ButtonFullWidth`}
                // onClick={onClickPriorityNext}
              >
                SAVE
              </button>
            ) : (
              <button
                className={`ButtonFullWidth`}
                onClick={onClickAssignUserNext}
              >
                Next
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={props.close}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {arrangePriority && (
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Confirm Agents</p>
            <img className="PopupClose" src={Close} onClick={props.close} />
          </div>
          <div className="PopupBody">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                textAlign: 'center',
                fontSize: '12px',
                'letter-spacing': '1.2px',
              }}
              className="PopupColumnHeaders"
            >
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingLeft: '30px',
                }}
              >
                SNO
              </p>
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingRight: '30px',
                }}
              >
                AGENTS
              </p>
            </div>
            <div
              style={{
                height: '170px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {selectedUsers.map((item, index) => (
                <>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '50% 50%',
                      textAlign: 'center',
                      'letter-spacing': '1.2px',
                    }}
                    className="dropColumnBg"
                  >
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        fontSize: '15px',
                        color: '#33aabb',
                      }}
                    >
                      {index + 1}
                    </p>
                    <p
                      style={{
                        margin: '10px',
                        textAlign: 'left',
                        paddingRight: '30px',
                        fontSize: '15px',
                        color: '#33aabb',
                      }}
                    >
                      {item.zvr_name}
                    </p>
                  </div>
                  <hr
                    className="HR"
                    style={{ width: '90%', margin: '0 auto' }}
                  />
                </>
              ))}
            </div>
            {/* <div
              style={{
                height: '170px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        minHeight: '220px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                      }}
                    >
                      {selectedUsers.map((item, index) => (
                        <Draggable
                          key={item.user_id}
                          draggableId={item.user_id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <>
                              <div
                                className={`${snapshot.isDragging
                                  ? 'dropColumnBg'
                                  : 'dragColumnBg'
                                  }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: 'none',
                                  // padding: grid * 2,
                                  margin: `0 0 ${grid}px 0`,
                                  display: 'grid',
                                  gridTemplateColumns: '33% 33% 33%',
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <p
                                  style={{
                                    paddingLeft: '40px',
                                    fontSize: '14px',
                                    color: '#33aabb',
                                    margin: 'auto 0',
                                  }}
                                >
                                  {index + 1}
                                </p>
                                <p
                                  style={{
                                    height: '28px',
                                    width: '10px',
                                    margin: '8px 62px',
                                    color: '#33aabb',
                                  }}
                                >
                                  {item.zvr_name}
                                </p>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    alignItems: 'center',
                                    padding: '0px 30px',
                                  }}
                                >
                                  <DraggableIcon />
                                </div>
                              </div>
                              <hr
                                className="HR"
                                style={{
                                  width: '90%',
                                  margin: '0 auto',
                                }}
                              />
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div> */}
            <button className={`ButtonFullWidth`} onClick={onClickPriorityNext}>
              SAVE
            </button>
            <button
              className="ButtonCancelFullWidth"
              onClick={() => {
                setArrangePriority(false);
                props.close();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Popup>
  );
}
