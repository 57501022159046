import '../../../assets/css/UpgradeServices.css';
import { AlertIcon } from '../../../assets/Icons/Icons';
import checkmark from '../../../assets/images/checkmark-circle.svg';
import { GET, PUT } from '../../../services/HttpRequests';
import React, { useEffect, useState } from 'react';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import Lock from '../../../assets/images/lockOrangeCircle.png';
import Popup from 'reactjs-popup';
import UpgradePlanPopup from '../upgradePlanPopup';
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const overlayStyle = { textAlign: 'center', background: 'rgba(0,0,0,0.9)' };
const contentStyle = {
  width: '512px',
  padding: '40px 42px',
  borderRadius: '8px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
};

const PlanExpired = (props) => {
  const [tenure, setTenure] = useState(null);
  const [plan, setPlan] = useState(null);
  const [product, setProduct] = useState(null);
  const [showSelectPlan, setShowSelectPlan] = useState(false);

  const fetchPlanDetails = async () => {
    const res = await GET(`${orgUrl}v1/organisation/plan`, {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    });

    setPlan(res?.data?.response?.planDetails?.plan);
    setProduct(res?.data?.response?.planDetails?.product);
    setTenure(res?.data?.response?.planDetails?.tenure);
  };

  useEffect(() => {
    // if (props.open) {
      fetchPlanDetails();
    // }
  }, []);

  // if (showSelectPlan) {
  //   return (
  //     <UpgradePlanPopup
  //       open={showSelectPlan}
  //       close={() => setShowSelectPlan(false)}
  //       currentPlan={plan || null}
  //       currentTenure={tenure}
  //       currentProduct={product}
  //     />
  //   );
  // }
  const FreemiumContent = () => {
    return (<>
      <h2 className="heading" style={{ color: '#f07038' }}>
        Your 14 day free trial is over
      </h2>

      <p style={{ fontSize: '22px', fontWeight: 500 }}>
        Access to Alohaa has expired
      </p>
      <p style={{ fontSize: '16px', fontWeight: 500 }}>
        This does not have to be the end, to continue accessing all features,
        please select a plan.
      </p>
      <button
        style={{
          color: '#fff',
          backgroundColor: '#000',
          width: '336px',
          height: '48px',
          borderRadius: '4px',
          fontWeight: 600
        }}
        onClick={() => setShowSelectPlan(true)}
      >
        EXPLORE PLANS
      </button>
    </>);
  }

  const NonFreemiumContent = () => {
    return (<>
      <h2 className="heading" style={{ color: '#f07038' }}>
        Access to Alohaa has expired
      </h2>

      <p style={{ fontSize: '16px', fontWeight: 500 }}>
        This does not have to be the end, to continue accessing all features.
        Please contact <b>support@alohaa.ai</b>
      </p>
      {/* <button
        style={{
          color: '#fff',
          backgroundColor: '#000',
          width: '336px',
          height: '48px',
          borderRadius: '4px',
        }}
        onClick={() => setShowSelectPlan(true)}
      >
        RENEW NOW
      </button> */}
    </>)
  }
  return (
    <>
      <Popup
        open={props.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle, contentStyle }}
      >
        <img src={Lock} />
        {props.isFreemium ? <FreemiumContent /> : <NonFreemiumContent />}
      </Popup>


      <UpgradePlanPopup
        open={showSelectPlan}
        close={() => setShowSelectPlan(false)}
        currentPlan={plan || null}
        currentTenure={tenure}
        currentProduct={product}
      />

    </>
  );
};

export default PlanExpired;