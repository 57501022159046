/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from 'react';

export const AuthContext = React.createContext();

export default function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('doosra-business-sessionToken')
  );
  const [sessionToken, setSessionToken] = useState(undefined);
  const checkIsLogin = useCallback(() => {
    const sessionToken = localStorage.getItem('doosra-business-sessionToken');
    const isLogin =
      typeof sessionToken === 'string' && sessionToken.trim() !== '';

    if (!isLogin) setIsLoggedIn(false);
    return isLogin;
  }, []);

  useEffect(() => {
    const sessionToken = localStorage.getItem('doosra-business-sessionToken');
    if (checkIsLogin()) {
      setIsLoggedIn(true);
      setSessionToken(sessionToken);
    }

    window.checkIsLogin = checkIsLogin;
  }, []);

  const saveSession = (res) => {
    let name, number, email, organisationId, is_webrtc_enabled, sip_details;
    console.log('res', res);
    if (res.role === 'USER' || res.role === 'user') {
      organisationId = res.user.organisation.organisation_id;
      name = res.user.zvr_name;
      number = res.user.zvr_mobile_no;
      email = res.user.zvr_email;
      is_webrtc_enabled = res.user.is_webrtc_enabled || false;
      sip_details = res.user.sip_details;
      localStorage.setItem(
        'doosra-biz-user-doosra-number',
        res.user.v_mobile_no
      );
      localStorage.setItem('is_webrtc_enabled', is_webrtc_enabled);
      if (sip_details !== undefined) {
        localStorage.setItem('sip_details', JSON.stringify(sip_details));
      } else {
        localStorage.setItem('sip_details', JSON.stringify({}));
      }
    }
    if (res.role === 'ADMIN' || res.role === 'admin') {
      organisationId = res.user.organisation_id;
      name = res.user.name;
      email = res.user.email;
      number = res.user.phone_number;
    }

    localStorage.setItem(
      'doosra-business-sessionToken',
      res['x-metro-sessiontoken']
    );
    setIsLoggedIn(!!localStorage.getItem('doosra-business-sessionToken'));
    localStorage.setItem('doosra-biz-user-role', res.role.toUpperCase());
    localStorage.setItem(
      'doosra-biz-user-role-is-super-admin',
      res.isSuperAdmin
    );
    localStorage.setItem(
      'doosra-biz-super-admin-permissions',
      JSON.stringify(res?.user?.permissions?.business_portal)
    );
    localStorage.setItem('doosra-biz-user-id', res.user.user_id);
    localStorage.setItem('doosra-biz-agent-id', res.user._id?.toString());

    localStorage.setItem('doosra-biz-organisation-id', organisationId);
    localStorage.setItem('doosra-biz-user-name', name);
    localStorage.setItem('doosra-biz-user-number', number);
    localStorage.setItem('doosra-biz-user-email', email);

    // Do not reload here for the Google Authentication
    if (!isSsoLoginAuthRoute(window.location.pathname)) {
      window.location.reload();
    }
  };

  function isSsoLoginAuthRoute(route) {
    const sanitizedRoute = route.trim().replace(/\/$/, '');
    const ssoCallbackRoutes = ["/auth/google/callback", "/auth/microsoft/callback", "/auth/ship-rocket/callback"]
    return ssoCallbackRoutes.includes(sanitizedRoute)
  }

  const ctxValue = {
    isLoggedIn,
    sessionToken,
    saveSession,
  };
  return (
    <AuthContext.Provider value={ctxValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
