import React from 'react';
import callAnswer from '../../../assets/images/callAnswer.png';
import callReject from '../../../assets/images/callReject.png';
import PersonWhite from '../../../assets/images/personWhite.png';
const IncomingCallPopup = ({ callerName, callerNumber, onAnswer, onReject, type, way }) => {
  return (
    <div style={styles.popupContainer}>
      <div style={styles.popup}>
        <div style={styles.callContent}>
          <div style={styles.incomingText}>
            Incoming call - <div style={styles.greenCallStatus}>{way}</div>
          </div>
          {callerName && <div style={styles.callerName}>{callerName || 'Unknown Caller'}</div>}
          <div style={styles.callerNumber}>+91 {callerNumber || "NA"}</div>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.buttonGreen} onClick={onAnswer}>
            <img src={callAnswer} alt='' width={18} height={18} /> &nbsp; Answer
          </button>
          <button style={styles.buttonRed} onClick={() => onReject(true)}>
            <img src={callReject} alt='' width={22} height={22} />&nbsp; Decline
          </button>
        </div>
      </div>
    </div >
  );
};
const styles = {
  popupContainer: {
    position: 'fixed',
    top: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: '#333',
    color: 'white',
    padding: '30px 20px 30px 20px',
    borderRadius: '14px',
    width: '430px',
    height: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
  },
  callContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '15px'
  },
  greenDividerDot: {
    height: '5px',
    width: '5px',
    backgroundColor: 'green',
    borderRadius: '50%',
  },
  redDividerDot: {
    height: '5px',
    width: '5px',
    backgroundColor: '#ed3833',
    borderRadius: '50%',
  },
  incomingText: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    gap: '5px',
    justifyContent: 'flex-start',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'start',
    color: '#07aa3f'
  },
  greenCallStatus: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'start',
    color: '#07aa3f'
  },
  redCallStatus: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'start',
    color: '#ed3833'
  },
  callerName: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#fff'
  },

  callerNumber: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: '#fff'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px',
  },
  buttonGreen: {
    width: '183px',
    height: '55px',
    borderRadius: '8px',
    boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 2px #07aa3f',
    backgroundColor: '#07aa3f',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff'
  },
  buttonGrey: {
    width: '183px',
    height: '55px',
    borderRadius: '8px',
    boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 2px grey',
    backgroundColor: 'grey',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff',
    cursor: 'default'
  },
  buttonRed: {
    width: '183px',
    height: '55px',
    borderRadius: '8px',
    boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 2px #ed3833',
    backgroundColor: '#ed3833',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff'
  },
};
export default IncomingCallPopup;