import React from 'react';
import Topbar from '../../common/component/topbar/Topbar';
import Sidebar from '../../common/component/sidebar/Sidebar';
import HubSpotConnectCard from '../../common/component/hubspot/HubSpotConnectCard';
import { connect } from 'react-redux';
import { commonStyle } from '../../assets/css/css';

const NativeIntegrationsPage = (props) => {
  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div style={styles.NativeIntegrationsContainer}>
          <HubSpotConnectCard />
        </div >
      </div>
    </div>  
  );
};
const styles = {
  NativeIntegrationsContainer: {
    position: 'relative',
    top: '20px',
    left: '20px',
  },
};
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});  
export default commonStyle(connect(mapStateToProps, {})(NativeIntegrationsPage));