import adapter from 'webrtc-adapter';
import JsSIP from 'jssip';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DailPadIcon from '../../../assets/images/dialpad.svg';
import AlohaaLogo from '../../../assets/images/AlohaaLogoWhite.png';
import phoneWhite from '../../../assets/images/phoneWhite.png';
import callEndLogo from '../../../assets/images/call_end.svg';
import circleDown from '../../../assets/images/expand_circle_down.svg';
import MicOff from '../../../assets/images/mic_off.svg';
import Mic from '../../../assets/images/mic.svg';
import Play from '../../../assets/images/play_arrow.svg';
import Pause from '../../../assets/images/pause.svg';
import GreyPause from '../../../assets/images/GreyPause.svg';
import ExpandCircleDown from '../../../assets/images/expand_circle_up.svg';
import GreyMic from '../../../assets/images/GreyMic.svg';
import HangupLogo from '../../../assets/images/RecoveryHangup.webp';
import Help from '../../../assets/images/help.svg';
import BlackBlueArrow from '../../../assets/images/backArrowBlue.png';
import search from '../../../assets/call-merge-icons/search.svg';
import refreshIcon from '../../../assets/call-merge-icons/refresh.svg';
import expandCircleDownIcon from '../../../assets/call-merge-icons/expand_circle_down.svg';
import callEndIcon from '../../../assets/call-merge-icons/call_end.svg';
import redCallEnd from '../../../assets/call-merge-icons/red-call-end.svg';
import backIcon from '../../../assets/call-merge-icons/arrow_back.svg';
import coloredAddAgent from '../../../assets/call-merge-icons/colored-add-agent.svg';
import coloredCallMergeAdd from '../../../assets/call-merge-icons/colored-call-merge-add.svg';
import coloredCallTransfer from '../../../assets/call-merge-icons/colored-call-transfer.svg';
import greyAddAgent from '../../../assets/call-merge-icons/grey-add-agent.svg';
import greyForward from '../../../assets/call-merge-icons/grey-forward.svg';
import forward from '../../../assets/call-merge-icons/forward.svg';
import greyCallMergeAdd from '../../../assets/call-merge-icons/grey-call-merge-add.svg';
import greyCallTransfer from '../../../assets/call-merge-icons/grey-call-transfer.svg';
import activeCallIcon from '../../../assets/call-merge-icons/record_voice_over.svg';
import incomingRingtone from "../../../assets/incoming_ringtone.mp3";

import { GET, POST, PUT } from '../../../services/HttpRequests';
import { useSocket } from '../../../providers/SocketProvider';
import { toast } from 'react-toastify';
import { TURN_SERVER_CONFIG } from '../../../assets/constants';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '../../../providers/customeThemeProvider';
import tickAnimaiton from '../../../assets/images/tickAnimation.json';
import GreenTick from '../../../assets/images/greenTickSmall.png';
import Lottie from 'react-lottie';
import './DailPad.css';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import {
  setIsOpen,
  setisMicOff,
  setIsPaused,
  setIsCallInProgress,
  setNumbers,
  setDestinationNumber,
  setDid,
  setCallType,
  setCallStatus,
  setUAList,
  setRTCSessionData,
  setDispositions,
  setTimer,
  setRunTimer,
  setCallId,
  setShowDailNextButton,
  setDailNextTimer,
  setContactId,
  setAgentStatus,
  setDisposition,
  setSelectedDailer,
  setAgentActiveCampaigns,
  setSelectedCampaign,
  setSelectedCampaignDetails,
  setShowCampaignExitScreen,
  setIsCampaignInProgress,
  setDestinationName,
  setBatchId,
  setCampaignCompletionStat,
  setCampaignId,
  setShowCallMergeAndTransferUI,
  setUnfilteredAgentsList,
  setAgentsList,
  setActiveCallAgents,
  setOngoingOtherAgent,
  updateOngoingOtherAgent,
  setOngoingOtherAgentTimerStatus,
  setOngoingCallInterval,
  setActiveAgentIntervals,
  increaseTimerofActiveCallAgent,
  setDontAllowMergeAndTransfer,
  setShowCustomerDetails,
  setLeadDetails,
  setIsIncomingCall,
  setIncomingCallPopup,
  setIncomingWay,
  setIncomingRingSoundToggle
} from '../../../actions/dailPadActions';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IncomingCallPopup from './IncomingCallPopup';
import { Switch, withStyles } from '@material-ui/core';

const MERGE_ACTIONS = {
  MERGE: 'MERGE',
  UN_HOLD: 'UN_HOLD',
  CALL_END: 'CALL_END',
};

const DIALER_TYPES = {
  SOLO: 'solo',
  POWER: 'power',
};

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const userUrl = process.env.REACT_APP_USER_API;

JsSIP.debug.enable('JsSIP:*');
const sip_details = JSON.parse(localStorage.getItem('sip_details'));
const username = sip_details?.username;
const password = sip_details?.password;

const DailerButton = styled.button`
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DailerInProgress = styled.div`
  width: 263px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--Alohaa-Red, #f8153b);
`;

const DailerInProgressInfo = styled.div`
  width: 110px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #ba2c2c;
  position: absolute;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const DailerInProgressInfoTimer = styled.label`
  color: var(--White, #fff);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 133px;
  top: 20px;
  margin: 0px;
`;

const DailerInProgressEndButton = styled.div`
  width: 51px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 13px;
  border: 1px solid var(--White, #fff);
  background: var(--Alohaa-Red, #f8153b);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 176px;
  top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DailerInProgressNumber = styled.label`
  color: var(--White, #fff);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  line-height: normal;
`;

const GradientDiv = styled.div`
  height: 98px;
  background: linear-gradient(#f8153b, #920c23);
  display: flex;
  flex-direction: row;
  border-radius: 12px 12px 0px 0px;
`;

const DailerDiv = styled.div`
  height: ${(props) => (props.theme === 'light' ? '295px' : '333px')};
  background: ${(props) => (props.theme === 'light' ? 'white' : '#282828')};
  border-radius: ${(props) =>
    props.theme === 'light' ? '0px' : '0px 0px 10px 10px'};
`;

const ExitCampaignDiv = styled.div`
  height: ${(props) => (props.theme === 'light' ? '180px' : '333px')};
  background: ${(props) => (props.theme === 'light' ? 'white' : '#282828')};
  border-radius: ${(props) =>
    props.theme === 'light' ? '0px' : '0px 0px 10px 10px'};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DailButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  background: ${(props) =>
    props.disabled ? 'grey' : 'var(--Alohaa-Green, #07aa3f)'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EndButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  background: var(--Alohaa-Red, #f8153b);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${(props) => (props.type === DIALER_TYPES.POWER ? '70px' : '16px')};
  margin: 0px 36px 0px 36px;
`;

const DailNextButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #07aa3f;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: ${(props) => (props.type === DIALER_TYPES.POWER ? '70px' : '16px')};
  align-items: center;
  cursor: pointer;
  margin: 0px 36px 0px 36px;
`;

const ResumeCallingButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #07aa3f;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 52px};
  align-items: center;
  cursor: pointer;
  margin: 0px 36px 0px 36px;
`;


const CustomSwitch = withStyles({
  root: {
    padding: 8,
    cursor: 'pointer',
  },
  switchBase: {
    padding: 10,
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: '#07aa3f', // Background color when switch is on
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    marginTop: 1,
    marginBottom: 1,
    marginRight: -10,
    marginLeft: 3,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 20 / 2,
    backgroundColor: '#e0e0e0', // Default color when switch is off
    opacity: 1,
    transition: 'background-color 0.3s',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 11,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="black" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  checked: {},
})(Switch);




const DailPad = (props) => {
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const { themeType } = useTheme();


  const dailpadContainer = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const isOpen = useSelector((state) => state.dailpad.isOpen);
  const isMicOff = useSelector((state) => state.dailpad.isMicOff);
  const isPaused = useSelector((state) => state.dailpad.isPaused);
  const isCallInProgress = useSelector(
    (state) => state.dailpad.isCallInProgress
  );
  const numbers = useSelector((state) => state.dailpad.numbers);
  const destinationNumber = useSelector(
    (state) => state.dailpad.destinationNumber
  );
  const destinationName = useSelector((state) => state.dailpad.destinationName);
  const did = useSelector((state) => state.dailpad.did);
  const callType = useSelector((state) => state.dailpad.callType);
  const callStatus = useSelector((state) => state.dailpad.callStatus);
  const UAList = useSelector((state) => state.dailpad.UAList);
  const RTCSessionData = useSelector((state) => state.dailpad.RTCSessionData);
  const dispositions = useSelector((state) => state.dailpad.dispositions);
  const disposition = useSelector((state) => state.dailpad.disposition);
  const timer = useSelector((state) => state.dailpad.timer);
  const runTimer = useSelector((state) => state.dailpad.runTimer);
  const callId = useSelector((state) => state.dailpad.callId);
  const campaignId = useSelector((state) => state.dailpad.campaignId);
  const showDailNextButton = useSelector(
    (state) => state.dailpad.showDailNextButton
  );
  const dailNextTimer = useSelector((state) => state.dailpad.dailNextTimer);
  const contactId = useSelector((state) => state.dailpad.contactId);
  const agentStatus = useSelector((state) => state.dailpad.agentStatus);
  const selectedDailer = useSelector((state) => state.dailpad.selectedDailer);
  const showCustomerDetails = useSelector(
    (state) => state.dailpad.showCustomerDetails
  );
  const leadDetails = useSelector((state) => state.dailpad.leadDetails);
  const agentActiveCampaigns = useSelector(
    (state) => state.dailpad.agentActiveCampaigns
  );
  const selectedCampaign = useSelector(
    (state) => state.dailpad.selectedCampaign
  );
  const selectedCampaignDetails = useSelector(
    (state) => state.dailpad.selectedCampaignDetails
  );
  const showCampaignExitScreen = useSelector(
    (state) => state.dailpad.showCampaignExitScreen
  );
  const isCampaignInProgress = useSelector(
    (state) => state.dailpad.isCampaignInProgress
  );
  const campaignCompletionStat = useSelector(
    (state) => state.dailpad.campaignCompletionStat
  );
  const batchId = useSelector((state) => state.dailpad.batchId);
  const buttonRef = useRef(null);
  const {
    setIsOpen,
    setisMicOff,
    setIsPaused,
    setIsCallInProgress,
    setNumbers,
    setDestinationNumber,
    setDid,
    setCallType,
    setCallStatus,
    setUAList,
    setRTCSessionData,
    setDispositions,
    setTimer,
    setRunTimer,
    setCallId,
    setShowDailNextButton,
    setDailNextTimer,
    setAgentStatus,
    setDisposition,
    setSelectedDailer,
    setAgentActiveCampaigns,
    setSelectedCampaign,
    setSelectedCampaignDetails,
    setShowCampaignExitScreen,
    setIsCampaignInProgress,
    setDestinationName,
    setBatchId,
    setCampaignCompletionStat,
    setCampaignId,
    setShowCallMergeAndTransferUI,
    setUnfilteredAgentsList,
    setAgentsList,
    setActiveCallAgents,
    setOngoingOtherAgent,
    setOngoingOtherAgentTimerStatus,
    setOngoingCallInterval,
    setActiveAgentIntervals,
    updateOngoingOtherAgent,
    increaseTimerofActiveCallAgent,
    setDontAllowMergeAndTransfer,
    setShowCustomerDetails,
    setLeadDetails,
    setIsIncomingCall,
    setIncomingCallPopup,
    setIncomingWay,
    setIncomingRingSoundToggle
  } = props;

  const orgId = localStorage.getItem('doosra-biz-organisation-id');
  const isWebRTCEnabled = localStorage.getItem('is_webrtc_enabled');
  const sipDetails = localStorage.getItem('sip_details');
  const isBrowserCallEnabledForUser =
    isWebRTCEnabled === 'true' &&
    Object.keys(JSON.parse(sipDetails)).length > 0;
  const [loading, setLoading] = useState(false);
  const [selectedDisposition, setSelectedDisposition] = useState('');
  const [distanceToRight, setDistanceToRight] = useState('');
  const [showDispositionUpdatedMessage, setShowDispositionUpdatedMessage] =
    useState(false);
  const socket = useSocket();
  let options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {},
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: tickAnimaiton,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  /* incoming call - start */

  const isIncomingCallEnabledForAgent = useSelector((state) => state.topbar.isCallForwardingAllowed);
  const incomingPreferenceMode = useSelector((state) => state.topbar.incomingPreferenceMode);
  const isIncomingWebrtcEnabled = useSelector((state) => state.topbar.isIncomingWebrtcEnabled);
  const isIncomingCallEnabledForOrg = useSelector((state) => state.topbar.call_forwarding_status);
  const ringtoneRef = useRef();
  const incomingRingSoundToggle = useSelector((state) => state.dailpad.incomingRingSoundToggle);
  const isIncomingCall = useSelector((state) => state.dailpad.isIncomingCall);
  const incomingCallPopup = useSelector((state) => state.dailpad.incomingCallPopup);
  const incomingWay = useSelector((state) => state.dailpad.incomingWay);


  /* call merge apis - start */

  const showCallMergeAndTransferUI = useSelector(
    (state) => state.dailpad.showCallMergeAndTransferUI
  );
  const unfilteredAgentsList = useSelector(
    (state) => state.dailpad.unfilteredAgentsList
  );
  const agentsList = useSelector((state) => state.dailpad.agentsList);
  const activeCallAgents = useSelector(
    (state) => state.dailpad.activeCallAgents
  );
  const onGoingOtherAgent = useSelector(
    (state) => state.dailpad.onGoingOtherAgent
  );
  const onGoingOtherAgentTimerStatus = useSelector(
    (state) => state.dailpad.onGoingOtherAgentTimerStatus
  );
  const ongoingCallInterval = useSelector(
    (state) => state.dailpad.ongoingCallInterval
  );
  const activeAgentIntervals = useSelector(
    (state) => state.dailpad.activeAgentIntervals
  );
  const dontAllowMergeAndTransfer = useSelector(
    (state) => state.dailpad.dontAllowMergeAndTransfer
  );


  useEffect(() => {
    if (onGoingOtherAgentTimerStatus) {
      if (!ongoingCallInterval) {
        const intervalId = setInterval(() => {
          updateOngoingOtherAgent({ incrementTimer: true });
        }, 1000);
        setOngoingCallInterval({ ongoingCallInterval: intervalId });
      }
    } else if (ongoingCallInterval) {
      clearInterval(ongoingCallInterval);
      setOngoingCallInterval({ ongoingCallInterval: null });
    }

    return () => {
      if (ongoingCallInterval) {
        clearInterval(ongoingCallInterval);
        setOngoingCallInterval({ ongoingCallInterval: null });
      }
    };
  }, [ongoingCallInterval, onGoingOtherAgentTimerStatus]);

  useEffect(() => {
    Object.values(activeAgentIntervals).forEach((intervalId) =>
      clearInterval(intervalId)
    );
    setActiveAgentIntervals({ activeAgentIntervals: {} });

    const newIntervals = {};
    activeCallAgents.forEach((agent) => {
      if (agent.callStatus === 'Answered') {
        const intervalId = setInterval(() => {
          increaseTimerofActiveCallAgent({ agentId: agent.agentId });
        }, 1000);

        newIntervals[agent.agentId] = intervalId;
      }
    });
    setActiveAgentIntervals({ activeAgentIntervals: newIntervals });
    return () => {
      Object.values(activeAgentIntervals).forEach((intervalId) =>
        clearInterval(intervalId)
      );
      setActiveAgentIntervals({ activeAgentIntervals: {} });
    };
  }, [activeCallAgents]);

  const endAgentCall = (agent) => {
    if (activeAgentIntervals[agent.agentId]) {
      clearInterval(activeAgentIntervals[agent.agentId]);
      delete activeAgentIntervals[agent.agentId];
      setActiveAgentIntervals({ activeAgentIntervals });
    }
    const newActiveCallAgents = activeCallAgents.filter(
      (a) => a.agentId !== agent.agentId
    );
    setActiveCallAgents({ activeCallAgents: newActiveCallAgents });
  };

  const endMergeCall = () => {
    if (ongoingCallInterval) {
      clearInterval(ongoingCallInterval);
      setOngoingCallInterval({ ongoingCallInterval: null });
    }

    Object.values(activeAgentIntervals).forEach((intervalId) =>
      clearInterval(intervalId)
    );
    setActiveAgentIntervals({ activeAgentIntervals: {} });

    setOngoingOtherAgent({ onGoingOtherAgent: null });
    setOngoingOtherAgentTimerStatus({ onGoingOtherAgentTimerStatus: false });
    setActiveCallAgents({ activeCallAgents: [] });
  };

  const agentConnected = () => {
    setOngoingOtherAgentTimerStatus({ onGoingOtherAgentTimerStatus: true });
    updateOngoingOtherAgent({ callStatus: 'Answered' });
  };

  const emitEvent = (userId, eventName, message) => {
    var body = {
      user_id: userId,
      event_name: eventName,
      data: message,
    };
    return PUT(cloudCallUrl + 'campaign/emit-event', {}, body)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const mergeCallApi = async (action, callId, agentObj) => {
    if (!agentObj && action !== MERGE_ACTIONS.UN_HOLD) {
      toast.error('Unable to Merge this user');
      return;
    }
    var data = JSON.stringify({
      callId,
      agentId: agentObj?.agentId,
      action,
      dialer: DIALER_TYPES.SOLO,
    });
    await POST(cloudCallUrl + 'cloud-call/merge', data)
      .then((res) => {
        if (action === MERGE_ACTIONS.MERGE) {
          setOngoingOtherAgent({
            onGoingOtherAgent: { ...agentObj, callStatus: 'Ringing', timer: 0 },
          });
          setShowCallMergeAndTransferUI({
            showCallMergeAndTransferUI: 'merge-screen-2',
          });
        }
        if (action === MERGE_ACTIONS.UN_HOLD) {
          if (agentObj) {
            console.log(
              'onGoingOtherAgent before active call',
              onGoingOtherAgent
            );
            setActiveCallAgents({
              activeCallAgents: [...activeCallAgents, onGoingOtherAgent],
            });
          }
          setOngoingOtherAgentTimerStatus({
            onGoingOtherAgentTimerStatus: false,
          });
          setShowCallMergeAndTransferUI({
            showCallMergeAndTransferUI: 'merge-screen-3',
          });
        }
        if (action === MERGE_ACTIONS.CALL_END) {
          endAgentCall(agentObj);
          if (activeCallAgents.length > 1) {
            setShowCallMergeAndTransferUI({
              showCallMergeAndTransferUI: 'merge-screen-3',
            });
          } else {
            setShowCallMergeAndTransferUI({ showCallMergeAndTransferUI: '' });
          }
        }
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const transferCallApi = async (callId, agentObj) => {
    if (!agentObj) {
      toast.error('Unable to Transfer this user');
      return;
    }
    var data = JSON.stringify({
      callId,
      agentId: agentObj?.agentId,
      dialer: DIALER_TYPES.SOLO,
    });
    await POST(cloudCallUrl + 'cloud-call/transfer', data)
      .then((res) => {
        setShowCallMergeAndTransferUI({ showCallMergeAndTransferUI: '' });
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const goToMergeAndTransferMode = async (mode) => {
    await getAgentsAvailability();
    console.log('hit', mode);
    if (mode === 'merge') {
      setShowCallMergeAndTransferUI({
        showCallMergeAndTransferUI: 'merge-screen-1',
      });
    }
    if (mode === 'transfer') {
      setShowCallMergeAndTransferUI({
        showCallMergeAndTransferUI: 'transfer-screen-1',
      });
    }
  };

  const mergeCurrentCallToExistingCalls = async (callId) => {
    if (!callId) {
      toast.error('Unable to Merge both calls');
      return;
    }
    await mergeCallApi(MERGE_ACTIONS.UN_HOLD, callId, onGoingOtherAgent);
  };

  const getAgentsAvailability = async () => {
    await GET(
      userUrl +
      `v1/user/agents-availability-status?organisationId=${localStorage.getItem(
        'doosra-biz-organisation-id'
      )}`
    )
      .then((res) => {
        let data = res.data.response;

        data = data.map((i) => ({
          name: i.zvr_name,
          status: i.availability ? i.availability.toLowerCase() : 'offline',
          agentId: i?._id?.toString(),
          number: i.zvr_mobile_no,
        }));

        setAgentsList({ agentsList: data });
        setUnfilteredAgentsList({ unfilteredAgentsList: data });
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  /* call merge apis - end */

  const destroyWebsocketConnection = (domain) => {
    if (ringtoneRef?.current) {
      console.log('Incoming Audio - pause');
      ringtoneRef.current.pause();
      ringtoneRef.current.currentTime = 0;
    }
    if (domain) {
      UAList.forEach(({ ua }) => {
        if (ua.hostport_params === domain) {
          ua.stop();
        }
      });
    } else {
      UAList.forEach(({ ua }) => {
        ua.stop();
      });
    }
    setUAList({ UAList: [] });
  };

  const resetValues = () => {

    /* incoming values - start */

    if (isIncomingCall) {
      setIsOpen({ isOpen: false });
    }

    setIsIncomingCall({ isIncomingCall: false });
    setIncomingCallPopup({ incomingCallPopup: false });
    setIncomingWay({ incomingWay: '' });

    /* incoming values - end */

    setTimer({ timer: 0 });
    setIsCallInProgress({ isCallInProgress: false });
    setRunTimer({ runTimer: false });
    setShowDailNextButton({ showDailNextButton: false });
    setDestinationName({ destinationName: '' });
    setDestinationNumber({ destinationNumber: '' });
    // setRunTimer({ runTimer: false });
    // setDid({ did: "" });
    setCallType({ callType: '' });
    setDisposition({ disposition: '' });
    setDontAllowMergeAndTransfer({ dontAllowMergeAndTransfer: '' });
    setCallStatus({ callStatus: 'Ringing' });
    setDailNextTimer({ dailNextTimer: 30 });
    setContactId({ contactId: '' });
    setCallId({ callId: '' });
    setCampaignId({ campaignId: '' });
    setIsPaused({ isPaused: false });
    setisMicOff({ isMicOff: false });
    setShowCallMergeAndTransferUI({ showCallMergeAndTransferUI: '' });
    setShowCustomerDetails({ showCustomerDetails: false });
    setLeadDetails({ leadDetails: {} });
  };

  const calculateDistance = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const distance = window.innerWidth - buttonRect.right;
      console.log('right', distance);
      setDistanceToRight(distance);
    }
  };

  const initiateCampaignCalls = (lastBatchId = '') => {
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = selectedCampaign;
    data.isPersistentCall = false;
    console.log(lastBatchId);
    if (showDailNextButton) {
      toast.error('Please wait while previous call is being updated');
      return;
    }

    let currentCampaignLeadsCalled = selectedCampaignDetails.leadsCalled;
    if (campaignCompletionStat) {
      if (
        selectedCampaignDetails.totalLeads ===
        selectedCampaignDetails.leadsCalled
      ) {
        currentCampaignLeadsCalled = parseInt(
          campaignCompletionStat.split('/')[0]
        );
      }
    }
    setLoading(true);
    if (
      selectedCampaignDetails.campaignType === 'static' &&
      (selectedCampaignDetails.batchCallId || lastBatchId) &&
      selectedCampaignDetails.totalLeads > currentCampaignLeadsCalled &&
      selectedCampaignDetails.totalLeads
    ) {
      data.batchCallId = lastBatchId || selectedCampaignDetails.batchCallId;
      return POST(cloudCallUrl + 'cloud-call/resume-batch-call', data)
        .then((res) => {
          setBatchId({ batchId: data.batchCallId || '' });
          setIsCampaignInProgress({ isCampaignInProgress: true });
          setLoading(false);
          return true;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          setLoading(false);
          return false;
        });
    }

    console.log('the data is ', data);
    return POST(cloudCallUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        setBatchId({ batchId: res.data.response.callId });
        setIsCampaignInProgress({ isCampaignInProgress: true });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        setLoading(false);
        return false;
      });
  };

  useEffect(() => {
    let interval;
    if (runTimer) {
      interval = setInterval(() => {
        setTimer({});
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer, runTimer]);

  useEffect(() => {
    calculateDistance();
  }, []);

  const getAgentActiveCampaigns = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      userId: localStorage.getItem('doosra-biz-user-id'),
      campaignStatus: 'ACTIVE',
      pageNo: 1,
      pageSize: 100,
    };
    GET(cloudCallUrl + 'campaign/get-agent-campaigns', data)
      .then((res) => {
        setAgentActiveCampaigns({
          agentActiveCampaigns: res?.data?.response?.agentCampaignsObjs,
        });
        if (res?.data?.response?.agentCampaignsObjs.length > 0) {
          setSelectedCampaign({
            selectedCampaign: res?.data?.response?.agentCampaignsObjs[0]._id,
          });
          setSelectedCampaignDetails({
            selectedCampaignDetails: {
              totalLeads:
                res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                  ?.totalLeads,
              leadsCalled:
                res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                  ?.leadsCalled,
              answerRate: (
                (parseInt(
                  res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                    ?.leadsCalled
                ) /
                  parseInt(
                    res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                      ?.totalLeads
                  )) *
                100
              ).toFixed(2),
              campaignType:
                res?.data?.response?.agentCampaignsObjs[0]?.campaignType,
              callType: res?.data?.response?.agentCampaignsObjs[0]?.callType,
              batchCallId:
                res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                  ?.batchCallId,
            },
          });
        } else {
          setSelectedCampaign({ selectedCampaign: '' });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const stopBatchCall = () => {
    var data = JSON.stringify({
      batchCallId: batchId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: selectedCampaign,
      userId: localStorage.getItem('doosra-biz-user-id'),
    });
    return POST(cloudCallUrl + 'cloud-call/stop-batch-call', data)
      .then((res) => {
        console.log('true case');
        campaignExitScreen();
        callEnd();
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  useEffect(() => {
    if (
      selectedDailer === DIALER_TYPES.POWER &&
      !showCampaignExitScreen &&
      !isCampaignInProgress
    ) {
      getAgentActiveCampaigns();
    }
  }, [selectedDailer]);
  useEffect(() => {
    console.log('Show dail next button interval');
    let dailNextInterval;
    if (showDailNextButton && isCampaignInProgress) {
      console.log('Show dail next button interval starting');
      dailNextInterval = setInterval(() => {
        setDailNextTimer({});
      }, 1000);
      setTimeout(() => {
        resetValues();
        clearInterval(dailNextInterval);
      }, 30000);
    }
    return () => {
      console.log('clearing the interval', dailNextInterval);
      clearInterval(dailNextInterval);
    };
  }, [showDailNextButton]);

  const getDomainName = (socketUrl) => {
    const startIndex = socketUrl.indexOf('//') + 2;
    const endIndex = socketUrl.indexOf('/', startIndex);
    const domainName = socketUrl.substring(startIndex, endIndex);
    return domainName;
  };

  const handleWebSocketConnect = (data) => {
    PUT(cloudCallUrl + 'websocket/update-user-websockets', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
    return true;
  };
  const handleWebSocketDisConnect = (data) => {
    PUT(cloudCallUrl + 'websocket/update-user-websockets', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  const sendSocketConnectionStatus = (socket, type) => {
    if (userRole === 'USER') {
      var data = JSON.stringify({
        userId: localStorage.getItem('doosra-biz-user-id'),
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        webSocket: socket,
        eventType: type,
      });
      if (type === 'connected') {
        handleWebSocketConnect(data);
      } else {
        handleWebSocketDisConnect(data);
      }
    }
  };
  useEffect(() => {
    UAList.forEach(({ ua: UA }) => {
      UA.on('disconnected', (e) => {
        UA.unregister({ all: true });
        const disconnectedSocket = e.socket._url;
        const domainName = getDomainName(disconnectedSocket);
        destroyWebsocketConnection(domainName);
        sendSocketConnectionStatus(domainName, 'disconnected');
      });
      UA.on('connected', (e) => {
        const connectedSocket = e.socket.socket._url;
        console.log('user registered successfully', connectedSocket);
        /*
          1. DONT USE CONNECTED EVENT TO SEND THE CONNECTION STATUS BECAUSE IT IS 
            TRIGGERED BEFORE THE WEBSOCKET CONNECTION IS ESTABLISHED COMPLETELY.
          2. USE REGISTERED EVENT TO SEND THE CONNECTION STATUS BECAUSE IT IS THE MAIN 
            EVENT THAT IS TRIGGERED AFTER THE WEBSOCKET CONNECTION IS ESTABLISHED COMPLETELY.
        */
        // const domainName = getDomainName(connectedSocket);
        // setTimeout(() => {
        //   sendSocketConnectionStatus(domainName, "connected")
        // }, 1000);
      });
      UA.on('registered', (e) => {
        console.log('user registered successfully');
        const connectedSocket = e.response.from._uri._host;
        setTimeout(() => {
          sendSocketConnectionStatus(connectedSocket, 'connected');
        }, 1000);
      });
      UA.on('registrationFailed', (e) => {
        console.log('user registration failed');
        // setIsAuthenticatedUser(false);
      });
      UA.on('unregistered', () => {
        console.log('user unregistration event');
      });
      UA.on('newMessage', (e) => {
        console.log("got newMessage", e.request);
        const event = e.request.event.event;
        const body = e.request.body;
        handleCallEvent(event, body);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UAList]);

  const handleCallEvent = (event, body) => {
    switch (event) {
      case "incomingagentanswered":
        stopRingtone();
        setIncomingCallPopup({ incomingCallPopup: false });
        setIncomingWay({ incomingWay: '' });
        setTimer({ timer: 0 });
        setCallStatus({ callStatus: 'Answered' });
        setRunTimer({ runTimer: true });
        setAgentStatus({ agentStatus: 'Busy' });
        handleAgentStatus('Busy');
        setIsCallInProgress({ isCallInProgress: true });
        setIsOpen({ isOpen: true });
        break;
      default: break;
    }
  }

  useEffect(() => {
    const RTCSessions = [];
    UAList.forEach(({ ua: UA }) => {
      UA.on('newRTCSession', (e) => {
        console.log('Setting the newRTCSession');
        const headers = e?.request?.headers;
        let stasisApp, incomingType, incomingIntitatorNo, incomingIntitatorName, incomingCallType, incomingCallId;
        if (headers) {
          if (headers?.Incomingtype?.length) {
            incomingType = headers?.Incomingtype[0]?.raw;
          }
          if (headers?.Initiatornumber?.length) {
            incomingIntitatorNo = headers.Initiatornumber[0]?.raw;
          }
          if (headers?.Initiatorname?.length) {
            incomingIntitatorName = headers.Initiatorname[0]?.raw;
          }
          if (headers['X-Stasis-App']?.length) {
            stasisApp = headers['X-Stasis-App'][0]?.raw;
          }
          if (headers?.Calltype?.length) {
            incomingCallType = headers.Calltype[0]?.raw;
          }
          if (headers?.Uniqueid?.length) {
            incomingCallId = headers.Uniqueid[0]?.raw;
          }
        }
        console.log('headers', headers);
        const newSession = e.session;

        /* START ICE CONFIG */
        let iceServers = [
          {
            urls: ['stun:stun.l.google.com:19302'],
            // eslint-disable-next-line no-dupe-keys
          },
          {
            urls: ['stun:stun.ekiga.net'],
          },
        ];
        const pbx = newSession._ua._configuration.hostport_params;
        iceServers.push(TURN_SERVER_CONFIG[pbx]);
        options.pcConfig['iceServers'] = iceServers;

        newSession.on('icecandidate', (candidate) => {
          console.log('iceCandidate gathered - ', candidate);
        });

        /* END ICE CONFIG */

        if (newSession.direction === 'incoming' && newSession.status === 4) {
          setRTCSessionData({ RTCSessionData: newSession });
          RTCSessions.push(newSession);
          newSession.on('accepted', function (event) {
            console.log(' Call Accepted ');
          });
          newSession.on('confirmed', function (event) {
            if (event.originator === 'remote') {
            }
          });

          // Call failed event
          newSession.on('failed', (event) => {
            console.log(' Call failed ');
            if (stasisApp && stasisApp.includes('incoming-stasis-')) {
              stopRingtone();
              resetValues();
              setIsOpen({ isOpen: false });
              setAgentStatus({ agentStatus: 'Online' });
              handleAgentStatus('Online');
            }
          });
          // Call hungup event
          newSession.on('ended', (event) => {
            console.log(' Call hangup ');
            if (stasisApp && stasisApp.includes('incoming-stasis-')) {
              stopRingtone();
              onIncomingCallHangUp();
            }
            // resetValues();
          });

          // incoming call entry
          if (stasisApp && stasisApp.includes('incoming-stasis-')) {
            resetValues();
            setIsOpen({ isOpen: false });
            setIsIncomingCall({ isIncomingCall: true });
            setIncomingCallPopup({ incomingCallPopup: true });
            setIncomingWay({ incomingWay: incomingType });
            setCallType({ callType: incomingCallType });
            setDestinationNumber({ destinationNumber: incomingIntitatorNo });
            setDestinationName({ destinationName: incomingIntitatorName || 'Unknown Caller' });
            setCallId({ callId: incomingCallId });
            console.log('Incoming Audio - play');
            if (ringtoneRef?.current) {
              // volume ranges between 0 to 1
              ringtoneRef.current.volume = 0.25;
              ringtoneRef.current.play().catch((err) =>
                console.error("Failed to play ringtone:", err)
              );
            }
          } else {
            // outgoing Answer Call
            if (newSession.status === 4) {
              newSession.answer(options);
              console.log('iceConnectionState - ', newSession);
            }
            // Audio connection
            newSession.connection.addEventListener('addstream', (e) => {
              var audio = new Audio();
              audio.srcObject = e.stream;
              audio.play();
            });
          }
        }
      });
      return () => {
        UA.removeAllListeners('newRTCSession');
      };
    });
  }, [UAList]);

  const stopRingtone = () => {
    console.log('Incoming Audio - stopRingtone');
    if (ringtoneRef?.current) {
      ringtoneRef.current.pause();
      ringtoneRef.current.currentTime = 0; // Reset to start
    }
  };

  const onIncomingCallAnswer = () => {

    /* START ICE CONFIG */
    let iceServers = [
      {
        urls: ['stun:stun.l.google.com:19302'],
        // eslint-disable-next-line no-dupe-keys
      },
      {
        urls: ['stun:stun.ekiga.net'],
      },
    ];
    const pbx = RTCSessionData._ua._configuration.hostport_params;
    iceServers.push(TURN_SERVER_CONFIG[pbx]);
    options.pcConfig['iceServers'] = iceServers;

    /* END ICE CONFIG */

    // Answer Call
    if (RTCSessionData && RTCSessionData.status === 4) {
      RTCSessionData.answer(options);
      // Audio connection
      RTCSessionData.connection.addEventListener('addstream', (e) => {
        var audio = new Audio();
        audio.srcObject = e.stream;
        audio.play();
      });

    }
  }

  const onIncomingCallHangUp = (reset) => {
    if (reset) {
      resetValues();
    } else {
      setCallStatus({ callStatus: 'Ended' });
      setRunTimer({ runTimer: false });
      setIsOpen({ isOpen: true });
      setShowDailNextButton({ showDailNextButton: true });
    }
    setAgentStatus({ agentStatus: 'Online' });
    handleAgentStatus('Online');
    if (RTCSessionData && RTCSessionData.status !== RTCSessionData.C.STATUS_TERMINATED) {
      RTCSessionData.terminate();
    }
  }

  useEffect(() => {
    initiateIncomingAudio();
  }, []);

  const initiateIncomingAudio = () => {
    console.log('Incoming Audio - initiate');
    if (ringtoneRef && incomingRingSoundToggle) {
      ringtoneRef.current = new Audio(incomingRingtone);
      ringtoneRef.current.loop = true;
    }
  }

  const unlockAudio = () => {
    console.log('Incoming Audio - unlockAudio');

    if (!ringtoneRef?.current) {
      ringtoneRef.current = new Audio(incomingRingtone);
      ringtoneRef.current.loop = true;
    }

    if (ringtoneRef?.current) {
      ringtoneRef.current.volume = 0;
      ringtoneRef.current.play().then(() => {
        ringtoneRef.current.pause();
        ringtoneRef.current.currentTime = 0;
      });
    }
  };

  const lockAudio = () => {
    console.log('Incoming Audio - lockAudio');
    ringtoneRef.current = null;
  };

  const handleIncomingRingSoundToggle = (check) => {
    console.log('handleIncomingRingSoundToggle', check);
    setIncomingRingSoundToggle({ incomingRingSoundToggle: check });
    if (check) {
      unlockAudio();
    } else {
      lockAudio();
    }
  }


  useEffect(() => {
    if (socket) {
      socket.on('callConnected', (message, cb) => {
        console.log('ReceivedEvent callConnected', message);
        if (message?.callConference) {
          setOngoingOtherAgentTimerStatus({
            onGoingOtherAgentTimerStatus: true,
          });
          updateOngoingOtherAgent({ callStatus: 'Answered' });
          setCallId({ callId: message.callId || message?.callObj?._id });
        }
        if (message?.callConference || message?.callTransfer) {
          setAgentStatus({ agentStatus: 'Busy' });
          handleAgentStatus('Busy');
          setCallType({ callType: 'PSTN' });
        }
        setCallStatus({ callStatus: 'Answered' });
        if (!runTimer) {
          setRunTimer({ runTimer: true });
        }
        setCallId({ callId: message.callId || message?.callObj?._id });

        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('agentCallEnd', (message, cb) => {
        console.log('ReceivedEvent agentCallEnd', message);
        endAgentCall({ agentId: message.agentId });
        setOngoingOtherAgentTimerStatus({
          onGoingOtherAgentTimerStatus: false,
        });
        updateOngoingOtherAgent({ callStatus: 'Ended' });
        const currentUserId = localStorage.getItem('doosra-biz-user-id');
        const confDetails = message?.callObj?.conferenceDetails;
        if (confDetails) {
          const user = confDetails.find((i) => i.agent === message.agentId);
          if (user?.userId) {
            if (currentUserId === user.userId) {
              setTimer({ timer: 0 });
              // setRunTimer({ runTimer: false });
              resetValues();
              setAgentStatus({ agentStatus: 'Online' });
              handleAgentStatus('Online');
            }
          }
        }
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('answeredCall', (message, cb) => {
        console.log('ReceivedEvent answeredCall', message);
        setCallStatus({ callStatus: 'Answered' });
        setRunTimer({ runTimer: true });
        setCallId({ callId: message.callId });
        setLeadDetails({
          leadDetails:
            message.destinationLead &&
              message.destinationLead.length > 0 &&
              message.destinationLead[0].matchedLead
              ? message.destinationLead[0].matchedLead
              : {},
        });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('ringNewAgentForTransfer', (message, cb) => {
        console.log('ReceivedEvent ringNewAgentForTransfer', message);
        setShowDailNextButton({ showDailNextButton: false });
        setDisposition({ disposition: '' });
        setDailNextTimer({ dailNextTimer: 30 });
        setContactId({ contactId: '' });
        setCampaignId({ campaignId: '' });
        setIsPaused({ isPaused: false });
        setisMicOff({ isMicOff: false });
        setShowCallMergeAndTransferUI({ showCallMergeAndTransferUI: '' });
        setDontAllowMergeAndTransfer({ dontAllowMergeAndTransfer: 'transfer' });
        setCallId({
          callId: message?.callObj?._id,
        });
        setDestinationName({ destinationName: 'Single Call' });
        setDestinationNumber({
          destinationNumber: message?.callObj?.destinationNumber,
        });
        setRunTimer({ runTimer: false });
        setTimer({ timer: 0 });
        setCallStatus({ callStatus: 'Ringing' });
        setCallType({ callType: 'PSTN' });
        setIsCallInProgress({ isCallInProgress: true });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('addedToConference', (message, cb) => {
        console.log('ReceivedEvent addedToConference', message);
        let confDetails = message?.conferenceDetails || [];
        confDetails = confDetails.filter((i) => i.user_id !== message.userId);
        confDetails = confDetails.map((i) => ({
          name: i.zvr_name,
          number: i.zvr_mobile_no,
        }));
        setDestinationName({ destinationName: 'Single Call' });
        setDestinationNumber({
          destinationNumber: message?.callObj?.destinationNumber,
        });
        setActiveCallAgents({ activeCallAgents: confDetails });
        setShowCallMergeAndTransferUI({
          showCallMergeAndTransferUI: 'merge-screen-4',
        });
        setDontAllowMergeAndTransfer({ dontAllowMergeAndTransfer: 'both' });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('ringNewAgentForMerge', (message, cb) => {
        console.log('ReceivedEvent ringNewAgentForMerge', message);

        setTimer({ timer: 0 });
        setShowDailNextButton({ showDailNextButton: false });
        setDisposition({ disposition: '' });
        setDailNextTimer({ dailNextTimer: 30 });
        setContactId({ contactId: '' });
        setCampaignId({ campaignId: '' });
        setIsPaused({ isPaused: false });
        setisMicOff({ isMicOff: false });
        setShowCallMergeAndTransferUI({ showCallMergeAndTransferUI: '' });
        setDontAllowMergeAndTransfer({ dontAllowMergeAndTransfer: 'both' });
        setCallId({
          callId: message?.callObj?._id,
        });
        setDestinationName({ destinationName: 'Peer Agent' });
        setDestinationNumber({
          destinationNumber: message?.callObj?.initiatorNumber,
        });
        setCallType({ callType: 'PSTN' });
        setCallStatus({ callStatus: 'Ringing' });
        setIsCallInProgress({ isCallInProgress: true });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('campaignCompleted', (message, cb) => {
        console.log('ReceivedEvent campaignCompleted', message);
        const campaignDetailsObj = selectedCampaignDetails;
        setSelectedCampaignDetails({
          selectedCampaignDetails: {
            ...campaignDetailsObj,
            isCampaignCompleted: true,
          },
        });
        setShowCampaignExitScreen({ showCampaignExitScreen: true });
        setIsCampaignInProgress({ isCampaignInProgress: false });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('callEnd', (message, cb) => {
        console.log('ReceivedEvent callEnd', message);
        setCallStatus({ callStatus: 'Ended' });
        setIsOpen({ isOpen: true });
        setAgentStatus({ agentStatus: 'Online' });
        handleAgentStatus('Online');
        // setTimer({ timer: 0 });
        setRunTimer({ runTimer: false });
        setShowCallMergeAndTransferUI({ showCallMergeAndTransferUI: '' });
        setDontAllowMergeAndTransfer({ dontAllowMergeAndTransfer: '' });
        setActiveCallAgents({ activeCallAgents: [] });
        if (ongoingCallInterval) {
          clearInterval(ongoingCallInterval);
          setOngoingCallInterval({ ongoingCallInterval: null });
        }
        setOngoingOtherAgent({ onGoingOtherAgent: null });
        setOngoingOtherAgentTimerStatus({
          onGoingOtherAgentTimerStatus: false,
        });
        setShowModal(false);
        endMergeCall();
        setShowDailNextButton({ showDailNextButton: true });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('refreshCampaignCallDetails', (message, cb) => {
        console.log('ReceivedEvent refreshCampaignCallDetails', message);
        setCallStatus({ callStatus: 'Ended' });
        setIsOpen({ isOpen: true });
        setAgentStatus({ agentStatus: 'Online' });
        handleAgentStatus('Online');
        setRunTimer({ runTimer: false });
        setIsCallInProgress({ isCallInProgress: false });
        setShowCustomerDetails({ showCustomerDetails: false });
        setLeadDetails({ leadDetails: {} });
        if (message.isAnswered) {
          setShowDailNextButton({ showDailNextButton: true });
        } else {
          resetValues();
        }
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('singleCall', (message, cb) => {
        console.log('ReceivedEvent singleCall', message);
        const { data, callId } = message;
        setAgentStatus({ agentStatus: 'Busy' });
        handleAgentStatus('Busy');
        setDestinationName({ destinationName: data.name });
        setDestinationNumber({ destinationNumber: data.number });
        setCampaignCompletionStat({ campaignCompletionStat: data.position });
        setIsCallInProgress({ isCallInProgress: true });
        setDisposition({ disposition: '' });
        setSelectedDisposition('');
        setCallId({ callId });
        cb({
          status: 'Acknowledged',
        });
      });
      return () => {
        socket.off('callConnected');
        socket.off('answeredCall');
        socket.off('campaignCompleted');
        socket.off('callEnd');
        socket.off('refreshCampaignCallDetails');
        socket.off('singleCall');
        socket.off('agentCallEnd');
        socket.off('ringNewAgentForMerge');
        socket.off('addedToConference');
        socket.off('ringNewAgentForTransfer');
      };
    }
  }, [socket]);
  useEffect(() => {
    GET(cloudCallUrl + `outbound/organisation/did-numbers`, {
      pageNo: 0,
      pageSize: 1000,
      organisation_id: orgId,
      active: true,
    })
      .then((res) => res.data.response)
      .then((res) => {
        setNumbers({ numbers: res.items });
        if (res.items.length >= 1) {
          setDid({ did: res.items[0]._id });
        } else {
          setDid({ did: '' });
        }
      })
      .catch((err) => console.log(err));
    getDispositions();
  }, []);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      formattedTime = `${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callType, selectedCampaign, batchId]);

  const handleBeforeUnload = async (event) => {
    console.log('In the handle before', callType);
    event.preventDefault();
    event.returnValue = '';
    if (
      selectedCampaign &&
      localStorage.getItem('doosra-biz-organisation-id')
    ) {
      await stopBatchCall();
    }
    if (callType === 'VOIP') {
      callEnd();
    }
    resetValues();
    destroyWebsocketConnection();
  };

  const CampaignInfo = (props) => {
    return (
      <div className="campaign-info">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <label className="Campaign-info-label">Campaign info</label>
        </div>
        <div>
          <div className="campaign-details">
            <label className="Campaign-details-key">Total leads</label>
            <label className="Campaign-details-value">
              {selectedCampaignDetails.totalLeads}
            </label>
          </div>
          <div className="campaign-details">
            <label className="Campaign-details-key">No of leads called</label>
            <label className="Campaign-details-value">
              {selectedCampaignDetails.leadsCalled}
            </label>
          </div>
          <div className="campaign-details">
            <label className="Campaign-details-key">Campaign completion</label>
            <label
              className="Campaign-details-value"
              style={{ color: '#07aa3f' }}
            >
              {selectedCampaignDetails.answerRate !== 'NaN'
                ? selectedCampaignDetails.answerRate
                : 0}
              %
            </label>
          </div>
        </div>
      </div>
    );
  };
  const dailNextLead = () => {
    console.log('dail next lead button');
  };
  const handleWebsocketConnection = () => {
    if (agentStatus === 'Online' && isWebRTCEnabled === 'true') {
      GET(
        cloudCallUrl +
        `outbound/get-active-domains-organisation?organisationId=${orgId}`
      )
        .then((res) => res.data.response)
        .then((res) => {
          if (res?.length > 0) {
            const uaList = res.map((server) => {
              const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
              const uaConfig = {
                uri: `sip:${username}@${server}`,
                password: password,
                sockets: [socket],
                connection_recovery_min_interval: 5,
              };
              const ua = new JsSIP.UA(uaConfig);
              ua.start();
              return { ua: ua };
            });
            console.log(uaList);
            setUAList({ UAList: uaList });
            return uaList;
          }
        })
        .catch((err) => console.log(err));
    }
    if (agentStatus !== 'Online' && agentStatus !== 'Busy') {
      if (callType === 'VOIP') {
        callEnd();
      }
      resetValues();
      destroyWebsocketConnection();
      setIsOpen({ isOpen: false });
    }
  };

  useEffect(() => {
    handleWebsocketConnection();
  }, [agentStatus]);

  const changeCampaignDetails = (campaignId) => {
    const campaignObj = agentActiveCampaigns.filter(
      (campaign) => campaign._id === campaignId
    );
    console.log(campaignObj);
    setSelectedCampaignDetails({
      selectedCampaignDetails: {
        totalLeads: campaignObj[0]?.batchCallObj?.totalLeads,
        leadsCalled: campaignObj[0]?.batchCallObj?.leadsCalled,
        answerRate: (
          (parseInt(campaignObj[0]?.batchCallObj?.leadsCalled) /
            parseInt(campaignObj[0]?.batchCallObj?.totalLeads)) *
          100
        ).toFixed(2),
        campaignType: campaignObj[0]?.campaignType,
        callType: campaignObj[0]?.callType,
        batchCallId: campaignObj[0]?.batchCallObj?.batchCallId,
      },
    });
  };
  const getDispositions = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallUrl + 'disposition', data)
      .then((res) => {
        setDispositions({ dispositions: res.data.response.dispositions });
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  useEffect(() => {
    if (showDispositionUpdatedMessage) {
      setTimeout(() => {
        setShowDispositionUpdatedMessage(false);
      }, 3000);
    }
  }, [showDispositionUpdatedMessage]);

  const updateDisposition = (callId, disposition) => {
    const data = {
      callId,
      disposition,
      isIncomingCall
    };
    return PUT(
      cloudCallUrl + 'outbound/update-disposition-outbound-call',
      {},
      data
    )
      .then((res) => {
        setSelectedDisposition(disposition);
        setDisposition({ disposition });
        setShowDispositionUpdatedMessage(true);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const addDispositionForConnectedCall = async (callId, callTag) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      callTag,
      callId,
    };
    await POST(cloudCallUrl + 'campaign/add-call-tag', data)
      .then((res) => {
        setSelectedDisposition(disposition);
        setDisposition({ disposition });
        setShowDispositionUpdatedMessage(true);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  const micOn = () => {
    if (RTCSessionData) {
      RTCSessionData.unmute();
    }
  };

  const micOff = () => {
    if (RTCSessionData) {
      RTCSessionData.mute();
    }
  };

  const holdCall = () => {
    if (RTCSessionData) {
      RTCSessionData.hold();
    }
  };

  const unHoldCall = () => {
    if (RTCSessionData) {
      RTCSessionData.unhold();
    }
  };
  const callEnd = async () => {
    if (isIncomingCall) {
      onIncomingCallHangUp();
      stopRingtone();
    } else {
      const requestData = {
        callId
      }
      await POST(cloudCallUrl + 'cloud-call/agent-hangup', requestData);
    }
  };

  const handleAgentStatus = async (eventKey) => {
    const breakReasonMap = {
      Break: 'break',
      Online: 'active',
      Busy: 'busy',
    };

    let breakReason = breakReasonMap[eventKey] || 'offline';
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: '',
      breakReason,
    };
    if (eventKey === 'Online') {
      data.breakSwitch = 'off';
    } else {
      data.breakSwitch = 'on';
    }
    console.log(data);
    await PUT(cloudCallUrl + 'metric/update-agent-break-time', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  const campaignExitScreen = () => {
    console.log('show campaign exit ');
    setShowCampaignExitScreen({ showCampaignExitScreen: true });
    setIsCampaignInProgress({ isCampaignInProgress: false });
  };
  const initiateCall = () => {
    if (!contactId && !callId) {
      if (!destinationNumber) {
        toast.error('Destination Number is required');
        return;
      }
      if (
        destinationNumber.length !== 10 ||
        isNaN(destinationNumber) === true
      ) {
        toast.error('Please check the destination number');
        return;
      }
    }
    if (!did) {
      if (numbers.length === 0) {
        toast.error('No Caller ID available');
      } else {
        toast.error('Please select the did');
      }
      return;
    }
    if (!callType) {
      toast.error('Please select call Connectivity');
      return;
    }
    if (showDailNextButton) {
      toast.error('Please wait while previous call is being updated');
      return;
    }
    const callData = {
      number: destinationNumber,
      didId: did,
      contactId: null,
      userId: localStorage.getItem('doosra-biz-user-id'),
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      callType,
      contactId,
      callId,
    };
    let apiRoute = 'cloud-call/initiate-outbound-call';
    console.log('campaign call', campaignId);
    if (campaignId) {
      callData.isSingleCall = true;
      callData.campaignId = campaignId;
      callData.singleCallId = callId;
      apiRoute = 'cloud-call/initiate-call';
    }
    POST(`${cloudCallUrl}${apiRoute}`, callData)
      .then((res) => {
        setCallId({
          callId: res.data.response?.referenceId || res.data.response?.callId,
        });
        console.log('The call ils being connected');
        setIsCallInProgress({ isCallInProgress: true });
        setAgentStatus({ agentStatus: 'Busy' });
        handleAgentStatus('Busy');
      })
      .catch((err) => {
        console.log('The error is', err);
        toast.error(
          err.response?.data?.error?.reason ||
          'An error occured while initating the calls'
        );
      });
  };

  const onSearchAgentForCallMergeAndTransfer = (e) => {
    let filteredAgents = unfilteredAgentsList;
    if (e.target.value) {
      const pattern = new RegExp(e.target.value, 'i');
      filteredAgents = agentsList.filter((agent) => pattern.test(agent.name));
    }
    setAgentsList({ agentsList: filteredAgents });
  };

  return (
    <>
      {incomingCallPopup ?
        <IncomingCallPopup
          type={callType}
          callerName={destinationName}
          callerNumber={destinationNumber}
          onAnswer={onIncomingCallAnswer}
          onReject={onIncomingCallHangUp}
          way={incomingWay}
        />
        : null
      }
      <div className="tooltip-container" >
        {isCallInProgress && !isOpen ? (
          <DailerInProgress>
            <DailerInProgressInfo>
              {/* <DailerInProgressNumber style={{fontSize: "12px", top: "9px"}}>Single Call</DailerInProgressNumber> */}
              {/* <br></br> */}
              <DailerInProgressNumber style={{ fontSize: '10px', top: '14px' }}>
                {callStatus === 'Ended'
                  ? // ? `Dail Next In(${dailNextTimer})`
                  'Dail Next'
                  : `+91 ${destinationNumber}`}
              </DailerInProgressNumber>
            </DailerInProgressInfo>
            <DailerInProgressInfoTimer>
              {formatTime(timer)}
            </DailerInProgressInfoTimer>
            {callType === 'VOIP' ? (
              <DailerInProgressEndButton onClick={() => callEnd()}>
                <img src={callEndLogo} alt=""></img>
              </DailerInProgressEndButton>
            ) : null}
            <img
              style={{ position: 'absolute', left: '240px', top: '20px' }}
              src={circleDown}
              onClick={() => {
                setIsOpen({ isOpen: !isOpen });
              }}
              alt=""
            ></img>
          </DailerInProgress>
        ) : (
          <DailerButton
            ref={buttonRef}
            className={
              (agentStatus === 'Online' || agentStatus === 'Busy')
                ? 'Dailer'
                : 'DailerInActive'
            }
            onClick={() => {
              if (agentStatus === 'Online' || agentStatus === 'Busy') {
                setIsOpen({ isOpen: !isOpen });
              }
            }}
          >
            <img src={DailPadIcon} width={28} height={28} />
          </DailerButton>
        )}
        <div className="tooltip-text left" data-tip-position="left">
          Dialer
        </div>
      </div>

      <div
        style={{
          background: 'white',
          display: isOpen ? 'block' : 'none',
          width: '300px',
          height:
            showCampaignExitScreen && selectedDailer === DIALER_TYPES.POWER
              ? '290px'
              : selectedDailer === DIALER_TYPES.POWER &&
                isCallInProgress === true
                ? '445px'
                : '430px',
          borderRadius: '5px',
          boxShadow: '0px 0px 4px 0px black',
          position: 'absolute',
          right: `${distanceToRight}px`,
          top: '15%',
          zIndex: '10',
          borderRadius: '12px',
          overflow: 'hidden',
        }}
      >
        <GradientDiv>
          <div
            style={{
              position: 'absolute',
              marginTop: '24px',
              marginLeft: '24px',
            }}
          >
            <img src={AlohaaLogo} width={24} height={20}></img>
          </div>
          <img
            src={ExpandCircleDown}
            onClick={() => {
              if (agentStatus === 'Online' || agentStatus === 'Busy') {
                setIsOpen({ isOpen: !isOpen });
              }
            }}
            style={{ position: 'absolute', right: '20px', top: '20px' }}
          ></img>
          {isIncomingCallEnabledForAgent
            && isIncomingCallEnabledForOrg &&
            isIncomingWebrtcEnabled &&
            incomingPreferenceMode === 'VOIP' &&
            <div className='incoming-ring-toggle'>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-left">
                    Enable it to receive ring sound for incoming browser calls.
                  </Tooltip>
                }
              >
                <CustomSwitch
                  checked={incomingRingSoundToggle}
                  onClick={(e) => handleIncomingRingSoundToggle(e.target.checked)} />
              </OverlayTrigger>
            </div>
          }

          {props.isAgentOutboundCallEnabled &&
            <p
              onClick={() => {
                if (isCampaignInProgress) {
                  toast.error('There is a active campaign');
                  return;
                }
                setSelectedDailer({ selectedDailer: DIALER_TYPES.SOLO });
              }}
              style={{
                position: 'absolute',
                top: '60px',
                left: '27px',
                marginBottom: '0px',
                paddingBottom: '14px',
                borderBottom:
                  selectedDailer === DIALER_TYPES.SOLO ? '3px solid white' : '',
                color: 'white',
                fontSize: '14px',
                fontWeight: 700,
                cursor: 'pointer',
              }}
            >
              Solo dialer{' '}
            </p>
          }
          {props.isAgentOutboundCallEnabled && localStorage.getItem('is_campaigns_v2_enabled') === 'true' && (
            <p
              onClick={() => {
                if (isCallInProgress) {
                  toast.error('There is a active call');
                  return;
                }
                setSelectedDailer({ selectedDailer: DIALER_TYPES.POWER });
              }}
              style={{
                position: 'absolute',
                top: '60px',
                right: '24px',
                marginBottom: '0px',
                paddingBottom: '14px',
                borderBottom:
                  selectedDailer === DIALER_TYPES.POWER
                    ? '3px solid white'
                    : '',
                color: 'white',
                fontSize: '14px',
                fontWeight: 700,
                cursor: 'pointer',
              }}
            >
              Power dialer{' '}
            </p>
          )}
        </GradientDiv>

        {showCallMergeAndTransferUI ? (
          <div
            style={{
              width: '300px',
              height:
                showCampaignExitScreen && selectedDailer === DIALER_TYPES.POWER
                  ? '290px'
                  : selectedDailer === DIALER_TYPES.POWER &&
                    isCallInProgress === true
                    ? '445px'
                    : '430px',
              display: 'flex',
              flexDirection: 'column',
            }}
            ref={dailpadContainer}
          >
            {showCallMergeAndTransferUI === 'merge-screen-1' && (
              <>
                <Card
                  style={{
                    borderRadius: '0%',
                    border: 'none',
                    display: 'flex',
                    height: '43px',
                    padding: '0px 13px 0px 13px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                  }}
                >
                  <div className="call-merge-add-a-call">
                    <img
                      style={{ cursor: 'pointer', marginTop: '-2px' }}
                      src={backIcon}
                      width={'16px'}
                      height={'16px'}
                      onClick={() =>
                        activeCallAgents.length
                          ? setShowCallMergeAndTransferUI({
                            showCallMergeAndTransferUI: 'merge-screen-3',
                          })
                          : setShowCallMergeAndTransferUI({
                            showCallMergeAndTransferUI: '',
                          })
                      }
                    />
                    &nbsp;&nbsp;Add to Call
                  </div>
                  <div className="call-merge-back-button">
                    <img
                      src={refreshIcon}
                      width={'20px'}
                      height={'20px'}
                      onClick={getAgentsAvailability}
                    />
                  </div>
                </Card>
                <Card
                  style={{
                    borderRadius: '0%',
                    border: 'none',
                    background: '#FEF7FF',
                    height: '40px',
                    padding: '0px 13px 0px 13px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <input
                    className="call-merge-agent-search"
                    type="text"
                    placeholder={'Search'}
                    onChange={onSearchAgentForCallMergeAndTransfer}
                  />
                  <span
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: '#aaa',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                  >
                    🔍
                  </span>
                </Card>
                <div style={{ overflow: 'scroll', height: '195px' }}>
                  {agentsList?.map((i) => (
                    <Card
                      style={{
                        borderRadius: '0%',
                        display: 'flex',
                        width: '100%',
                        height: '60px',
                        padding: '0px 13px 0px 13px',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <div style={{ textAlign: 'left' }}>
                        <div className="call-merge-agent-name">{i.name}</div>
                        <div
                          className={
                            'call-merge-status call-merge-status-' + i.status
                          }
                        >
                          <div
                            className={
                              'call-merge-colored-dot call-merge-status-colored-dot-' +
                              i.status
                            }
                          ></div>
                          {i.status === 'online' ? 'Live' : i.status}
                        </div>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <div>
                          <img
                            style={
                              i.status === 'online' ? { cursor: 'pointer' } : {}
                            }
                            src={
                              i.status === 'online'
                                ? coloredCallMergeAdd
                                : greyCallMergeAdd
                            }
                            width={'18px'}
                            height={'18px'}
                            onClick={() =>
                              i.status === 'online'
                                ? mergeCallApi(MERGE_ACTIONS.MERGE, callId, i)
                                : null
                            }
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              </>
            )}
            {showCallMergeAndTransferUI === 'merge-screen-2' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    borderRadius: '0px 0px 12px 12px',
                    width: '100%',
                    height: '60px',
                    padding: '0px 13px 0px 13px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>
                    <div className="call-merge-customer-name">
                      {' '}
                      {onGoingOtherAgent?.name || 'Agent'}
                    </div>
                    <div className="call-merge-customer-number">{`+91 ${onGoingOtherAgent?.number || '1234567890'
                      }`}</div>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <div className="call-merge-customer-call-duration">
                      {formatTime(onGoingOtherAgent.timer)}
                    </div>
                    <div
                      className="call-merge-customer-call-status"
                      style={{
                        color:
                          onGoingOtherAgent.callStatus === 'Ended'
                            ? 'red'
                            : 'green',
                      }}
                    >
                      {onGoingOtherAgent.callStatus}
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      (onGoingOtherAgent?.status === 'Answered' ||
                        onGoingOtherAgent?.status === 'Ringing') &&
                        callType === 'PSTN' ? (
                        <Tooltip id="tooltip-left">
                          Use your phone to manage GSM calls.
                        </Tooltip>
                      ) : (
                        <div></div>
                      )
                    }
                  >
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        background:
                          onGoingOtherAgent?.status === 'Ringing' ||
                            onGoingOtherAgent?.status === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : isMicOff
                              ? 'red'
                              : 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: `1px solid ${onGoingOtherAgent?.status === 'Ringing' ||
                          onGoingOtherAgent?.status === 'Ended' ||
                          callType === 'PSTN'
                          ? '#D9D9D9'
                          : 'red'
                          }`,
                        cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        marginRight: '0px',
                      }}
                      onClick={() => {
                        if (onGoingOtherAgent?.status !== 'Ringing') {
                          if (isMicOff) {
                            micOn();
                          } else {
                            micOff();
                          }
                          setisMicOff({ isMicOff: !isMicOff });
                        }
                      }}
                    >
                      <img
                        src={
                          onGoingOtherAgent?.status === 'Ringing' ||
                            onGoingOtherAgent?.status === 'Ended' ||
                            callType === 'PSTN'
                            ? GreyMic
                            : isMicOff
                              ? MicOff
                              : Mic
                        }
                        width={'20px'}
                        height={'20px'}
                      ></img>
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      (onGoingOtherAgent?.status === 'Answered' ||
                        onGoingOtherAgent?.status === 'Ringing') &&
                        callType === 'PSTN' ? (
                        <Tooltip id="tooltip-left">
                          Use your phone to manage GSM calls.
                        </Tooltip>
                      ) : (
                        <div></div>
                      )
                    }
                  >
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        background:
                          onGoingOtherAgent?.status === 'Ringing' ||
                            onGoingOtherAgent?.status === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : isPaused
                              ? 'red'
                              : 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: `1px solid ${onGoingOtherAgent?.status === 'Ringing' ||
                          onGoingOtherAgent?.status === 'Ended' ||
                          callType === 'PSTN'
                          ? '#D9D9D9'
                          : 'red'
                          }`,
                        cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        marginLeft: '15px',
                      }}
                      onClick={() => {
                        if (onGoingOtherAgent?.status !== 'Ringing') {
                          if (isPaused) {
                            unHoldCall();
                          } else {
                            holdCall();
                          }
                          setIsPaused({ isPaused: !isPaused });
                        }
                      }}
                    >
                      <img
                        src={
                          onGoingOtherAgent?.status === 'Ringing' ||
                            onGoingOtherAgent?.status === 'Ended' ||
                            callType === 'PSTN'
                            ? GreyPause
                            : isPaused
                              ? Play
                              : Pause
                        }
                        width={'20px'}
                        height={'20px'}
                      ></img>
                    </div>
                  </OverlayTrigger>
                </div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    (onGoingOtherAgent?.status === 'Answered' ||
                      onGoingOtherAgent?.status === 'Ringing') &&
                      callType === 'PSTN' ? (
                      <Tooltip id="tooltip-left">
                        Use your phone to manage GSM calls.
                      </Tooltip>
                    ) : (
                      <div></div>
                    )
                  }
                >
                  <Card
                    style={{
                      display: 'flex',
                      height: '40px',
                      margin: '40px 10px 10px 10px',
                      flexDirection: 'row',
                      cursor: 'pointer',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 'var(--32, 32px)',
                      background:
                        onGoingOtherAgent?.callStatus === 'Answered' ||
                          onGoingOtherAgent?.callStatus === 'Ended'
                          ? 'var(--Alohaa-Green, #07AA3F)'
                          : 'lightgrey',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                    onClick={() =>
                      onGoingOtherAgent?.callStatus === 'Ended'
                        ? mergeCallApi(MERGE_ACTIONS.UN_HOLD, callId)
                        : onGoingOtherAgent?.callStatus === 'Answered'
                          ? mergeCurrentCallToExistingCalls(callId)
                          : null
                    }
                  >
                    <span className="call-merge-add-to-call-button">
                      {onGoingOtherAgent?.callStatus === 'Answered'
                        ? 'Add to Call'
                        : 'Go Back to Call'}
                    </span>
                  </Card>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    (onGoingOtherAgent?.status === 'Answered' ||
                      onGoingOtherAgent?.status === 'Ringing') &&
                      callType === 'PSTN' ? (
                      <Tooltip id="tooltip-left">
                        Use your phone to manage GSM calls.
                      </Tooltip>
                    ) : (
                      <div></div>
                    )
                  }
                >
                  <Card
                    style={{
                      display: 'flex',
                      height: '40px',
                      margin: '0px 10px 10px 10px',
                      cursor: 'pointer',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 'var(--32, 32px)',
                      background:
                        callType !== 'PSTN'
                          ? 'var(--Alohaa-Red, #F8153B)'
                          : 'lightgrey',
                    }}
                    onClick={() =>
                      callType !== 'PSTN'
                        ? mergeCallApi(
                          MERGE_ACTIONS.CALL_END,
                          callId,
                          onGoingOtherAgent
                        )
                        : null
                    }
                  >
                    <img src={HangupLogo}></img>
                  </Card>
                </OverlayTrigger>
                {/* <button onClick={() => agentConnected()}>Agent Connected </button> */}
              </>
            )}
            {(showCallMergeAndTransferUI === 'merge-screen-1' ||
              showCallMergeAndTransferUI === 'merge-screen-2') && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '1px',
                    display: 'flex',
                    borderRadius: '0px 0px 12px 12px',
                    width: '100%',
                    height: '60px',
                    padding: '0px 13px 0px 13px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>
                    <div className="call-merge-customer-name">
                      {' '}
                      {destinationName || 'Single Call'}
                    </div>
                    <div className="call-merge-customer-number">{`+91 ${destinationNumber}`}</div>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <div className="call-merge-customer-call-duration">
                      {formatTime(timer)}
                    </div>
                    <div
                      className="call-merge-customer-call-status"
                      style={{ color: 'green' }}
                    >
                      {callStatus}
                    </div>
                  </div>
                </div>
              )}
            {showCallMergeAndTransferUI === 'merge-screen-3' && (
              <>
                <div style={{ height: '200px', overflow: 'scroll' }}>
                  {
                    <>
                      {showModal && (
                        <Modal
                          show={true}
                          onHide={handleClose}
                          container={dailpadContainer.current}
                          backdrop={false}
                          centered
                          className="dailer-modal-box"
                          style={{ position: 'absolute' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              backgroundColor: 'white',
                              padding: '20px',
                              gap: '14px',
                            }}
                          >
                            <img
                              src={redCallEnd}
                              width={'24px'}
                              height={'24px'}
                            />
                            <div className="modal-merge-call-end-header">
                              End call?
                            </div>
                            <div className="modal-merge-call-end-description">
                              Choose how to end the call
                            </div>
                            <div className="modal-merge-call-end-me">
                              End call for me
                            </div>
                            <div className="modal-merge-call-end-everyone">
                              End for everyone
                            </div>
                            <div
                              className="modal-merge-call-end-back"
                              onClick={handleClose}
                            >
                              Back
                            </div>
                          </div>
                        </Modal>
                      )}
                    </>
                  }
                  <Card
                    style={{
                      display: 'flex',
                      borderRadius: '0px',
                      width: '100%',
                      height: '60px',
                      padding: '0px 13px 0px 13px',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>
                      <span className="call-merge-customer-name">
                        {' '}
                        {destinationName || 'Single Call'}{' '}
                        <img
                          src={activeCallIcon}
                          width={'14px'}
                          height={'14px'}
                          style={{ marginTop: '-4px' }}
                        ></img>
                      </span>
                      <div className="call-merge-customer-number">{`External • +91 ${destinationNumber}`}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '4px',
                        marginBottom: '-7px',
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>
                        <div className="call-merge-customer-call-duration">
                          {formatTime(timer)}
                        </div>
                        <div
                          className="call-merge-customer-call-status"
                          style={{ color: 'green' }}
                        >
                          {callStatus}
                        </div>
                      </div>
                      <div
                        style={{
                          width: '26px',
                          height: '26px',
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%',
                          background:
                            callType !== 'PSTN'
                              ? 'var(--Alohaa-Red, #F8153B)'
                              : 'lightgrey',
                          border: `1px solid ${callType !== 'PSTN' ? '#F8153B' : 'lightgrey'
                            }`,
                        }}
                        onClick={callType === 'PSTN' ? null : handleShow}
                      >
                        <img
                          src={HangupLogo}
                          width={'14px'}
                          height={'6px'}
                        ></img>
                      </div>
                    </div>
                  </Card>
                  {activeCallAgents.map((i) => (
                    <Card
                      style={{
                        display: 'flex',
                        width: '100%',
                        height: '60px',
                        padding: '0px 13px 0px 13px',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                      }}
                    >
                      <div style={{ textAlign: 'left' }}>
                        <span className="call-merge-customer-name">
                          {' '}
                          {i.name}{' '}
                          <img
                            src={activeCallIcon}
                            width={'14px'}
                            height={'14px'}
                            style={{ marginTop: '-4px' }}
                          ></img>
                        </span>
                        <div className="call-merge-customer-number">
                          {'Agent'}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '4px',
                          marginBottom: '-7px',
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>
                          <div className="call-merge-customer-call-duration">
                            {formatTime(i.timer)}
                          </div>
                          <div
                            className="call-merge-customer-call-status"
                            style={{ color: 'green' }}
                          >
                            {i.callStatus}
                          </div>
                        </div>
                        <div
                          style={{
                            width: '26px',
                            height: '26px',
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            background:
                              callType !== 'PSTN'
                                ? 'var(--Alohaa-Red, #F8153B)'
                                : 'lightgrey',
                            border: `1px solid ${callType !== 'PSTN' ? '#F8153B' : 'lightgrey'
                              }`,
                          }}
                        >
                          <img
                            src={HangupLogo}
                            width={'14px'}
                            height={'6px'}
                            onClick={() =>
                              callType === 'PSTN'
                                ? null
                                : mergeCallApi(
                                  MERGE_ACTIONS.CALL_END,
                                  callId,
                                  i
                                )
                            }
                          ></img>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '1px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    borderRadius: '0px 0px 12px 12px',
                    width: '100%',
                    height: '124px',
                    padding: '0px 20px 10px 20px',
                    backgroundColor: '#fff',
                    boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <select
                        onChange={(e) =>
                          campaignId
                            ? addDispositionForConnectedCall(
                              callId,
                              e.target.value
                            )
                            : updateDisposition(callId, e.target.value)
                        }
                        style={{
                          height: '36px',
                          width: '260px',
                          display: 'block',
                          margin: '5px auto 0px auto',
                          color: themeType === 'light' ? 'black' : 'white',
                          background:
                            themeType === 'light' ? 'white' : '#282828',
                          borderRadius: '5px',
                          border: '1px solid var(--Light-Gray, #D9D9D9)',
                        }}
                      >
                        <option
                          value=""
                          selected={true}
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: 400,

                            // color: themeType === 'light' ? '#9B9B9B' : 'white',
                            color: 'green',
                          }}
                        >
                          Select disposition
                        </option>
                        {dispositions.map((i) => (
                          <option
                            className="call-merge-disposition-text"
                            key={i._id}
                            value={i.dispositon}
                            selected={
                              disposition === i.dispositon ? true : false
                            }
                          >
                            {i.dispositon}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : isMicOff
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (callStatus !== 'Ringing') {
                            if (isMicOff) {
                              micOn();
                            } else {
                              micOff();
                            }
                            setisMicOff({ isMicOff: !isMicOff });
                          }
                        }}
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? GreyMic
                              : isMicOff
                                ? MicOff
                                : Mic
                          }
                          width={'15px'}
                          height={'15px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (callStatus !== 'Ringing') {
                            if (isPaused) {
                              unHoldCall();
                            } else {
                              holdCall();
                            }
                            setIsPaused({ isPaused: !isPaused });
                          }
                        }}
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? GreyPause
                              : isPaused
                                ? Play
                                : Pause
                          }
                          width={'15px'}
                          height={'15px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use it for merging your peer agent
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType !== 'PSTN'
                              ? '#D9D9D9'
                              : isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor:
                            callType !== 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() =>
                          callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType !== 'PSTN'
                            ? null
                            : goToMergeAndTransferMode('merge')
                        }
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType !== 'PSTN'
                              ? greyAddAgent
                              : coloredAddAgent
                          }
                          width={'36px'}
                          height={'36px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN' ||
                              showCallMergeAndTransferUI === 'merge-screen-4'
                              ? '#D9D9D9'
                              : isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN' ||
                            showCallMergeAndTransferUI === 'merge-screen-4'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() =>
                          callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType !== 'PSTN' ||
                            showCallMergeAndTransferUI === 'merge-screen-4'
                            ? null
                            : goToMergeAndTransferMode('transfer')
                        }
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN' ||
                              showCallMergeAndTransferUI === 'merge-screen-4'
                              ? greyCallTransfer
                              : coloredCallTransfer
                          }
                          width={'36px'}
                          height={'36px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '64px',
                          height: '36px',
                          borderRadius: '22px',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : !isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={callType !== 'PSTN' ? handleShow : null}
                      >
                        <img
                          src={HangupLogo}
                          width={'22px'}
                          height={'9px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </>
            )}
            {showCallMergeAndTransferUI === 'merge-screen-4' && (
              <>
                <div style={{ height: '200px', overflow: 'scroll' }}>
                  <Card
                    style={{
                      display: 'flex',
                      borderRadius: '0px',
                      width: '100%',
                      height: '60px',
                      padding: '0px 13px 0px 13px',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                    }}
                  >
                    <div style={{ textAlign: 'left' }}>
                      <span className="call-merge-customer-name">
                        {' '}
                        {destinationName || 'Single Call'}{' '}
                        <img
                          src={activeCallIcon}
                          width={'14px'}
                          height={'14px'}
                          style={{ marginTop: '-4px' }}
                        ></img>
                      </span>
                      <div className="call-merge-customer-number">{`External • +91 ${destinationNumber}`}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '4px',
                        marginBottom: '-7px',
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>
                        <div className="call-merge-customer-call-duration">
                          ...
                        </div>
                        <div
                          className="call-merge-customer-call-status"
                          style={{ color: 'green' }}
                        >
                          {callStatus}
                        </div>
                      </div>
                      <div
                        style={{
                          width: '26px',
                          height: '26px',
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%',
                          background: 'lightgrey',
                          border: `1px solid lightgrey`,
                        }}
                      >
                        <img
                          src={HangupLogo}
                          width={'14px'}
                          height={'6px'}
                        ></img>
                      </div>
                    </div>
                  </Card>
                  {activeCallAgents.map((i) => (
                    <Card
                      style={{
                        display: 'flex',
                        width: '100%',
                        height: '60px',
                        padding: '0px 13px 0px 13px',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                      }}
                    >
                      <div style={{ textAlign: 'left' }}>
                        <span className="call-merge-customer-name">
                          {' '}
                          {i.number} ({i.name}){' '}
                          <img
                            src={activeCallIcon}
                            width={'14px'}
                            height={'14px'}
                            style={{ marginTop: '-4px' }}
                          ></img>
                        </span>
                        <div className="call-merge-customer-number">
                          {'Agent'}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '4px',
                          marginBottom: '-7px',
                        }}
                      >
                        <div style={{ textAlign: 'right' }}>
                          <div className="call-merge-customer-call-duration">
                            ...
                          </div>
                          <div
                            className="call-merge-customer-call-status"
                            style={{ color: 'green' }}
                          >
                            Answered
                          </div>
                        </div>
                        <div
                          style={{
                            width: '26px',
                            height: '26px',
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            background: 'lightgrey',
                            border: `1px solid lightgrey`,
                          }}
                        >
                          <img
                            src={HangupLogo}
                            width={'14px'}
                            height={'6px'}
                            onClick={() => null}
                          ></img>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '1px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    borderRadius: '0px 0px 12px 12px',
                    width: '100%',
                    height: '124px',
                    padding: '0px 20px 10px 20px',
                    backgroundColor: '#fff',
                    boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  {/* <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }} >
                    <div>
                      <select
                        onChange={(e) => campaignId ? addDispositionForConnectedCall(callId, e.target.value) : updateDisposition(callId, e.target.value)}
                        style={{
                          height: '36px',
                          width: '260px',
                          display: 'block',
                          margin: '5px auto 0px auto',
                          color: themeType === 'light' ? 'black' : 'white',
                          background: themeType === 'light' ? 'white' : '#282828',
                          borderRadius: '5px',
                          border: '1px solid var(--Light-Gray, #D9D9D9)'
                        }}
                      >
                        <option value="" selected={true} style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontStyle: 'italic',
                          fontWeight: 400,

                          // color: themeType === 'light' ? '#9B9B9B' : 'white',
                          color: 'green',
                        }}>
                          Select disposition
                        </option>
                        {dispositions.map((i) => (
                          <option
                            className='call-merge-disposition-text'
                            key={i._id}
                            value={i.dispositon}
                            selected={disposition === i.dispositon ? true : false}
                          >
                            {i.dispositon}
                          </option>
                        ))}
                      </select>
                    </div>

                  </div> */}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : isMicOff
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (callStatus !== 'Ringing') {
                            if (isMicOff) {
                              micOn();
                            } else {
                              micOff();
                            }
                            setisMicOff({ isMicOff: !isMicOff });
                          }
                        }}
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? GreyMic
                              : isMicOff
                                ? MicOff
                                : Mic
                          }
                          width={'15px'}
                          height={'15px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (callStatus !== 'Ringing') {
                            if (isPaused) {
                              unHoldCall();
                            } else {
                              holdCall();
                            }
                            setIsPaused({ isPaused: !isPaused });
                          }
                        }}
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? GreyPause
                              : isPaused
                                ? Play
                                : Pause
                          }
                          width={'15px'}
                          height={'15px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use it for merging your peer agent
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType !== 'PSTN' ||
                              showCallMergeAndTransferUI === 'merge-screen-4'
                              ? '#D9D9D9'
                              : isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor:
                            callType !== 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() =>
                          callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType !== 'PSTN' ||
                            showCallMergeAndTransferUI === 'merge-screen-4'
                            ? null
                            : goToMergeAndTransferMode('merge')
                        }
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType !== 'PSTN' ||
                              showCallMergeAndTransferUI === 'merge-screen-4'
                              ? greyAddAgent
                              : coloredAddAgent
                          }
                          width={'36px'}
                          height={'36px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN' ||
                              showCallMergeAndTransferUI === 'merge-screen-4'
                              ? '#D9D9D9'
                              : isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN' ||
                            showCallMergeAndTransferUI === 'merge-screen-4'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() =>
                          callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType !== 'PSTN' ||
                            showCallMergeAndTransferUI === 'merge-screen-4'
                            ? null
                            : goToMergeAndTransferMode('transfer')
                        }
                      >
                        <img
                          src={
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN' ||
                              showCallMergeAndTransferUI === 'merge-screen-4'
                              ? greyCallTransfer
                              : coloredCallTransfer
                          }
                          width={'36px'}
                          height={'36px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        (callStatus === 'Answered' ||
                          callStatus === 'Ringing') &&
                          callType === 'PSTN' ? (
                          <Tooltip id="tooltip-left">
                            Use your phone to manage GSM calls.
                          </Tooltip>
                        ) : (
                          <div></div>
                        )
                      }
                    >
                      <div
                        style={{
                          width: '64px',
                          height: '36px',
                          borderRadius: '22px',
                          background:
                            callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : !isPaused
                                ? 'red'
                                : 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Ringing' ||
                            callStatus === 'Ended' ||
                            callType === 'PSTN'
                            ? '#D9D9D9'
                            : 'red'
                            }`,
                          cursor:
                            callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={callType !== 'PSTN' ? handleShow : null}
                      >
                        <img
                          src={HangupLogo}
                          width={'22px'}
                          height={'9px'}
                        ></img>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </>
            )}
            {showCallMergeAndTransferUI === 'transfer-screen-1' && (
              <>
                {
                  <>
                    {showModal && dailpadContainer?.current && (
                      <Modal
                        show={true}
                        onHide={handleClose}
                        container={dailpadContainer.current}
                        backdrop={false}
                        centered
                        className="dailer-modal-box"
                        style={{ position: 'absolute' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            backgroundColor: 'white',
                            padding: '20px',
                            gap: '14px',
                          }}
                        >
                          <img src={forward} width={'25px'} height={'25px'} />
                          <div className="modal-transfer-call-end-header">{`Forward call to ${showModal?.name || 'Agent'
                            }?`}</div>
                          <div className="modal-transfer-call-end-description">
                            You will be disconnected from this call once it has
                            been transferred
                          </div>
                          <div
                            className="modal-transfer-forward-call"
                            onClick={() => transferCallApi(callId, showModal)}
                          >
                            Forward call
                          </div>
                          <div
                            className="modal-merge-call-end-back"
                            onClick={handleClose}
                          >
                            Back
                          </div>
                        </div>
                      </Modal>
                    )}
                  </>
                }
                <Card
                  style={{
                    borderRadius: '0%',
                    border: 'none',
                    display: 'flex',
                    height: '43px',
                    padding: '0px 13px 0px 13px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                  }}
                >
                  <div className="call-merge-add-a-call">
                    <img
                      style={{ cursor: 'pointer', marginTop: '-2px' }}
                      src={backIcon}
                      width={'16px'}
                      height={'16px'}
                      onClick={() =>
                        setShowCallMergeAndTransferUI({
                          showCallMergeAndTransferUI: '',
                        })
                      }
                    />
                    &nbsp;&nbsp;Transfer Call
                  </div>
                  <div className="call-merge-back-button">
                    <img
                      src={refreshIcon}
                      width={'20px'}
                      height={'20px'}
                      onClick={getAgentsAvailability}
                    />
                  </div>
                </Card>
                <Card
                  style={{
                    borderRadius: '0%',
                    border: 'none',
                    background: '#FEF7FF',
                    height: '40px',
                    padding: '0px 13px 0px 13px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <input
                    className="call-merge-agent-search"
                    type="text"
                    placeholder={'Search agent or group'}
                    onChange={onSearchAgentForCallMergeAndTransfer}
                  />
                  <span
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: '#aaa',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                  >
                    🔍
                  </span>
                </Card>
                <div style={{ overflow: 'scroll', height: '195px' }}>
                  {agentsList.map((i) => (
                    <Card
                      style={{
                        borderRadius: '0%',
                        display: 'flex',
                        width: '100%',
                        height: '60px',
                        padding: '0px 13px 0px 13px',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <div style={{ textAlign: 'left' }}>
                        <div style={{ display: 'flex', gap: '5px' }}>
                          <div
                            className={
                              'call-merge-status call-merge-status-' + i.status
                            }
                          >
                            <div
                              className={
                                'call-merge-colored-dot call-merge-status-colored-dot-' +
                                i.status
                              }
                            ></div>
                          </div>
                          <div className="call-merge-agent-name">{i.name}</div>
                        </div>
                        <div className="call-transfer-group-agent-type">
                          Agent
                        </div>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <div>
                          <img
                            style={
                              i.status === 'online' ? { cursor: 'pointer' } : {}
                            }
                            src={i.status === 'online' ? forward : greyForward}
                            width={'18px'}
                            height={'18px'}
                            onClick={() =>
                              i.status === 'online' ? setShowModal(i) : null
                            }
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '1px',
                    display: 'flex',
                    borderRadius: '0px 0px 12px 12px',
                    width: '100%',
                    height: '60px',
                    padding: '0px 13px 0px 13px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>
                    <div className="call-merge-customer-name">
                      {' '}
                      {destinationName || 'Single Call'}
                    </div>
                    <div className="call-merge-customer-number">{`+91 ${destinationNumber}`}</div>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <div className="call-merge-customer-call-duration">
                      {formatTime(timer)}
                    </div>
                    <div
                      className="call-merge-customer-call-status"
                      style={{ color: 'green' }}
                    >
                      {callStatus}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            {props.isAgentOutboundCallEnabled ?
              selectedDailer === DIALER_TYPES.SOLO ? (
                isCallInProgress ? (
                  <DailerDiv theme={themeType}>
                    <div style={{ display: 'flex', paddingTop: '16px' }}>
                      <label
                        style={{
                          marginLeft: '16px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >
                        {destinationName || 'Single Call'}
                      </label>
                      <label
                        style={{
                          marginLeft: 'auto',
                          marginRight: '10px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >
                        {formatTime(timer)}
                      </label>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <label
                        style={{
                          marginLeft: '16px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >{`+91 ${destinationNumber}`}</label>
                      <label
                        style={{
                          marginLeft: 'auto',
                          marginRight: '10px',
                          fontWeight: 700,
                          color: showDailNextButton ? 'red' : 'green',
                        }}
                      >
                        {callStatus}
                      </label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          (callStatus === 'Answered' ||
                            callStatus === 'Ringing') &&
                            callType === 'PSTN' ? (
                            <Tooltip id="tooltip-left">
                              Use your phone to manage GSM calls.
                            </Tooltip>
                          ) : (
                            <div></div>
                          )
                        }
                      >
                        <div
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            background:
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN'
                                ? '#D9D9D9'
                                : isMicOff
                                  ? 'red'
                                  : 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `1px solid ${callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : 'red'
                              }`,
                            cursor:
                              callType === 'PSTN' ? 'not-allowed' : 'pointer',
                            marginRight: '0px',
                          }}
                          onClick={() => {
                            if (callStatus !== 'Ringing') {
                              if (isMicOff) {
                                micOn();
                              } else {
                                micOff();
                              }
                              setisMicOff({ isMicOff: !isMicOff });
                            }
                          }}
                        >
                          <img
                            src={
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN'
                                ? GreyMic
                                : isMicOff
                                  ? MicOff
                                  : Mic
                            }
                            width={'20px'}
                            height={'20px'}
                          ></img>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          (callStatus === 'Answered' ||
                            callStatus === 'Ringing') &&
                            callType === 'PSTN' ? (
                            <Tooltip id="tooltip-left">
                              Use your phone to manage GSM calls.
                            </Tooltip>
                          ) : (
                            <div></div>
                          )
                        }
                      >
                        <div
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            background:
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN'
                                ? '#D9D9D9'
                                : isPaused
                                  ? 'red'
                                  : 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `1px solid ${callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN'
                              ? '#D9D9D9'
                              : 'red'
                              }`,
                            cursor:
                              callType === 'PSTN' ? 'not-allowed' : 'pointer',
                            marginLeft: '15px',
                          }}
                          onClick={() => {
                            if (callStatus !== 'Ringing') {
                              if (isPaused) {
                                unHoldCall();
                              } else {
                                holdCall();
                              }
                              setIsPaused({ isPaused: !isPaused });
                            }
                          }}
                        >
                          <img
                            src={
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN'
                                ? GreyPause
                                : isPaused
                                  ? Play
                                  : Pause
                            }
                            width={'20px'}
                            height={'20px'}
                          ></img>
                        </div>
                      </OverlayTrigger>
                      {localStorage.getItem('call_merge_enabled') === 'true' &&
                        callType === 'PSTN' && (
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              (callStatus === 'Answered' ||
                                callStatus === 'Ringing') &&
                                callType === 'PSTN' ? (
                                <Tooltip id="tooltip-left">
                                  Use it for merging your peer agent
                                </Tooltip>
                              ) : (
                                <div></div>
                              )
                            }
                          >
                            <div
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                background:
                                  callStatus === 'Ringing' ||
                                    callStatus === 'Ended' ||
                                    callType !== 'PSTN' ||
                                    dontAllowMergeAndTransfer === 'both' ||
                                    dontAllowMergeAndTransfer === 'merge'
                                    ? '#D9D9D9'
                                    : isPaused
                                      ? 'red'
                                      : 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor:
                                  callType !== 'PSTN' ? 'not-allowed' : 'pointer',
                                marginLeft: '15px',
                              }}
                              onClick={() =>
                                callStatus === 'Ringing' ||
                                  callStatus === 'Ended' ||
                                  callType !== 'PSTN' ||
                                  dontAllowMergeAndTransfer === 'both' ||
                                  dontAllowMergeAndTransfer === 'merge'
                                  ? null
                                  : goToMergeAndTransferMode('merge')
                              }
                            >
                              <img
                                src={
                                  callStatus === 'Ringing' ||
                                    callStatus === 'Ended' ||
                                    callType !== 'PSTN' ||
                                    dontAllowMergeAndTransfer === 'both' ||
                                    dontAllowMergeAndTransfer === 'merge'
                                    ? greyAddAgent
                                    : coloredAddAgent
                                }
                                width={'50px'}
                                height={'50px'}
                              ></img>
                            </div>
                          </OverlayTrigger>
                        )}
                      {localStorage.getItem('call_transfer_enabled') === 'true' &&
                        callType === 'PSTN' && (
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              ((callStatus === 'Answered' ||
                                callStatus === 'Ringing') &&
                                callType === 'PSTN') ||
                                dontAllowMergeAndTransfer === 'both' ||
                                dontAllowMergeAndTransfer === 'transfer' ? (
                                <Tooltip id="tooltip-left">
                                  Use it for transfering to your peer agent
                                </Tooltip>
                              ) : (
                                <div></div>
                              )
                            }
                          >
                            <div
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                background:
                                  callStatus === 'Ringing' ||
                                    callStatus === 'Ended' ||
                                    callType !== 'PSTN' ||
                                    dontAllowMergeAndTransfer === 'both' ||
                                    dontAllowMergeAndTransfer === 'transfer'
                                    ? '#D9D9D9'
                                    : isPaused
                                      ? 'red'
                                      : 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${callStatus === 'Ringing' ||
                                  callStatus === 'Ended' ||
                                  callType !== 'PSTN' ||
                                  dontAllowMergeAndTransfer === 'both' ||
                                  dontAllowMergeAndTransfer === 'transfer'
                                  ? '#D9D9D9'
                                  : 'red'
                                  }`,
                                cursor:
                                  callType !== 'PSTN' ? 'not-allowed' : 'pointer',
                                marginLeft: '15px',
                              }}
                              onClick={() =>
                                callStatus === 'Ringing' ||
                                  callStatus === 'Ended' ||
                                  callType !== 'PSTN' ||
                                  dontAllowMergeAndTransfer === 'both' ||
                                  dontAllowMergeAndTransfer === 'transfer'
                                  ? null
                                  : goToMergeAndTransferMode('transfer')
                              }
                            >
                              <img
                                src={
                                  callStatus === 'Ringing' ||
                                    callStatus === 'Ended' ||
                                    callType !== 'PSTN' ||
                                    dontAllowMergeAndTransfer === 'both' ||
                                    dontAllowMergeAndTransfer === 'transfer'
                                    ? greyCallTransfer
                                    : coloredCallTransfer
                                }
                                width={'50px'}
                                height={'50px'}
                              ></img>
                            </div>
                          </OverlayTrigger>
                        )}
                    </div>
                    <p
                      style={{
                        marginTop: '15px',
                        marginRight: '135px',
                        color: 'black',
                        marginBottom: '12px',
                        borderRadius: '5px',
                        color: themeType === 'light' ? 'black' : 'white',
                      }}
                    >
                      Select Disposition
                    </p>
                    <select
                      onChange={(e) =>
                        campaignId
                          ? addDispositionForConnectedCall(callId, e.target.value)
                          : updateDisposition(callId, e.target.value)
                      }
                      style={{
                        height: '36px',
                        width: '260px',
                        display: 'block',
                        margin: '0px auto 15px auto',
                        color: themeType === 'light' ? 'black' : 'white',
                        background: themeType === 'light' ? 'white' : '#282828',
                        borderRadius: '5px',
                      }}
                    >
                      <option value="" selected={true}>
                        Select disposition
                      </option>
                      {dispositions.map((i) => (
                        <option
                          key={i._id}
                          value={i.dispositon}
                          selected={disposition === i.dispositon ? true : false}
                        >
                          {i.dispositon}
                        </option>
                      ))}
                    </select>

                    {showDispositionUpdatedMessage ? (
                      <label
                        style={{
                          position: 'absolute',
                          left: '70px',
                          fontSize: '12px',
                          color: 'green',
                        }}
                      >
                        <img className="disposition-updated" src={GreenTick} />
                        Disposition Updated
                      </label>
                    ) : null}

                    {showDailNextButton ? (
                      <DailNextButton onClick={() => resetValues()}>
                        <label
                          style={{
                            color: '#07AA3F',
                            marginBottom: '0px',
                            cursor: 'pointer',
                          }}
                        >
                          {/* Dail Next In ({dailNextTimer}) */}
                          Dial Next
                        </label>
                      </DailNextButton>
                    ) : (
                      <EndButton
                        style={{
                          background: callType === 'PSTN' ? 'lightgrey' : 'red',
                          cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => callEnd()}
                      >
                        <img src={HangupLogo}></img>
                      </EndButton>
                    )}
                  </DailerDiv>
                ) : (
                  <DailerDiv theme={themeType}>
                    <p
                      style={{
                        margin: '0px 140px 10px 16px',
                        color: themeType === 'light' ? 'black' : 'white',
                        fontWeight: 400,
                        paddingTop: '15px',
                      }}
                    >
                      Enter number
                    </p>
                    <input
                      type={'text'}
                      value={destinationNumber}
                      style={{
                        height: '36px',
                        width: '216px',
                        display: 'block',
                        margin: '0px auto',
                        borderRadius: '5px',
                        border: '1px solid #D9D9D9',
                        marginTop: '5px',
                        color: themeType === 'light' ? 'black' : 'white',
                        background: themeType === 'light' ? 'white' : '#282828',
                      }}
                      onChange={(e) => {
                        if (callId) {
                          setCallId({ callId: '' });
                        }
                        if (contactId) {
                          setContactId({ contactId: '' });
                        }
                        if (campaignId) {
                          setCampaignId({ campaignId: '' });
                        }
                        const destinationNo = e.target.value.replace(
                          /[^0-9#]/g,
                          ''
                        );
                        setDestinationNumber({
                          destinationNumber: destinationNo,
                        });
                      }}
                    ></input>
                    <p
                      style={{
                        margin: '15px 135px 10px 16px',
                        color: 'black',
                        fontWeight: 400,
                        color: themeType === 'light' ? 'black' : 'white',
                      }}
                    >
                      Select Caller ID
                    </p>
                    <select
                      style={{
                        height: '36px',
                        width: '216px',
                        display: 'block',
                        margin: '0px auto',
                        marginTop: '5px',
                        borderRadius: '5px',
                        color: themeType === 'light' ? 'black' : 'white',
                        background: themeType === 'light' ? 'white' : '#282828',
                      }}
                      disabled={
                        numbers.length === 1 || numbers.length === 0
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setDid({ did: e.target.value });
                      }}
                    >
                      <>
                        {numbers.length === 0 && (
                          <option value="" selected={did === '' ? true : false}>
                            No Caller ID available
                          </option>
                        )}
                        {numbers.length === 1 &&
                          numbers.map((number) => (
                            <option key={number._id} value={number._id}>
                              {number.virtualNumber}
                            </option>
                          ))}
                        {numbers.length > 1 &&
                          numbers.map((number) => (
                            <option
                              key={number._id}
                              value={number._id}
                              selected={number._id === did}
                            >
                              {number.virtualNumber}
                            </option>
                          ))}
                      </>
                    </select>
                    <p
                      style={{
                        margin:
                          localStorage.getItem('webrtc_enabled') !== 'true' ||
                            !isBrowserCallEnabledForUser
                            ? '15px 110px 2px 16px'
                            : '15px 125px 2px 16px',
                        color: themeType === 'light' ? 'black' : 'white',
                      }}
                    >
                      Call Connectivity
                      {(localStorage.getItem('webrtc_enabled') !== 'true' ||
                        !isBrowserCallEnabledForUser) && (
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip id="tooltip-left">
                                Upgrade to get access to browser calling
                              </Tooltip>
                            }
                          >
                            <img src={Help} style={{ marginLeft: '4px' }}></img>
                          </OverlayTrigger>
                        )}
                    </p>
                    <div>
                      <label
                        style={{
                          marginTop: '10px',
                          marginRight: '40px',
                          color: 'black',
                          marginBottom: '0px',
                          fontSize: '16px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >
                        <input
                          type="radio"
                          value="PSTN"
                          checked={callType === 'PSTN'}
                          disabled={
                            !(localStorage.getItem('pstn_enabled') === 'true')
                          }
                          onChange={() => {
                            setCallType({ callType: 'PSTN' });
                          }}
                        />
                        &nbsp; GSM
                      </label>
                      <label
                        style={{
                          color: 'black',
                          marginRight: '70px',
                          marginBottom: '10px',
                          fontSize: '16px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >
                        <input
                          type="radio"
                          value="VOIP"
                          checked={callType === 'VOIP'}
                          disabled={
                            !(
                              localStorage.getItem('webrtc_enabled') === 'true'
                            ) || !isBrowserCallEnabledForUser
                          }
                          onChange={() => {
                            setCallType({ callType: 'VOIP' });
                          }}
                        />
                        &nbsp; Browser
                      </label>
                    </div>
                    <DailButton
                      style={{ margin: '15px auto 0px auto' }}
                      onClick={() => initiateCall()}
                    >
                      <img src={phoneWhite}></img>
                    </DailButton>
                  </DailerDiv>
                )
              ) : showCampaignExitScreen ? (
                <ExitCampaignDiv theme={themeType}>
                  <span className="Campaign-exited-label">Campaign exited</span>
                  <span className="Customer-outreach-label">
                    Customer outreach campaign {campaignCompletionStat} called
                  </span>
                  {!selectedCampaignDetails.isCampaignCompleted ? (
                    <ResumeCallingButton
                      onClick={() => {
                        if (showDailNextButton) {
                          toast.error(
                            'Please wait while previous call is being updated'
                          );
                          return;
                        } else {
                          if (!loading) {
                            setShowCampaignExitScreen({
                              showCampaignExitScreen: false,
                            });
                            initiateCampaignCalls(batchId);
                          }
                        }
                      }}
                    >
                      <label
                        style={{
                          color: '#07AA3F',
                          marginBottom: '0px',
                          cursor: 'pointer',
                        }}
                      >
                        Resume calling
                      </label>
                    </ResumeCallingButton>
                  ) : null}

                  <span
                    onClick={() => {
                      setShowCampaignExitScreen({
                        showCampaignExitScreen: false,
                      });
                      getAgentActiveCampaigns();
                      setIsCampaignInProgress({ isCampaignInProgress: false });
                    }}
                    className="Choose-another-campaign-label"
                  >
                    Choose another campaign
                  </span>
                </ExitCampaignDiv>
              ) : isCampaignInProgress ? (
                showCustomerDetails ? (
                  <div
                    style={{
                      width: '300px',
                      height:
                        showCampaignExitScreen &&
                          selectedDailer === DIALER_TYPES.POWER
                          ? '290px'
                          : selectedDailer === DIALER_TYPES.POWER &&
                            isCallInProgress === true
                            ? '330px'
                            : '330px',

                    }}
                  >
                    <div
                      style={{
                        textAlign: 'left',
                        height: "270px",
                        overflowY: 'auto',
                      }}
                    >
                      <div
                        style={{ marginBottom: "24px" }}
                        className="customer-info"
                        onClick={() => {
                          setShowCustomerDetails({ showCustomerDetails: false });
                        }}
                      >
                        <img
                          src={BlackBlueArrow}
                          style={{
                            cursor: 'pointer',
                            marginTop: '-2px',
                            marginLeft: '24px',
                            marginRight: '8px',
                          }}
                        ></img>
                        Customer info
                      </div>
                      {Object.keys(leadDetails).map((key) =>
                        key !== 'agent' &&
                          key !== 'lastCallAgent' &&
                          key !== 'disposition' &&
                          key !== 'agentId' &&
                          key !== 'status' ? (
                          <>
                            <div className="customer-info-header">{key}</div>
                            <div className="customer-info-value">
                              {leadDetails[key]}
                            </div>
                          </>
                        ) : null
                      )}
                    </div>
                    <div className='customer-info-call-box'>
                      <div className='customer-info-name'>{destinationName || ''}</div>
                      <div className='customer-info-number'>{`${destinationNumber}`}</div>
                      <div className='customer-info-timer'>{formatTime(timer)}</div>
                      <div className='customer-info-status' style={{ color: showDailNextButton ? 'red' : 'green' }}>{callStatus}</div>
                      <div className='customer-info-hangup' onClick={() => callEnd()}>
                        <img src={HangupLogo}></img>
                      </div>
                    </div>

                  </div>
                ) : (
                  <DailerDiv theme={themeType}>
                    <div style={{ display: 'flex', paddingTop: '16px' }}>
                      <label
                        style={{
                          marginLeft: '16px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >
                        {destinationName || ''}
                      </label>
                      <label
                        style={{
                          marginLeft: 'auto',
                          marginRight: '10px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >
                        {formatTime(timer)}
                      </label>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <label
                        style={{
                          marginLeft: '16px',
                          color: themeType === 'light' ? 'black' : 'white',
                        }}
                      >{`+91 ${destinationNumber}`}</label>
                      <label
                        style={{
                          marginLeft: 'auto',
                          marginRight: '10px',
                          fontWeight: 700,
                          color: showDailNextButton ? 'red' : 'green',
                        }}
                      >
                        {callStatus}
                      </label>
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'space-evenly' }}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          background:
                            callStatus === 'Answered' ? 'white' : '#D9D9D9',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px solid ${callStatus === 'Answered' ? 'red' : '#D9D9D9'
                            }`,
                          marginRight: '15px',
                          cursor:
                            callStatus === 'Answered' ? 'pointer' : 'not-allowed',
                        }}
                        onClick={() => {
                          if (callStatus === 'Answered') {
                            setShowCustomerDetails({ showCustomerDetails: true });
                          }
                        }}
                      >
                        <label
                          style={{
                            margin: '0px',
                            padding: '0px',
                            color: `${callStatus === 'Answered' ? 'red' : 'grey'
                              }`,
                            fontWeight: 'bolder',
                          }}
                        >
                          i
                        </label>
                      </div>

                      <OverlayTrigger
                        placement="left"
                        overlay={
                          (callStatus === 'Answered' ||
                            callStatus === 'Ringing') &&
                            (callType === 'PSTN' ||
                              (selectedCampaignDetails &&
                                selectedCampaignDetails.callType === 'PSTN')) ? (
                            <Tooltip id="tooltip-left">
                              Use your phone to manage GSM calls.
                            </Tooltip>
                          ) : (
                            <div></div>
                          )
                        }
                      >
                        <div
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            background:
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN' ||
                                (selectedCampaignDetails &&
                                  selectedCampaignDetails.callType === 'PSTN')
                                ? '#D9D9D9'
                                : isMicOff
                                  ? 'red'
                                  : 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `1px solid ${callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN' ||
                              (selectedCampaignDetails &&
                                selectedCampaignDetails.callType === 'PSTN')
                              ? '#D9D9D9'
                              : 'red'
                              }`,
                            cursor:
                              callType === 'PSTN' ||
                                (selectedCampaignDetails &&
                                  selectedCampaignDetails.callType === 'PSTN')
                                ? 'not-allowed'
                                : 'pointer',
                            marginRight: '15px',
                          }}
                          onClick={() => {
                            if (callStatus !== 'Ringing') {
                              if (isMicOff) {
                                micOn();
                              } else {
                                micOff();
                              }
                              setisMicOff({ isMicOff: !isMicOff });
                            }
                          }}
                        >
                          <img
                            src={
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN' ||
                                (selectedCampaignDetails &&
                                  selectedCampaignDetails.callType === 'PSTN')
                                ? GreyMic
                                : isMicOff
                                  ? MicOff
                                  : Mic
                            }
                          ></img>
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="left"
                        overlay={
                          (callStatus === 'Answered' ||
                            callStatus === 'Ringing') &&
                            (callType === 'PSTN' ||
                              (selectedCampaignDetails &&
                                selectedCampaignDetails.callType === 'PSTN')) ? (
                            <Tooltip id="tooltip-left">
                              Use your phone to manage GSM calls.
                            </Tooltip>
                          ) : (
                            <div></div>
                          )
                        }
                      >
                        <div
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            background:
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN' ||
                                (selectedCampaignDetails &&
                                  selectedCampaignDetails.callType === 'PSTN')
                                ? '#D9D9D9'
                                : isPaused
                                  ? 'red'
                                  : 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `1px solid ${callStatus === 'Ringing' ||
                              callStatus === 'Ended' ||
                              callType === 'PSTN' ||
                              (selectedCampaignDetails &&
                                selectedCampaignDetails.callType === 'PSTN')
                              ? '#D9D9D9'
                              : 'red'
                              }`,
                            cursor:
                              callType === 'PSTN' ||
                                (selectedCampaignDetails &&
                                  selectedCampaignDetails.callType === 'PSTN')
                                ? 'not-allowed'
                                : 'pointer',
                            marginLeft: '15px',
                          }}
                          onClick={() => {
                            if (callStatus !== 'Ringing') {
                              if (isPaused) {
                                unHoldCall();
                              } else {
                                holdCall();
                              }
                              setIsPaused({ isPaused: !isPaused });
                            }
                          }}
                        >
                          <img
                            src={
                              callStatus === 'Ringing' ||
                                callStatus === 'Ended' ||
                                callType === 'PSTN' ||
                                (selectedCampaignDetails &&
                                  selectedCampaignDetails.callType === 'PSTN')
                                ? GreyPause
                                : isPaused
                                  ? Play
                                  : Pause
                            }
                          ></img>
                        </div>
                      </OverlayTrigger>
                    </div>
                    {/* <p
              style={{
                marginTop: '15px',
                marginRight: '135px',
                color: 'black',
                marginBottom: '12px',
                borderRadius: '5px',
                color: themeType === 'light' ? 'black' : 'white',
              }}
            >
              Select Disposition
            </p> */}
                    {callId && (
                      <select
                        onChange={(e) =>
                          addDispositionForConnectedCall(callId, e.target.value)
                        }
                        style={{
                          height: '36px',
                          width: '260px',
                          display: 'block',
                          margin: '24px auto 15px auto',
                          color: themeType === 'light' ? 'black' : 'white',
                          background: themeType === 'light' ? 'white' : '#282828',
                          borderRadius: '5px',
                        }}
                      >
                        <option value="" selected={true}>
                          Select disposition
                        </option>
                        {dispositions.map((i) => (
                          <option
                            key={i._id}
                            value={i.dispositon}
                            selected={disposition === i.dispositon ? true : false}
                          >
                            {i.dispositon}
                          </option>
                        ))}
                      </select>
                    )}
                    {showDispositionUpdatedMessage ? (
                      <label
                        style={{
                          position: 'absolute',
                          left: '70px',
                          fontSize: '12px',
                          color: 'green',
                        }}
                      >
                        <img className="disposition-updated" src={GreenTick} />
                        Disposition Updated
                      </label>
                    ) : null}
                    {showDailNextButton ? (
                      <DailNextButton type="power" onClick={() => dailNextLead()}>
                        <label
                          style={{
                            color: '#07AA3F',
                            marginBottom: '0px',
                            cursor: 'pointer',
                          }}
                        >
                          Next Call In ({dailNextTimer})
                        </label>
                      </DailNextButton>
                    ) : (
                      <EndButton
                        type="power"
                        style={{
                          background:
                            callType === 'PSTN' ||
                              (selectedCampaignDetails &&
                                selectedCampaignDetails.callType === 'PSTN')
                              ? 'lightgrey'
                              : 'red',
                          cursor:
                            callType === 'PSTN' ||
                              (selectedCampaignDetails &&
                                selectedCampaignDetails.callType === 'PSTN')
                              ? 'not-allowed'
                              : 'pointer',
                        }}
                        onClick={() => callEnd()}
                      >
                        <img src={HangupLogo}></img>
                      </EndButton>
                    )}
                    <div>
                      <p
                        className="Exit-campaign"
                        onClick={() => {
                          setIsCallInProgress({ isCallInProgress: false });
                          stopBatchCall();
                        }}
                      >
                        Exit campaign
                      </p>
                      <p className="campaign-completion-text">
                        Customer outreach campaign {campaignCompletionStat} called
                      </p>
                    </div>
                  </DailerDiv>
                )
              ) : (
                <DailerDiv theme={themeType}>
                  <p
                    style={{
                      margin: '15px 135px 10px 16px',
                      color: 'black',
                      fontWeight: 400,
                      color: themeType === 'light' ? 'black' : 'white',
                    }}
                  >
                    Select campaign
                  </p>
                  <select
                    style={{
                      height: '36px',
                      width: '240px',
                      display: 'block',
                      margin: '0px auto',
                      marginTop: '5px',
                      borderRadius: '5px',
                      color: themeType === 'light' ? 'black' : 'white',
                      background: themeType === 'light' ? 'white' : '#282828',
                    }}
                    disabled={agentActiveCampaigns.length === 0 ? true : false}
                    onChange={(e) => {
                      setSelectedCampaign({ selectedCampaign: e.target.value });
                      changeCampaignDetails(e.target.value);
                    }}
                  >
                    {agentActiveCampaigns.length === 0 && (
                      <option key={''} value={''} selected={true}>
                        No Active Campaigns
                      </option>
                    )}
                    {agentActiveCampaigns.length > 0 &&
                      agentActiveCampaigns.map((campaign) => (
                        <option
                          key={campaign._id}
                          value={campaign._id}
                          selected={campaign._id === selectedCampaign}
                        >
                          {campaign.campaignName}
                        </option>
                      ))}
                  </select>
                  {selectedCampaign ? <CampaignInfo /> : null}
                  <DailButton
                    style={{
                      margin: '15px 35px 0px 35px',
                      position: 'absolute',
                      bottom: '16px',
                      cursor:
                        agentActiveCampaigns.length === 0 || loading
                          ? 'not-allowed'
                          : 'pointer',
                      background:
                        agentActiveCampaigns.length === 0 || loading
                          ? 'grey'
                          : 'var(--Alohaa-Green, #07aa3f)',
                    }}
                    onClick={() => {
                      if (agentActiveCampaigns.length > 0 || !loading) {
                        initiateCampaignCalls();
                      }
                    }}
                  >
                    <img src={phoneWhite}></img>
                  </DailButton>
                </DailerDiv>
              ) :
              <div
                className='only-incoming-calls'
              > You don't have access to make outgoing calls.
              </div>
            }
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default commonStyle(
  connect(mapStateToProps, {
    setIsOpen,
    setisMicOff,
    setIsPaused,
    setIsCallInProgress,
    setNumbers,
    setDestinationNumber,
    setDid,
    setCallType,
    setCallStatus,
    setUAList,
    setRTCSessionData,
    setDispositions,
    setTimer,
    setRunTimer,
    setCallId,
    setShowDailNextButton,
    setDailNextTimer,
    setContactId,
    setAgentStatus,
    setDisposition,
    setSelectedDailer,
    setAgentActiveCampaigns,
    setSelectedCampaign,
    setSelectedCampaignDetails,
    setShowCampaignExitScreen,
    setIsCampaignInProgress,
    setDestinationName,
    setBatchId,
    setCampaignCompletionStat,
    setCampaignId,
    setShowCallMergeAndTransferUI,
    setUnfilteredAgentsList,
    setAgentsList,
    setActiveCallAgents,
    setOngoingOtherAgent,
    setOngoingOtherAgentTimerStatus,
    setOngoingCallInterval,
    setActiveAgentIntervals,
    updateOngoingOtherAgent,
    increaseTimerofActiveCallAgent,
    setDontAllowMergeAndTransfer,
    setShowCustomerDetails,
    setLeadDetails,
    setIsIncomingCall,
    setIncomingCallPopup,
    setIncomingWay,
    setIncomingRingSoundToggle
  })(DailPad)
);
