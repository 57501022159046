/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import styled from '@emotion/styled';
const moment = require('moment-timezone');

import './styles.css';


const SearchBar = (props) => {
  const [fromDate, setFromDate] = useState(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'));
  const [selectedCampaign, setSelectedCampaign] = useState('');

  const onChange = (key, value) => {
    if (key === 'fromDate') {
      setFromDate(value);
    }
    if (key === 'toDate') {
      setToDate(value);
    }
  }

  const onSearch = () => {
    const data = {
      fromDate,
      toDate,
      campaignId: selectedCampaign
    };
    props.setFromDate(fromDate);
    props.setToDate(toDate);
    props.search(data);
  }

  return (
    <div className={`${props.className}`}>
      <div className="SearchBarComponent">

        <div style={{ flex: 1 }} />

        {localStorage.getItem('is_campaigns_v2_enabled') === 'true' && <div className="date-range-container">
          <label className="date-range-label">Campaign</label>
          <div >
            <select
              className="SearchDropdown"
              id="campaign"
              onChange={(e) => setSelectedCampaign(e.target.value)}
            >
              <option value="">ALL</option>
              {props.campaignsList.map(i => <option key={i._id} value={i._id}>{i.campaignName}</option>)}
            </select>
          </div>
        </div>}
        <div className="date-range-container">
          <label className="date-range-label">Date range</label>
          <div className="date-range-inputs">
            <input
              type="date"
              className="date-input"
              id="fromDate"
              value={fromDate}
              onChange={(e) => onChange(e.target.id, e.target.value)}
            />

            <span className="date-range-separator">-</span>
            <input
              type="date"
              className="date-input"
              id="toDate"
              value={toDate}
              onChange={(e) => onChange(e.target.id, e.target.value)}
            />
          </div>
        </div>
        <div className="date-range-container">
          <button className='btn btn-success exportbutton' onClick={onSearch}>Search</button>
        </div>
      </div>
    </div >
  );
}

export default styled(SearchBar)`
display: flex;
flex: 1;

input::placeholder {
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}
input {
  color-scheme: ${(props) => props.theme.colors.dateCalenderIconColor};
}
.SearchBarComponent {
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  display: flex;
  grid-template-columns: 136px auto auto 116px;
  align-items: center;
  border-radius: 1px;
  box-shadow: 0px -15px 10px -15px rgb(0, 0, 0, 0.4);
  border: solid 1px ${(props) => props.theme.colors.searchBarBgcolor};
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

.SearchDropdown {
   width: 140px;
   height: 28px;
  font-size: 13px;
  margin: auto 5px;
  padding: 5px 8px;
  margin-bottom:1px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}

.Otherbutton {
  width: 140px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.lightGreyBgColor};
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.AgentStatus {
  width: 150px;
  height: 32px;
  display: flex;
  margin: 16px 16px 16px;
  padding: 7px 0px 8px 0px;
  font-size: 15px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: white;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SelectedContainer {
  width: 136px;
  height: 54px;
  background-color: #196cca;
}

.SelectedContainer h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-top: 11px;
  padding-left: 14px;
  color: #ffffff;
}

.SelectedContainer p {
  font-size: 16px;
  font-weight: bold;
  padding-left: 14px;
  margin: 0;
  letter-spacing: 1.2px;
  color: #ffffff;
}

@media only screen and (max-width: 900px) {
  .SearchBarComponent {
    width: fit-content;
    grid-template-columns: 48px 136px 77px auto 150px;
  }
}
`;
