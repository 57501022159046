/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import "../component/styles.css";
import { OUTGOING_CALL_LOGS_MAPPER } from "../../../common/constants";
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import phoneWhite from '../../../assets/images/ioscall.png';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import AudioPlayer from '../component/AudioPlayer';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import AddIndividualCallDispositionsPopup from "../component/AddIndividualCallDispositionsPopup"
import PreLoader from '../../../common/component/preLoader/PreLoader';
import ModifyHeadersPopUp from "../component/ModifyHeadersPopUp";
import { setCallLogsTableHeaders } from "../../../actions/recoveryXActions";
import moment from 'moment';
import { toast } from 'react-toastify';
import CallReportSearchBar from '../component/CallReportSearchBar';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const userUrl = process.env.REACT_APP_USER_API;

export function Calls(props) {
  const statusMapper = {
    'answered': 'Answered',
    'not_answered': "Not Answered"
  };
  let displayData;
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [leadListId, setLeadListId] = useState('');
  const [totalOrganisationCalls, setTotalOrganisationCalls] = useState(0);
  const [activeLoginUsersCount, setActiveLoginUsersCount] = useState(0);
  const [totalOrganisationDuration, setTotalOrganisationDuration] = useState(0);
  const [leadListContacts, setLeadListContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalCalls, setTotalCalls] = useState(0);
  const [exportFilters, setExportFilters] = useState({});
  const [users, setUsers] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState(props?.callLogsTableHeaders ||[]);
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);

  useEffect(() => {
    setFilteredHeaders(props?.callLogsTableHeaders || ["INITIATOR NAME", "INITIATOR NUMBER", "DESTINATION NUMBER", "CALL STATUS", "DATE", "DISPOSITION"]);
  }, [props?.callLogsTableHeaders])
  const getLeadsCount = (count) => {
    setCount(count);
  };
  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      cloudCallCenterUrl + 'disposition',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions)
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }

  const fetchUserData = () => {
    if (userRole === "admin" || userRole === "ADMIN") {
      let data = {
        pageNo: 1,
        pageSize: 100,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };
      GET(userUrl + 'v1/user', data)
        .then((res) => {
          const users = res.data.response.users.docs.map((user) => {
            return {
              user_id: user._id.toString(),
              userName: user.zvr_name
            }
          })
          return users;
        })
        .then((res) => {
          setUsers(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => { fetchOrganisationReport(); getLeadListsHandler(); getDispositions(); fetchUserData() }, []);

  const updateCallDispositonTag = (data) => {
    console.log("data", data);
    const tempLeadsData = leadListContacts.map(lead => {
      if (lead._id === data.callId) {
        lead.disposition = data.callTag;
        lead.callComment = data.callComment || "-"
      }
      return lead;
    });
    setLeadListContacts(tempLeadsData);
  }

  const getLeadListsHandler = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      outGoingUrl + 'v1/organisations/outbound-call/get-lead-lists',
      data
    )
      .then((res) => {

        setCount(res.data.count);

        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const fetchOrganisationReport = (pageNo = 1, filter = {}, reset = false) => {
    setLoading(true);
    let data = { "pageNo": pageNo, "pageSize": "15" };
    if ((filter.searchKey && filter.searchCondition)) {
      data.searchKey = filter.searchKey;
      data.searchValue = filter.searchCondition;
    }
    if ((filter.searchKey === "date" && filter.from_date && filter.to_date)) {
      data.searchKey = filter.searchKey;
      data.fromDate = filter.from_date;
      data.toDate = filter.to_date;
    }

    if (!filter.searchKey && !filter.searchValue && searchKey && searchValue && !reset) {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
    }

    if (!filter.searchKey && !filter.searchValue && searchKey && searchKey === "date" && fromDate && toDate && !reset) {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
      data.fromDate = fromDate;
      data.toDate = toDate;
    }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(cloudCallCenterUrl + 'cloud-call-logs/get-calls', data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setLeadListContacts(res.callsData);
        setExportFilters(data);
        setTotalPages(res.totalPages);
        setTotalCalls(res.totalCalls || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (loading) displayData = <PreLoader />;
  if (leadListContacts.length > 0) {
    displayData = leadListContacts.map((row) => (
      <DataTable.Row key={row._id}>
        {
          filteredHeaders.map((header) => {
            switch (header) {
              case "INITIATOR NAME":
                return (
                  <DataTable.Cell>
                    {!row.user || row.user === 'NA' ? '-' : row.user.zvr_name}
                  </DataTable.Cell>
                )
              case "INITIATOR NUMBER":
                return (
                  <DataTable.Cell>
                    {!row.initiatorNumber || row.initiatorNumber === 'NA' ? '-' : row.initiatorNumber}
                  </DataTable.Cell>
                )
              case "DESTINATION NUMBER":
                return (
                  <DataTable.Cell>
                    {!row.destinationNumber || row.destinationNumber === 'NA'
                      ? '-'
                      : row.destinationNumber}
                  </DataTable.Cell>
                )
              case "CALL STATUS":
                return (
                  <DataTable.Cell>
                    {!row.status || row.status === 'NA'
                      ? '-'
                      : statusMapper[row.status]}
                  </DataTable.Cell>
                )
              case "DURATION":
                return (
                  <DataTable.Cell>
                    {!row.duration || row.duration === 'NA'
                      ? '-'
                      : row.duration}
                  </DataTable.Cell>
                )
              case "TIME":
                return (
                  <DataTable.Cell>
                    {!row.createdAt || row.createdAt === 'NA'
                      ? '-'
                      : moment(row.createdAt).format('HH:mm:ss')}
                  </DataTable.Cell>
                )
              case "DATE":
                return (
                  <DataTable.Cell>
                    {!row.createdAt || row.createdAt === 'NA'
                      ? '-'
                      : moment(row.createdAt).format('DD-MM-YYYY')}
                  </DataTable.Cell>
                )
              case "DISPOSITION":
                return (
                  <DataTable.Cell>
                    {!row.disposition || row.disposition === 'NA'
                      ? '-'
                      : row.disposition}
                  </DataTable.Cell>
                )
              case "COMMENT":
                return (
                  <DataTable.Cell>
                    {!row.callComment || row.callComment === 'NA'
                      ? '-'
                      : row.callComment}
                  </DataTable.Cell>
                )
              case "RECORDING":
                return (
                  <DataTable.Cell>
                    {!row.duration || row.duration === 'NA' || row.duration === 0
                      ? ""
                      : (
                        <AudioPlayer
                          callRecordingUrl={row.recordingUrl}
                          callId={row._id}
                        />
                      )}
                  </DataTable.Cell>
                )
            }
          })
        }
        {userRole === "USER" ?
          <DataTable.Cell>
            <AddIndividualCallDispositionsPopup
              open={isModalOpen}
              toggle={toggleModal}
              dispositions={dispositions}
              callId={row._id}
              updateCallDispositonTag={updateCallDispositonTag}
              isDisabled={(!row.disposition || row.disposition === "RNR") ? false : true}
            >
            </AddIndividualCallDispositionsPopup>
          </DataTable.Cell>
          :
          null}
      </DataTable.Row>
    ));
  }

  const tabs = [];
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Overview Dashboard',
      onClick: () => props.history.push('/dashboard'),
      active: false,
    });
  }
  if (userRole === "USER") {
    tabs.push({
      title: 'Campaigns List',
      onClick: () => props.history.push('/agent-campaigns'),
      active: false,
    });
  }

  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Campaigns List',
      onClick: () => props.history.push('/cc-campaigns'),
      active: false,
    });
    tabs.push({
      title: 'Agents',
      onClick: () => props.history.push('/agents'),
      active: false,
    });
    // tabs.push({
    //   title: 'Agent Reports',
    //   onClick: () => props.history.push('/recovery-x-agent-report'),
    //   active: false,
    // });
  }
  tabs.push({
    title: 'Calls',
    subtitle: `${totalCalls}`,
    onClick: () => props.history.push('/calls'),
    active: true,
  });

  if (userRole === "ADMIN") {
    tabs.push({
      title: 'BlackList',
      onClick: () => props.history.push('/blacklist'),
      active: false,
    });
  }

  const tempfunction = (data = {}) => {
    setSearchKey(data.searchKey);
    setSearchValue(data.searchCondition);
    if (data.searchKey === "date") {
      setFromDate(data.from_date)
      setToDate(data.to_date)
    }
    console.log(data);
    fetchOrganisationReport(1, data);
  }
  const resetFunction = () => {
    setSearchKey("");
    setSearchValue("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
    fetchOrganisationReport(1, {}, true)
  }

  const updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      setFilteredHeaders(headers => [...headers, header]);
      props.setCallLogsTableHeaders({callLogsTableHeaders: [...filteredHeaders, header]});
    } else {
      const unFilteredHeaders = filteredHeaders.filter((headerType) => headerType !== header)
      setFilteredHeaders(unFilteredHeaders);
      props.setCallLogsTableHeaders({callLogsTableHeaders: unFilteredHeaders});
    }
  }

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <ModifyHeadersPopUp
              headers={Object.keys(OUTGOING_CALL_LOGS_MAPPER)}
              updateFilteredHeader={updateFilteredHeader}
              filteredHeaders={filteredHeaders}
              isPopUpOpen={isHeadersPopUpOpen}
              openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
              closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
            />
            <SearchBar
              type="recovery-call-logs"
              optional="campaigns"
              exportFilters={exportFilters}
              disableTitle
              count={count}
              getLeadsCount={(e) => getLeadsCount(e)}
              search={(e) => tempfunction(e)}
              reset={(e) => resetFunction(e)}
            />

          </TabSearchBar>
          <CallReportSearchBar
            type="recovery-call-logs"
            disableTitle
            count={count}
            userData={users}
            dispositions={dispositions}
            getLeadsCount={(e) => getLeadsCount(e)}
            search={(e) => tempfunction(e)}
            reset={(e) => resetFunction(e)} />
          {/* <ReportSearchBar
            totalOrganisationCalls={totalOrganisationCalls}
            totalOrganisationDuration={totalOrganisationDuration}
            activeLoginUsersCount={activeLoginUsersCount}
            search={fetchOrganisationReport}
            reset={fetchOrganisationReport}
          /> */}
          <DataTable.Table>
            <DataTable.Head>
              {filteredHeaders.map((header) => (<DataTable.CellHead>{header}</DataTable.CellHead>))}
              {userRole === "USER" ? <DataTable.CellHead>ACTION</DataTable.CellHead> : null}
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => {
              setCurrentPage(value)
              fetchOrganisationReport(value)
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callLogsTableHeaders: state.recovery.callLogsTableHeaders,
});
export default commonStyle(connect(mapStateToProps, {
  setCallLogsTableHeaders
})(Calls));
