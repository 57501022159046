/* eslint-disable no-dupe-keys */
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddCallRemarksPopup from '../component/AddCallRemarksPopup';
import phoneWhite from '../../../assets/images/phoneWhiteRecoveryCall.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import { useSocket } from '../../../providers/SocketProvider';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import CallHistoryPopup from '../component/CallHistoryPopup';
import { setDynamicCampaignTableHeaders} from "../../../actions/recoveryXActions";
import {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  setSingleCallStatus,
  setDestinationName,
  setDestinationNumber,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setCampaignId,
  setDomainsOfCampaign,
  setCallType,
} from '../../../actions/recoveryXActions';

import PreLoader from '../../../common/component/preLoader/PreLoader';

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
export function DynamicCampaign(props) {
  let campaignUniqueId = useSelector((state) => state.recovery.campaignId);
  let batchCallId = useSelector((state) => state.recovery.batchCallId);
  const batchCallIdRef = useRef(batchCallId);
  const campaignUniqueIdRef = useRef(campaignUniqueId);
  const [campaignData, setCampaignData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [dataSearchKey, setDataSearchKey] = useState('');
  const [dataSearchValue, setDataSearchValue] = useState('');
  const [callOptions, setCallOptions] = useState(false);
  const [callOptionNumber, setCallOptionNumber] = useState(null);
  const [callOptionSecondaryNumber, setCallOptionSecondaryNumber] =
    useState(null);
  const [callOptionDetails, setCallOptionDetails] = useState([]);
  const [resumeAutoCalling, setResumeAutoCalling] = useState(true);
  const [filteredHeaders, setFilteredHeaders] = useState([]);
  const [hideTableHeaders, setHideTableHeaders] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [headers, setHeaders] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const agentId = searchParams.get('agentId');
  const campaignId = searchParams.get('campaignId');
  let socket = useSocket();
  const pageNoRef = React.useRef();

  let { callId } = props;
  let singleCallStatus = useSelector(
    (state) => state.recovery.singleCallStatus
  );
  let isConnected = useSelector((state) => state.recovery.isWebRtcConnected);
  /* START OF WEBRTC CALLS RELATED CODE */

  useEffect(() => {
    campaignUniqueIdRef.current = campaignUniqueId;
    batchCallIdRef.current = batchCallId;
    console.log(
      'updated refs',
      campaignUniqueIdRef.current,
      batchCallIdRef.current
    );
  }, [campaignUniqueId, batchCallId]);

  useEffect(() => {
    if (socket) {
      let shouldRefreshCallDetails = true;
      socket.on('setBatchCallId', (message, cb) => {
        props.setBatchCallId(message);
        props.setCampaignId(message);
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('callId', (message, cb) => {
        props.setCallId({ callId: message });
        shouldRefreshCallDetails = true;
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('changeSingleCallStatus', (message, cb) => {
        props.setSingleCallStatus({ singleCallStatus: false });
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('refreshCampaignCallDetails', (message, cb) => {
        if (shouldRefreshCallDetails) {
          shouldRefreshCallDetails = false;
          tempFunction();
        }
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('connectionStatus', (message, cb) => {
        if (!message.connected) {
          stopBatchCall();
        }
        cb({
          status: 'Acknowledged',
        });
      });
    }
  }, [socket])
  
  useEffect(() => {
    setFilteredHeaders(props.dynamicCampaignTableHeaders || ["name", "number"])
  }, [props.dynamicCampaignTableHeaders])

  // Register a user agent to Asterisk server. UseEffet makes the code run only once.
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [campaignUniqueId]);

  const handleOffline = () => {
    toast.error('Network disconnected. Please check internet connection', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleOnline = () => {
    toast.success('Network is up. Please refresh to reconnect to server', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  // Reload  conformation
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    stopBatchCall();
  };

  const resumeAutoCallingTimeHandler = () => {
    setTimeout(() => {
      setResumeAutoCalling(true);
    }, 10000);
  };

  // Reseting values after call ends or fails
  const resetValues = () => {
    props.setSingleCallStatus({ singleCallStatus: false });
    localStorage.setItem('singleCallStatus', false);
    props.setCallId({ callId: '' });
  };

  const handleFetchCampaignDetails = (
    agentId,
    campaignId,
    searchKey = null,
    searchValue = null,
    value
  ) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
      pageNo: value || currentPage,
      pageSize: pageSize,
    };
    if (searchKey && searchValue) {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
    }
    if (dataSearchKey && dataSearchValue) {
      data.searchKey = dataSearchKey;
      data.searchValue = dataSearchValue;
    }
    GET(cloudCallCenterUrl + 'campaign/get-dynamic-campaign-details', data)
      .then((res) => {
        setCampaignData(res.data.response);
        setHeaders(res.data.response.headerKeys);
        setCurrentPage(res.data.response.pageNo);
        setTotalPages(res.data.response.totalPages);
        props.setCallType({ callType: res.data.response.callType });
        setHideTableHeaders(res.data.response.uploadWithOutLeads || false);
        props.setDomainsOfCampaign({
          domainsOfCampaign: res.data.response.domains || [],
        });
        // props.setCallFilterLeads({ callFilterLeads: res.data.response.leads });
      })
      .catch((err) => console.log(err));
  };

  const resetHandleFetchCampaignDetails = (agentId, campaignId) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId,
      agentId,
      pageNo: 1,
      pageSize: pageSize,
    };
    GET(cloudCallCenterUrl + 'campaign/get-dynamic-campaign-details', data)
      .then((res) => {
        setCampaignData(res.data.response);
        setHeaders(res.data.response.headerKeys);
        setCurrentPage(res.data.response.pageNo);
        setTotalPages(res.data.response.totalPages);
        props.setDomainsOfCampaign({
          domainsOfCampaign: res.data.response.domains || [],
        });
        // props.setCallFilterLeads({ callFilterLeads: res.data.response.leads });
      })
      .catch((err) => console.log(err));
  };

  const tempFunction = (message) => {
    handleFetchCampaignDetails(
      agentId,
      campaignId,
      null,
      null,
      pageNoRef.current
    );
  };

  useEffect(() => {
    if (agentId && campaignId) {
      handleFetchCampaignDetails(agentId, campaignId);
      getDispositions();
    }
  }, [agentId, campaignId]);

  const initiateRecoveryCall = (
    destinationNumber,
    name,
    campaignId,
    callId
  ) => {
    if (!isConnected) {
      toast.error('Please create connection to make calls');
      return;
    }
    if (callId) {
      toast.error('There is an Active call');
      return;
    }
    if (campaignUniqueId) {
      toast.error('There is an Active Campaign');
      return;
    }
    if (localStorage.getItem('doosra-biz-user-number') === destinationNumber) {
      toast.error('Cannot Call To The Same Number');
      return;
    }
    let data = {
      number: destinationNumber,
      name,
      campaignId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      isRecoveryCall: true,
      isIndividualCall: true,
      isV2Call: true,
    };
    props.setSingleCallStatus({ singleCallStatus: true });
    POST(cloudCallCenterUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        setCallOptions(false);
        console.log('setting the single call statsus to true');
        return true;
      })
      .catch((err) => {
        setCallOptions(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const initiateWebRTCBatchRecoveryCall = (filter = {}) => {
    setLoading(true);
    if (singleCallStatus || callId) {
      toast.error('There is an active call');
      return;
    }
    if (campaignUniqueId && campaignUniqueId !== '') {
      toast.error('There is an active campaign call');
      return;
    }
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    data.userId = localStorage.getItem('doosra-biz-user-id');
    data.isV2Call = true;
    if (filter.isFilterSelected && filter.filteredLeads) {
      data.isFilterSelected = true;
      data.selectedDispositions = filter.filteredLeads;
    }
    return POST(cloudCallCenterUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        props.setBatchCallId({ batchCallId: res.data.response });
        props.setCampaignId({ campaignId: campaignId });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        setLoading(false);
        return false;
      });
  };

  const initiateWebRTCPredictiveRecoveryCall = (filter = {}) => {
    setLoading(true);
    if (!resumeAutoCalling) {
      toast.error('Please wait for 10 seconds before resuming the calls');
      return;
    }
    if (singleCallStatus || callId) {
      toast.error('There is an active call');
      return;
    }
    if (campaignUniqueId) {
      toast.error('There is an active campaign call');
      return;
    }
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = campaignId;
    data.isV2Call = true;
    data.userId = localStorage.getItem('doosra-biz-user-id');
    return POST(cloudCallCenterUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        props.setBatchCallId({ batchCallId: res.data.response });
        props.setCampaignId({ campaignId: campaignId });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        setLoading(false);
        return false;
      });
  };

  function CampaignReportTable(props) {
    const getStatus = (status) => {
      let res = '';
      if (status) {
        if (status === 'not_answered') {
          res = 'Not Answered';
        }
        if (status === 'answered') {
          res = 'Answered';
        }
      }
      return res;
    };
    const onClickCall = (agent) => {
      if (props.isValidCampaign && props.isActive) {
        if (agent?.lead?.secondaryNumber) {
          setCallOptions(true);
          setCallOptionNumber(agent?.lead?.number);
          setCallOptionSecondaryNumber(agent?.lead?.secondaryNumber);
          setCallOptionDetails([
            agent?.leadName,
            props.campaignId,
            props.callId,
          ]);
        } else {
          initiateRecoveryCall(
            agent?.destinationNumber,
            agent?.leadName,
            props.campaignId,
            props.callId
          );
        }
      }
    };
    return (
      <div>
        <DataTable.Table style={{ width: '100%' }}>
          <DataTable.Head>
            <DataTable.CellHead width="20">#</DataTable.CellHead>
            {props.headers &&
              props.headers.map((header) => (
                <DataTable.CellHead width="60">
                  {header.toUpperCase()}
                </DataTable.CellHead>
              ))}
            {/* <DataTable.CellHead width="60">LEAD NAME</DataTable.CellHead>
            <DataTable.CellHead width="40">LEAD NUMBER</DataTable.CellHead>
            <DataTable.CellHead width="60px">LEAD SECONDARY NUMBER</DataTable.CellHead> */}
            <DataTable.CellHead width="50">LAST CALL STATUS</DataTable.CellHead>
            <DataTable.CellHead width="50">
              LAST CALL DURATION (s)
            </DataTable.CellHead>
            <DataTable.CellHead width="60">CALL DATE</DataTable.CellHead>
            <DataTable.CellHead width="60">CALL TIME</DataTable.CellHead>
            <DataTable.CellHead width="60">CALL DISPOSITION</DataTable.CellHead>
            <DataTable.CellHead width="60">
              DISPOSITION COMMENT
            </DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              HISTORY
            </DataTable.CellHead>
            <DataTable.CellHead width="50" style={{ textAlign: 'end' }}>
              ACTIONS
            </DataTable.CellHead>
          </DataTable.Head>
          <DataTable.Body>
            {props?.data?.length > 0 &&
              props?.data?.map((agent, index) => (
                <DataTable.Row id={agent.userId}>
                  <DataTable.Cell>
                    {(props.page - 1) * props.pageSize + 1 + index}
                  </DataTable.Cell>
                  {props.headers &&
                    props.headers.map((header) => (
                      <DataTable.Cell width="60">
                        {agent.lead[header]}
                      </DataTable.Cell>
                    ))}
                  <DataTable.Cell>
                    {getStatus(agent?.status) || '-'}
                  </DataTable.Cell>
                  <DataTable.Cell>{agent?.duration}</DataTable.Cell>
                  <DataTable.Cell>
                    {new Date(agent?.createdAt).toLocaleDateString()}
                  </DataTable.Cell>
                  <DataTable.Cell>
                    {new Date(agent?.createdAt).toLocaleTimeString()}
                  </DataTable.Cell>
                  <DataTable.Cell>{agent?.lead?.disposition}</DataTable.Cell>
                  <DataTable.Cell>{agent?.callComment}</DataTable.Cell>
                  <DataTable.Cell>
                    <CallHistoryPopup
                      campaignId={campaignId}
                      destinationNumber={agent?.lead?.number}
                      secondaryNumber={agent?.lead?.secondaryNumber}
                    ></CallHistoryPopup>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <div style={{ display: 'flex', placeContent: 'flex-end' }}>
                      <div>
                        <AddCallRemarksPopup
                          agentHandleDisposition={
                            agent?.status?.toLowerCase() === 'not_initiated'
                          }
                          agentId={agentId}
                          isButtonDisabled={
                            agent.lead?.disposition &&
                            agent.lead?.disposition !== '' &&
                            agent.lead?.disposition !== 'RNR'
                              ? true
                              : false
                          }
                          handleFetchCampaignDetails={
                            handleFetchCampaignDetails
                          }
                          dispositions={dispositions}
                          number={agent?.destinationNumber}
                          campaignId={campaignId}
                        />
                      </div>
                      <div className="tooltip-container">
                        <div className="tooltip-button">
                          <button
                            className="ActivateButton"
                            onClick={() => onClickCall(agent)}
                            style={{
                              width: '32px',
                              height: '32px',
                              backgroundColor: '#07aa3f',
                              opacity:
                                props.isValidCampaign && props.isActive
                                  ? 1
                                  : 0.5,
                              cursor:
                                props.isValidCampaign && props.isActive
                                  ? 'pointer'
                                  : 'default',
                            }}
                          >
                            <img
                              alt=""
                              src={phoneWhite}
                              className="SidebarItemIcon"
                              style={{ width: '16px', height: '16px' }}
                            />
                          </button>
                        </div>
                        <div
                          className="tooltip-text left"
                          data-tip-position="left"
                        >
                          {props.isActive
                            ? props.isValidCampaign
                              ? 'Start Call'
                              : 'campaign is not valid'
                            : 'You are in Inactive Status'}
                          <span className="arrow-right"></span>
                        </div>
                      </div>
                    </div>
                  </DataTable.Cell>
                </DataTable.Row>
              ))}
          </DataTable.Body>
        </DataTable.Table>
      </div>
    );
  }

  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallCenterUrl + 'disposition', data)
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  const fetchWithFilter = (filter) => {
    setDataSearchKey(filter.searchKey);
    setDataSearchValue(filter.searchCondition);
    handleFetchCampaignDetails(
      agentId,
      campaignId,
      filter.searchKey,
      filter.searchCondition
    );
  };
  const updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      setFilteredHeaders(headers => [...headers, header])
      props.setDynamicCampaignTableHeaders({dynamicCampaignTableHeaders: [...filteredHeaders, header]})
    } else {
      const unFilteredHeaders = filteredHeaders.filter(
        (headerType) => headerType !== header
      );
      setFilteredHeaders(unFilteredHeaders);
      props.setDynamicCampaignTableHeaders({dynamicCampaignTableHeaders: unFilteredHeaders})
    }
  };
  const reset = (agentId, campaignId) => {
    setDataSearchKey('');
    setDataSearchValue('');
    resetHandleFetchCampaignDetails(agentId, campaignId);
  };
  const emitEvent = (userId, eventName, message) => {
    var body = {
      user_id: userId,
      event_name: eventName,
      data: message,
    };
    return PUT(cloudCallCenterUrl + 'campaign/emit-event', {}, body)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const stopBatchCall = () => {
    const userId = localStorage.getItem('doosra-biz-user-id');
    emitEvent(userId, 'stopBatchCall', { isBatchCallStopped: true });

    resetValues();

    if (!campaignUniqueIdRef?.current) {
      props.setSingleCallStatus({ singleCallStatus: false });
      props.setCallId({ callId: '' });
      return;
    }
    const batchId = batchCallIdRef.current;
    props.setBatchCallId({ batchCallId: '' });
    props.setCampaignId({ campaignId: '' });
    props.setSingleCallStatus({ singleCallStatus: false });
    props.setCallId({ callId: '' });
    var data = JSON.stringify({
      batchCallId: batchId,
      campaignId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      userId: localStorage.getItem('doosra-biz-user-id'),
    });
    return POST(cloudCallCenterUrl + 'cloud-call/stop-batch-call', data)
      .then((res) => {
        // setResumeAutoCalling(false);
        // resumeAutoCallingTimeHandler();
        // const id = res.data.leadList._id;
        setResumeAutoCalling(false);
        resumeAutoCallingTimeHandler();
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                alt=""
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  emitEvent(
                    localStorage.getItem('doosra-biz-user-id'),
                    'isInCampaign',
                    false
                  );
                  history.push('/agent-campaigns');
                }}
              />
              <div
                style={{
                  width: '200px',
                  height: '50px',
                  // overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                  padding: '10px',
                  color: '#00515a',
                  margin: 0,
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName
                  ? `${campaignData?.campaignName}`
                  : ''}
              </div>
            </div>
          </TabSearchBar>
          {/* {(campaignData?.dailerType !== 'predictive' && campaignData?.dailerType !== 'simple') ?
            <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px", marginTop: "5px" }}>
              {
                (isConnected && (campaignUniqueId && campaignUniqueId !== "")) || batchCallId ?
                  <div>
                    <button
                      className="Otherbutton"
                      style={{
                        color: 'white',
                        width: "200px",
                        backgroundColor:
                          campaignData.isActive === false ||
                            campaignData.isValidCampaign === false
                            ? 'lightgrey'
                            : '#ed3833',
                      }}
                      onClick={() => stopBatchCall()}
                      disabled={
                        campaignData.isActive === false ||
                        campaignData.isValidCampaign === false
                      }
                    >
                      Stop Calling Leads
                    </button>
                  </div>
                  :
                  <div>
                    <button
                      className="Otherbutton"
                      style={{
                        color: 'white',
                        width: "200px",
                        backgroundColor:
                          !isConnected ? 'lightgrey' : (campaignData.isActive === false ||
                            campaignData.isValidCampaign === false)
                            ? 'lightgrey'
                            : '#07aa3f',
                      }}
                      onClick={() => {
                        if (campaignData.dailerType === "simple") {
                          initiateWebRTCBatchRecoveryCall()
                        } else {
                          initiateWebRTCPredictiveRecoveryCall()
                        }
                      }}
                      disabled={
                        !isConnected ? true : (campaignData.isActive === false ||
                          campaignData.isValidCampaign === false)
                      }
                      title={!isConnected ? "Connect to server and start calling" : ""}
                    >
                      Start Calling Leads
                    </button>
                  </div>
              }
            </div> : null
          } */}
          <div
            style={{
              display: 'flex',
              gap: '32px',
              padding: '8px 16px 2px 16px',
            }}
          >
            <DataCard title="Calls" count={campaignData?.totalCalls} />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
            {/* <DataCard
              title="Calls Unanswered"
              count={campaignData?.unAnsweredCalls}
            />
            <DataCard
              title="Call Answer Rate (%)"
              count={campaignData?.answerPercentage}
            /> */}
          </div>
          <div>
            <SearchBar
              type="campaign-agent"
              dailerType="dynamic"
              search={(e) => fetchWithFilter(e)}
              reset={(e) => reset(agentId, campaignId)}
              headers={headers}
              updateFilteredHeader={updateFilteredHeader}
              filteredHeaders={filteredHeaders}
              hideTableHeaders={hideTableHeaders}
            ></SearchBar>
          </div>
          {loading && <PreLoader />}
          {!loading && (
            <div>
              <CampaignReportTable
                isActive={campaignData.isActive}
                isValidCampaign={campaignData.isValidCampaign}
                data={campaignData.leads}
                headers={filteredHeaders}
                campaignId={campaignId}
                page={currentPage}
                pageSize={pageSize}
                callId={callId}
              />
              <Paginator
                currentPage={currentPage}
                lastPage={totalPages}
                getInfo={(value) => {
                  pageNoRef.current = value;
                  handleFetchCampaignDetails(
                    agentId,
                    campaignId,
                    null,
                    null,
                    value
                  );
                }}
              />
              {callOptions && (
                <Popup
                  open={callOptions}
                  closeOnDocumentClick={false}
                  closeOnEscape={false}
                >
                  <div className="PopupContainer">
                    <div className="PopupHeaderContainer">
                      <p>SELECT NUMBER TO CALL</p>
                      {/* <p style={{ padding: 10, fontSize: 16, fontWeight: 600, textAlign: "center" }} className="PopupInputLabel">Call Balance: {this.state.currentCallBalance}</p> */}
                      <img
                        alt=""
                        className="PopupClose"
                        src={Close}
                        onClick={() => setCallOptions(false)}
                      />
                    </div>
                    <div className="PopupBody">
                      <br />
                      <button
                        className="ButtonFullWidth"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          initiateRecoveryCall(
                            callOptionNumber,
                            ...callOptionDetails
                          )
                        }
                      >
                        PRIMARY NUMBER
                      </button>
                      <button
                        className="ButtonCancelFullWidth"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          initiateRecoveryCall(
                            callOptionSecondaryNumber,
                            ...callOptionDetails
                          )
                        }
                      >
                        SECONDARY NUMBER
                      </button>
                    </div>
                    <br />
                  </div>
                </Popup>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isReocveryXEnabled: state.topbar.isReocveryXEnabled,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  userDetails: state.recovery.userDetails,
  callStatus: state.recovery.callStatus,
  position: state.recovery.position,
  batchCallId: state.recovery.batchCallId,
  // destinationNumber: state.recovery.destinationNumber,
  // destinationName: state.recovery.destinationName,
  // RTCSession: state.recovery.RTCSession,
  callId: state.recovery.callId,
  callFilterLeads: state.recovery.callFilterLeads,
  singleCallStatus: state.recovery.singleCallStatus,
  // RTCcallStatus: state.recovery.RTCcallStatus,
  isRecoveryXEmailSent: state.topbar.isRecoveryXEmailSent,
  dynamicCampaignTableHeaders: state.recovery.dynamicCampaignTableHeaders,
});
export default commonStyle(connect(mapStateToProps, {
  setCallDetails,
  setBatchCallId,
  setCallId,
  setCallStatus,
  setSingleCallStatus,
  setDestinationNumber,
  setDestinationName,
  setRTCSession,
  setCallFilterLeads,
  setRTCCallStatus,
  setCampaignId,
  setDomainsOfCampaign,
  setCallType,
  setDynamicCampaignTableHeaders
})(DynamicCampaign));
