import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { generateRecaptcha } from '../../../services/Recaptcha';
import { POST, GET } from '../../../services/HttpRequests';
import TwoFAAuthentication from '../component/TwoFAAuthentication';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import InfoRed from '../../../assets/images/infoRed.png';
import Wall from '../../../assets/images/loginPageWall.png';
import '../../../assets/css/Login.css';
import '../../../assets/css/LoginNew.css';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
const MICROSOFT_REDIRECT_URI = process.env.REACT_APP_MICROSOFT_REDIRECT_URI;
const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URI}&response_type=code&scope=profile email`;
const microsoftLoginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${MICROSOFT_REDIRECT_URI}&scope=openid%20profile%20email&response_mode=query`;

const userUrl = process.env.REACT_APP_USER_API;
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
class LoginPage extends Component {
  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const username = query.get('username');
    this.setState({ username: username ?? '' });
  };

  state = {
    checkboxChecked: false,
    step: 1,
    username: '',
    password: '',
    error: '',
    isLoading: false,
    loginFormToDisplay: "VERIFY_USERNAME",
    // loginFormToDisplay: "USERNAME_LOGIN",
    // loginFormToDisplay: "SHIP_ROCKET_LOGIN",
  };

  handleForgotPasswordClick = () => {
    this.props.history.push('/signup');
  };

  checkboxToggleHandler = () => {
    this.setState({ checkboxChecked: !this.state.checkboxChecked });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  submitCredentialsHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const recaptcha = await generateRecaptcha('BUSINESS_LOGIN');
    let reqBody = {
      username: this.state.username,
      password: this.state.password,
      recaptchaToken: recaptcha,
    };

    this.setState({ isLoading: true });

    POST(`${userUrl}v1/auth/login`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        const {
          is_2FA_enabled,
          two_FA_secret = '',
          organisation_id,
          user_id,
        } = res.user;
        this.setState({
          step: 2,
          is_2FA_enabled,
          secret: two_FA_secret,
          organisation_id,
          user_id,
          ...res,
          isLoading: false,
        });
        return res;
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({
            error: err.response.data.error.reason,
            isLoading: false,
          });
        }
      });
  };

  verifyUsername = async (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({ isLoading: true });
    let reqBody = {
      username: this.state.username,
    }
    POST(`${userUrl}v1/auth/verify-account`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.channel_partner === "SHIP_ROCKET") {
          this.setState({ loginFormToDisplay: "SHIP_ROCKET_LOGIN" });
        } else {
          this.setState({ loginFormToDisplay: "USERNAME_LOGIN" });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.setState({ loginFormToDisplay: "VERIFY_USERNAME" });
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({
            error: err.response.data.error.reason,
            isLoading: false,
          });
        }
      });
  }

  getFreeTrialInfo = async (orgId) => {
    console.log(orgId);
    await GET(`${orgUrl}v1/organisation/is-free-trial-details`, {
      organisationId: orgId,
    }).then((res) => {
      localStorage.setItem(
        'is_free_trial_enabled',
        res.data.response?.free_trial
      );
      localStorage.setItem(
        'is_kyc_submitted',
        res.data.response?.is_kyc_submitted
      );
      localStorage.setItem(
        'is_kyc_completed',
        !!res.data.response?.is_kyc_completed
      );
      console.log(res.data.response);
      return res.data.response;
    });
  };

  cancelHandler = (e) => {
    e.preventDefault();
  };

  navigateToSignup = () => {
    console.log('signup');
    this.props.history.push('/signup');
  };

  render () {
    const {
      step,
      username,
      password,
      error,
      checkboxChecked,
      is_2FA_enabled,
      secret,
      organisation_id,
      user_id,
      isLoading,
    } = this.state;

    const shipRocketLoginForm = () => {
      return (<div style={{ margin: '16px 0' }}>
        <div className="AuthHeadContainer">
          <p>Your account is set up with ShipRocket. Use the button below to login</p>
        </div>

        <a
          href={process.env.REACT_APP_SHIP_ROCKET_APP_URI}
          className="AuthButton AuthButtonInvert GetInTouch"
          style={{ padding: '14px' }}
        >
          Login with ShipRocket
        </a>

        <a>
          <span className="AuthForgotPassword" onClick={() => {
            this.setState({ loginFormToDisplay: "VERIFY_USERNAME" });
          }}>Login with other details</span>
        </a>
        {/* {loginPageContent()} */}
      </div>);
    }

    const verifyUsernameForm = () => {
      return (<div><form onSubmit={this.verifyUsername}>
        <input
          id="username"
          className="InputBox"
          placeholder="Username"
          onChange={this.handleChange}
          value={username}
        />
        <div className="ErrorContainer ErrorContainerMargin">
          {error && (
            <React.Fragment>
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {error}
            </React.Fragment>
          )}
        </div>

        <button
          className={`AuthButton ${isLoading ? 'ButtonLoading' : ''}`}
          type="submit"
          disabled={isLoading}
        >
          <span className="ButtonText">{isLoading ? '' : 'Verify Username'}</span>
          {isLoading && <span className="ButtonSpinner"></span>}
        </button>
      </form>
        {loginPageContent()}
      </div>);
    }

    const loginForm = () => {
      return (
        <div>

          <form onSubmit={this.submitCredentialsHandler}>
            <input
              id="username"
              className="InputBox"
              placeholder="Username"
              onChange={this.handleChange}
              value={username}
            />
            <input
              id="password"
              type="password"
              className="InputBox"
              placeholder="Password"
              onChange={this.handleChange}
              value={password}
            />

            <div className="ErrorContainer ErrorContainerMargin">
              {error && (
                <React.Fragment>
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {error}
                </React.Fragment>
              )}
            </div>

            <button
              className={`AuthButton ${isLoading ? 'ButtonLoading' : ''}`}
              type="submit"
              disabled={isLoading}
            >
              <span className="ButtonText">{isLoading ? '' : 'Next'}</span>
              {isLoading && <span className="ButtonSpinner"></span>}
            </button>
          </form>
          {loginPageContent()}
        </div>
      );
    }

    const loginPageContent = () => {

      return (
        <div>
          {/* { loginForm() } */}

          <div className="divider">Or</div>
          <div style={{ margin: '16px 0' }}>
            <a
              href={googleLoginUrl}
              className="AuthButton AuthButtonInvert GetInTouch"
              style={{ padding: '14px' }}
            >
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj4KICAgIDxwYXRoIGQ9Ik0zLjU0NTk0IDkuNjY4OTlMMi45ODkgMTEuNzQ4MUwwLjk1MzQwNiAxMS43OTEyQzAuMzQ1MDYzIDEwLjY2MjggMCA5LjM3MTg2IDAgNy45OTk5OUMwIDYuNjczMzkgMC4zMjI2MjUgNS40MjIzOSAwLjg5NDUgNC4zMjA4NkgwLjg5NDkzOEwyLjcwNzE5IDQuNjUzMTFMMy41MDEwNiA2LjQ1NDQ5QzMuMzM0OTEgNi45Mzg4OSAzLjI0NDM0IDcuNDU4ODkgMy4yNDQzNCA3Ljk5OTk5QzMuMjQ0NDEgOC41ODcyNCAzLjM1MDc4IDkuMTQ5ODkgMy41NDU5NCA5LjY2ODk5WiIgZmlsbD0iI0ZCQkIwMCIvPgogICAgPHBhdGggZD0iTTE1Ljg2MDQgNi41MDU2MkMxNS45NTIyIDYuOTg5NTUgMTYuMDAwMSA3LjQ4OTMzIDE2LjAwMDEgOC4wMDAxMkMxNi4wMDAxIDguNTcyODcgMTUuOTM5OSA5LjEzMTU1IDE1LjgyNTIgOS42NzA0NkMxNS40MzU4IDExLjUwNDMgMTQuNDE4MiAxMy4xMDU2IDEzLjAwODUgMTQuMjM4OEwxMy4wMDgxIDE0LjIzODRMMTAuNzI1NSAxNC4xMjE5TDEwLjQwMjQgMTIuMTA1MkMxMS4zMzc4IDExLjU1NjYgMTIuMDY4OCAxMC42OTgxIDEyLjQ1MzggOS42NzA0Nkg4LjE3NjAzVjYuNTA1NjJIMTIuNTE2MkgxNS44NjA0WiIgZmlsbD0iIzUxOEVGOCIvPgogICAgPHBhdGggZD0iTTEzLjAwNzkgMTQuMjM4MkwxMy4wMDgzIDE0LjIzODZDMTEuNjM3MyAxNS4zNDA2IDkuODk1NzcgMTUuOTk5OSA3Ljk5OTk2IDE1Ljk5OTlDNC45NTMzNyAxNS45OTk5IDIuMzA0NTkgMTQuMjk3MSAwLjk1MzM2OSAxMS43OTExTDMuNTQ1OSA5LjY2ODk1QzQuMjIxNDkgMTEuNDcyIDUuOTYwODQgMTIuNzU1NSA3Ljk5OTk2IDEyLjc1NTVDOC44NzY0MyAxMi43NTU1IDkuNjk3NTYgMTIuNTE4NiAxMC40MDIyIDEyLjEwNUwxMy4wMDc5IDE0LjIzODJaIiBmaWxsPSIjMjhCNDQ2Ii8+CiAgICA8cGF0aCBkPSJNMTMuMTA2NCAxLjg0MTc1TDEwLjUxNDggMy45NjM1QzkuNzg1NTMgMy41MDc2OSA4LjkyMzUzIDMuMjQ0MzcgOC4wMDAwMyAzLjI0NDM3QzUuOTE0NzUgMy4yNDQzNyA0LjE0Mjg4IDQuNTg2NzggMy41MDExMyA2LjQ1NDVMMC44OTQ5NjkgNC4zMjA4OEgwLjg5NDUzMUMyLjIyNTk3IDEuNzUzODQgNC45MDgxNiAwIDguMDAwMDMgMEM5Ljk0MTEyIDAgMTEuNzIwOSAwLjY5MTQzNyAxMy4xMDY0IDEuODQxNzVaIiBmaWxsPSIjRjE0MzM2Ii8+CiAgPC9nPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwMCI+CiAgICAgIDxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz4KICAgIDwvY2xpcFBhdGg+CiAgPC9kZWZzPgo8L3N2Zz4K" style={{ paddingRight: '8px' }} />
              Login with Google
            </a>
          </div>
          {displayMicrosoftLogin ? <div style={{ margin: '16px 0' }}>
            <a
              href={microsoftLoginUrl}
              className="AuthButton AuthButtonInvert GetInTouch"
              style={{ padding: '14px' }}
            >
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODk5OTk0IDAuOTAwMDI0SDguNjcwMThWOC42NzAyMUgwLjg5OTk5NFYwLjkwMDAyNFoiIGZpbGw9IiNGMzUzMjUiLz4KPHBhdGggZD0iTTkuNDQ3MTcgMC45MDAwMjRIMTcuMjE3NFY4LjY3MDIxSDkuNDQ3MTdWMC45MDAwMjRaIiBmaWxsPSIjODFCQzA2Ii8+CjxwYXRoIGQ9Ik0wLjg5OTk5NCA5LjQ0NzI3SDguNjcwMThWMTcuMjE3NUgwLjg5OTk5NFY5LjQ0NzI3WiIgZmlsbD0iIzA1QTZGMCIvPgo8cGF0aCBkPSJNOS40NDcxNyA5LjQ0NzI3SDE3LjIxNzRWMTcuMjE3NUg5LjQ0NzE3VjkuNDQ3MjdaIiBmaWxsPSIjRkZCQTA4Ii8+Cjwvc3ZnPgo=" style={{ paddingRight: '8px' }} />
              Login with Microsoft
            </a>
          </div> : null}
          <p style={{ fontWeight: 500 }}>
            Forgot password?{' '}

            <Link to="forgot-password">
              <span className="AuthForgotPassword">Reset password</span>
            </Link>
          </p>
          <p style={{ fontWeight: 500 }}>
            New to Alohaa?{' '}
            <span
              style={{
                color: '#ed3833',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={this.handleForgotPasswordClick}
            >
              {' '}
              Sign up
            </span>
          </p>
          <p style={{ fontWeight: 500 }}>
            Read our{' '}
            <a
              style={{
                color: '#ed3833',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              target="_blank"
              href="https://www.alohaa.ai/privacy-policy" rel="noreferrer"
            >
              {' '}
              Privacy Policy
            </a>
          </p></div>);
    }

    // @hotfix: Display the "Login with Microsoft" button only if the
    // the query param "microsoft" is passed.
    // It's required for the Microsoft to verify the functionality in production before making it live
    const urlParams = new URLSearchParams(window.location.search);
    const displayMicrosoftLogin = urlParams.get('microsoft') === "1";

    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== ''
    ) {
      let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
      isFreeTrail = isFreeTrail
        ? isFreeTrail === 'true'
          ? true
          : false
        : false;
      let userRole = localStorage.getItem('doosra-biz-user-role');
      if (isFreeTrail && userRole === 'ADMIN') {
        return <Redirect to="trial-period" />;
      }
      return <Redirect to="call-logs" />;
    }

    if (step === 2) {
      return (
        <TwoFAAuthentication
          is_2FA_enabled={is_2FA_enabled}
          secret={secret}
          error={error}
          password={password}
          organisation_id={organisation_id}
          user_id={user_id}
          resendOtp={this.submitCredentialsHandler}
          {...this.state}
        />
      );
    }

    return (
      <div className="login-main">
        <div className="section-1">
          <div className="login-container">
            <img className="login-container-logo" alt="" src={Logo} />
            <div className="AuthHeadContainer">
              <p className="AuthSubHeader" style={{ marginTop: '36px' }}>
                Log into your account{' '}
              </p>
            </div>

            {this.state.loginFormToDisplay === "VERIFY_USERNAME" && verifyUsernameForm()}

            {this.state.loginFormToDisplay === "USERNAME_LOGIN" && loginForm()}

            {this.state.loginFormToDisplay === "SHIP_ROCKET_LOGIN" && shipRocketLoginForm()}

          </div>
        </div>

        <img style={{ height: '100vh' }} src={Wall} alt="Wall" />
      </div>
    );
  }
}

export default LoginPage;
