import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import failIcon from '../../../assets/json/fail.json';
import loaderIcon from '../../../assets/json/loader.json';
import Lottie from 'react-lottie';
import { GET } from '../../../services/HttpRequests';
import { getTopBarData } from '../../../actions/topBarActions';
import { connect } from 'react-redux';
import { useAuth } from '../../../providers/AuthProvider';

const userUrl = process.env.REACT_APP_USER_API;
const failOption = {
  loop: true,
  autoplay: true,
  animationData: failIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const loadingOption = {
  loop: true,
  autoplay: true,
  animationData: loaderIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const SsoAuthCallback = (props) => {
  const { saveSession } = useAuth();

  const [loginStatus, setLoginStatus] = useState('processing');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  const history = useHistory();
  const currentRoute = window.location.pathname.trim().replace(/\/$/, '');
  const isShipRocketRoute = currentRoute === "/auth/ship-rocket/callback";

  function verifySsoToken() {
    const callbackRoutesMap = {
      "/auth/google/callback": `v1/auth/google/login${window.location.search}`,
      "/auth/microsoft/callback": `v1/auth/microsoft/login${window.location.search}`,
      "/auth/ship-rocket/callback": `v1/auth/ship-rocket/login${window.location.search}`,
    }

    let userSsoLoginRoute = callbackRoutesMap[currentRoute];
    GET(userUrl + userSsoLoginRoute)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        saveSession(res);
        history.push('/');
        window.location.reload();
      }).catch(err => {
        if (isShipRocketRoute) {
          const errorCode = err.response?.data?.error?.code;
          const shipRocketErrorCodes = [
            "SHIP_ROCKET_VERIFY_TOKEN_ERROR",
            "SHIP_ROCKET_ACCESS_TOKEN_ERROR"
          ];
          if (!shipRocketErrorCodes.includes(errorCode)) {
            // setLoginStatus("register");
            history.push(`/signup/ship-rocket${window.location.search}`);
            return;
          }
        }
        const errorMessage = err.response?.data?.error?.reason ?? "Something went wrong!";
        setLoginErrorMessage(errorMessage)
        setLoginStatus("failed");
      });
  }
  useEffect(() => {
    verifySsoToken();
  }, []);

  return (
    <div style={{ width: '100%', minHeight: '100vh' }}>
      <div>
        <div
          style={{
            minHeight: '175px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img className="login-container-logo" alt="" src={Logo} />
        </div>

         { loginStatus === 'processing' && (
          <div
            style={{
              minHeight: '600px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Lottie options={loadingOption} height={400} width={400} />
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '34px',
                fontWeight: 'bold',
                letterSpacing: '-1.02px',
                color: '#454545',
              }}
            >
              Verifying your account details
            </p>
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '500',
                letterSpacing: '-0.72px',
                color: '#454545',
              }}
            >
              This may take a moment
            </p>
          </div>
        )}

         {loginStatus === 'failed' && (
          <div
            style={{
              minHeight: '600px',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Lottie options={failOption} height={400} width={400} />
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '34px',
                fontWeight: 'bold',
                letterSpacing: '-1.02px',
                textAlign: 'center',
                color: '#ed3833',
              }}
            >
              <p>{loginErrorMessage}</p>
              <button
              style={{
                width: '240px',
                height: '54px',
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '500',
                letterSpacing: '-0.72px',
                backgroundColor: '#07aa3f',
                color: '#fff',
                borderRadius: '8px',
                border: 'none',
              }}
              onClick={() => {
                if (isShipRocketRoute) {
                  window.location.href = process.env.REACT_APP_SHIP_ROCKET_APP_URI;
                } else {
                  history.push('/');
                }
              }}
            >
              { isShipRocketRoute ? "Retry" : "Go back to Login" }
            </button>
            </p>
            <p>
              <br /> Please contact <a href="mailto:support@alohaa.ai">support@alohaa.ai</a> for further help.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, { getTopBarData })(SsoAuthCallback);
