import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import UserAdd from '../../../assets/images/userAddWhite.png';
import { GET } from '../../../services/HttpRequests';
import { debounce } from '../../../utility/utility';
import { connect } from 'react-redux';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_ASSIGN_NUMBER_BUTTON, ADMIN_ASSIGN_NUMBER } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
import './Tooltip.css';

const userUrl = process.env.REACT_APP_USER_API;
const autoAttendantUrl = process.env.REACT_APP_AUTO_ATTENDED_API;
let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
class AssignUserPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      users: [],
      searchValue: '',
      groups: props.groups,
      autoAttendantAgents: [],
      showGroups: props.groups && props.groups.length > 0 ? true : false,
      showAgents: false,
      showAutoAttendants: false,
    };
  }

  deBouncedHandle = debounce(async () => {
    let result = [];
    let filteredAutoAttendant = [];
    if (this.state.searchValue) {
      result = this.state.groups.filter((group) =>
        group.name.toLowerCase().includes(this.state.searchValue.toLowerCase())
      );
      filteredAutoAttendant = this.state.autoAttendantAgents.filter((group) =>
        group.name.toLowerCase().includes(this.state.searchValue.toLowerCase())
      );
    } else {
      result = this.props.groups;
      filteredAutoAttendant = this.state.autoAttendantAgents;
    }
    try {
      let orgId = localStorage.getItem('doosra-biz-organisation-id');
      const res = await GET(userUrl + `v1/user/search`, {
        search: this.state.searchValue,
        organisationId: orgId,
      });
      const users = res.data.response.users;
      this.setState({
        users: users,
        groups: result,
        autoAttendantAgents: filteredAutoAttendant,
        showGroups: this?.state?.searchValue ? true : false,
        showAgents: this?.state?.searchValue ? true : false,
        showAutoAttendants: this?.state?.searchValue ? true : false,
      });
    } catch (err) {
      console.log(err);
    }
  }, 500);

  openPopup = async () => {
    logPostHogEvent(ADMIN_ASSIGN_NUMBER_BUTTON);
    const { users } = this.state;
    this.setState({
      users: users,
    });
    this.setState({
      groups: this.props.groups,
    });
    try {
      const orgId = localStorage.getItem('doosra-biz-organisation-id');
      const res = await GET(userUrl + `v1/user/search`, {
        organisationId: orgId,
      });
      const usersData = res.data.response.users;
      const autoAttendantCall = await GET(
        autoAttendantUrl + `v1/auto-attendant`,
        {
          organisationId: orgId,
        }
      );
      const autoAttendantAgents =
        autoAttendantCall.data.response.autoAttendants;
      this.setState({ popupOpen: true });
      this.setState({
        users: usersData,
        autoAttendantAgents: autoAttendantAgents,
      });
    } catch (err) {
      console.log(err);
    }
  };

  closePopup = () => {
    this.setState({ popupOpen: false, users: [], searchValue: '' });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.deBouncedHandle();
  };

  assignUserAndClosePopup = (id, type) => {
    logPostHogEvent(ADMIN_ASSIGN_NUMBER);
    if (type === 'user') {
      console.log('');
      this.props.assignUser(id);
    } else if (type === 'group') {
      this.props.assignGroup(id);
    } else if (type === 'autoAttendant') {
      this.props.assignAutoAttendant(id);
    }
    this.closePopup();
  };

  toggleGroupList = () => {
    this.setState({ showGroups: !this.state.showGroups });
  };

  toggleAgentList = () => {
    this.setState({ showAgents: !this.state.showAgents });
  };

  toggleAutoAttendantList = () => {
    this.setState({ showAutoAttendants: !this.state.showAutoAttendants });
  };

  render() {
    let usersList = this.state.users.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#42a1e2',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          <button
            className="ButtonMidSize BgGreen"
            onClick={() => this.assignUserAndClosePopup(item.user_id, 'user')}
          >
            ASSIGN
          </button>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));

    let groupList = this.state.groups.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#42a1e2',
              margin: 'auto 0',
            }}
          >
            {item.name}
          </p>
          <button
            className="ButtonMidSize BgGreen"
            onClick={() => this.assignUserAndClosePopup(item._id, 'group')}
          >
            ASSIGN
          </button>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));

    let autoAttendantList = this.state.autoAttendantAgents?.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#42a1e2',
              margin: 'auto 0',
            }}
          >
            {item.name}
          </p>
          <button
            className="ButtonMidSize"
            onClick={() =>
              this.assignUserAndClosePopup(item._id, 'autoAttendant')
            }
          >
            ASSIGN
          </button>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));

    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Assign to {this.props.NumToAssign}</p>
            <img className="PopupClose" src={Close} onClick={this.closePopup} />
          </div>
          <div className="PopupBody">
            <div style={{ display: 'flex', textAlign: 'center' }}>
              <input
                placeholder="Search..."
                style={{ width: '100%' }}
                className="PopupSearchInput"
                id="searchValue"
                onChange={this.onChange}
                value={this.state.searchValue}
              />
            </div>
            {this.props.isGroupsEnabled && (
              <>
                <div className="PopupColumnHeaders">
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    AVAILABLE GROUPS
                    <span
                      onClick={this.toggleGroupList}
                      className="PopupListShowHideText"
                    >
                      {this.state.showGroups ? 'Hide' : 'Show'}
                    </span>
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ACTION
                  </p>
                </div>
                <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                  {this.state.showGroups && groupList}
                </div>
              </>
            )}
          </div>
          {this.props.isAgentsEnabled && (
            <div className="PopupBody">
              <div className="PopupColumnHeaders">
                <p
                  style={{
                    margin: '10px',
                    textAlign: 'left',
                    paddingLeft: '20px',
                  }}
                >
                  AVAILABLE AGENTS
                  <span
                    onClick={this.toggleAgentList}
                    className="PopupListShowHideText"
                  >
                    {this.state.showAgents ? 'Hide' : 'Show'}
                  </span>
                </p>
                <p
                  style={{
                    margin: '10px',
                    textAlign: 'right',
                    paddingRight: '10px',
                  }}
                >
                  ACTION
                </p>
              </div>
              <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                {this.state.showAgents && usersList}
              </div>
            </div>
          )}
          {this.props.isAutoAttendantEnabled &&
            this.props.showAutoAttendant && (
              <div className="PopupBody">
                <div className="PopupColumnHeaders">
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'left',
                      paddingLeft: '20px',
                    }}
                  >
                    AUTO ATTENDANT
                    <span
                      onClick={this.toggleAutoAttendantList}
                      className="PopupListShowHideText"
                    >
                      {this.state.showAutoAttendants ? 'Hide' : 'Show'}
                    </span>
                  </p>
                  <p
                    style={{
                      margin: '10px',
                      textAlign: 'right',
                      paddingRight: '10px',
                    }}
                  >
                    ACTION
                  </p>
                </div>
                <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                  {this.state.showAutoAttendants && autoAttendantList}
                </div>
              </div>
            )}
        </div>
      </Popup>
    );
    return (
      <span>
        {!this?.props?.numberDetails?.ivr_id && (
          <div className="tooltip-container">
            <div className="tooltip-button">
              <button
                onClick={this.openPopup}
                className={
                  this.props.nonIncomingDid ||
                    !isSuperAdminWithPermission('EDIT') ? 'DisableButton' : 'AssignButton'
                }
                disabled={this.props.nonIncomingDid || !isSuperAdminWithPermission('EDIT')}
              >
                <img alt={UserAdd} src={UserAdd} style={{ paddingRight: '10px' }} />
                ASSIGN
              </button>
            </div>
            {this.props.nonIncomingDid && <div className="tooltip-text left" data-tip-position="left">
              For outgoing calls only
              < span className="arrow-right"></span>
            </div>}
          </div>
        )}
        {popper}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  isAutoAttendantEnabled: state.topbar.isAutoAttendantEnabled,
});
export default connect(mapStateToProps, null)(AssignUserPopup);
