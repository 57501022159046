import styled from '@emotion/styled';

export const commonStyle = (Component) => styled(Component)`

.ContentContainer {
    /* width: 98%; */
    /* margin: 1%; */
    display: flex;
    flex-direction: column;
    border-top: none;
    /* justify-content: space-between; */
    overflow: hidden;
  }
  
  .Content {
    margin-left: 230px;
    height: 100vh;
    transition: margin 0.3s;
    background: ${(props) => props.theme.colors.tableBgColor};
  }
  
  .ContentFull {
    margin-left: 72px;
    height: 100vh;
    transition: margin 0.3s;
    background: ${(props) => props.theme.colors.tableBgColor};
  }
  
  .SMSTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns:
      110px minmax(100px, 170px) minmax(80px, 100px) minmax(80px, 100px) minmax(200px, auto)
      60px 110px;
    background: ${(props) => props.theme.colors.columnHeaderBg};
    padding-left: 10px;
  }
  
  .SMSDataTableAlignment {
    display: grid;
    grid-template-columns:
      110px minmax(100px, 170px) minmax(80px, 100px) minmax(80px, 100px) minmax(200px, auto)
      60px 110px;
    background: ${(props) => props.theme.colors.tableBgColor};
    padding-left: 10px;
  }
  
  .CallsTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns: 110px 110px 175px 130px 140px 100px auto 60px 110px;
    background: ${(props) => props.theme.colors.columnHeaderBg};
    padding-left: 10px;
  }
  
  .CallsDataTableAlignment {
    display: grid;
    grid-template-columns: 110px 110px 175px 130px 140px 100px auto 60px 110px;
    background: ${(props) => props.theme.colors.tableBgColor};
    padding-left: 10px;
  }
  
  .DoosraNumbersTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns: 150px 100px 200px 150px 100px auto;
    background: ${(props) => props.theme.colors.columnHeaderBg};
    padding-left: 10px;
  }
  
  .DoosraNumbersDataTableAlignment {
    display: grid;
    grid-template-columns: 150px 100px 200px 150px 100px auto;
    background: var(--white-color);
    padding-left: 10px;
    background: ${(props) => props.theme.colors.tableBgColor};
  }
  
  .UsersTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 122px 116px 90px;
    background: ${(props) => props.theme.colors.columnHeaderBg};
    padding-left: 10px;
  }
  
  .UsersTableAlignmentAdj {
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 130px 110px;
  }
  
  .UsersDataTableAlignment {
    display: grid;
    /* grid-template-columns: 48px 248px 250px 350px auto auto 100px; */
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 122px 116px 90px;
    background: ${(props) => props.theme.colors.tableBgColor};
    padding-left: 10px;
  }
  
  .UsersDataTableAlignmentAdj {
    grid-template-columns:
      90px 80px minmax(100px, 170px) 110px 110px 110px 150px minmax(100px, auto)
      auto 130px 110px;
  }
  
  .EnDisToggleHolder {
    width: 108px;
    height: 32px;
    margin: auto 0;
    display: flex;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.endisToggleBgColor};
  }

  .EnDisToggleHolder p {
    margin: auto 8px;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.14;  */
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.endisToggleTextColor};
  }

  .EnDisToggleHolder img {
    width: 24px;
    height: 14px;
    margin: auto 4px;
    cursor: pointer;
  }
  
  .TableHeader {
    font-size: 12px;
    font-weight: 600;
    margin: auto 0;
    color: ${(props) => props.theme.colors.tableHeaderTextColor};
  }
  
  .TableContent {
    font-size: 13px;
    font-weight: 500;
    /* padding: 5px; */
    word-break: break-word;
    margin-bottom: 0;
    /* margin: 5px 0; */
    align-self: center;
    color: ${(props) => props.theme.colors.tableContentColor};
  }
  
  .TableEndElement {
    text-align: end;
    padding-right: 10px;
  }
  
  .HR {
    border-top: 1px solid ${(props) => props.theme.colors.tableLineColor};
    width: 100%;
    margin: auto;
  }
  
  .HR.TableRowDivider {
    margin: 0 10px;
    width: calc(100%);
  }
  
  .ReassignButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--blue-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }
  .BlueButton {
    width: 35px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--dark-blue-color);
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .yellowButton {
    width: 35px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--orange-color);
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .LightBlueButton {
    width: 35px;
    height: 32px;
    border-radius: 3px;
    background-color: #35A7FF;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tableTags {
    width: 100px;
    border: 1px solid grey;
    border-radius: 4px;
    margin: 5px; 
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tableNotes {
    width: 100px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .GreenButton {
    width: 35px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--text-green-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .AddCallertune {
    width: 173px;
    height: 32px;
    border-radius: 3px;
    background-color: #196cca;
    text-align: center;
    color: var(--white-color);
    border: none;
  }
  
  .DisableButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--dark-grey-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .DisabledLink {
    cursor: not-allowed;
    color: gray;
    text-decoration: none;
    color: var(--white-color);
  }

  .UploadCallertune {
    width: 100px;
    height: 32px;
    border-radius: 3px;
    text-align: center;
    color: var(--white-color);
    border: none;
  }

  .removeCallerTune {
    width: 220px;
    height: 32px;
    border-radius: 3px ;
    background-color: #ffffff;
    text-align: center;
    color: #ed3833;
    border: solid 1px #ed3833;
  }

  .UnassignButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--yellowish-orange-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }
  
  .AssignButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: #00515a;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }
  
  .DeactivateButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }
  
  .ActivateButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--text-green-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }
  .DisableActivateButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: lightgrey;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }
  
  .TabelContainer {
    /* min-height: 82%; */
  }
  
  .NotFoundContainer {
    text-align: center;
    text-align: -webkit-center;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NotFound {
    font-size: 10vw;
    color: ${(props) => props.theme.colors.primaryColor};
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  
  .NotFoundText {
    font-size: 3vw;
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  .RedirectLink {
    font-size: 1vw;
    color: #32abbc;
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-decoration: underline;
  }

  .DataCard{
    border: solid 1px #d2d3d4;
    border-radius: 4px;
    // max-width:300px;
    padding:12px;
    flex:1;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .DataCard h3{
    margin:0;
    font-size: 16px;
    font-weight: 500;
  }
  .DataCard p{
    margin:0;
    font-size: 30px;
    font-weight: bold;
    color: #ed3833;
  }

  .ActiveFilterTab{
    // width:102px;
    // height:32px;
    padding:8px 14px;
    font-weight:bold;
    font-size:11px;
    color: #07aa3f;
    border-radius: 2px;
    background-color: #d3edda;
    cursor:pointer;
  }
  
  .InactiveFilterTab{
    // width:102px;
    // height:32px;
    padding:8px 14px;
    font-weight:bold;
    font-size:11px;
    color: #767171;
    border-radius: 2px;
    background-color: #f3f3f3;
    cursor:pointer;
  }

  .DayDefault{
    width:48px;
    height:48px;
    cursor:pointer;
    border-radius: 24px;
    border: solid 1px #939598;
    background-color: #fff;
    font-size:18px;
    font-weight:500;
    color: #939598;
    text-align:center;
    line-height:48px;
  }

  .DaySelected{
    width:48px;
    height:48px;
    cursor:pointer;
    border-radius: 24px;
    border: solid 1px #346ac3;
    background-color: #196cca;
    font-size:18px;
    font-weight:500;
    color: #ffffff;
    text-align:center;
    line-height:48px;
  }
  

  @media only screen and (max-width: 900px) {
    .ContentContainer {
      width: 98%;
      margin: 1%;
      height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }
    .SMSTableAlignment {
      width: fit-content;
      grid-template-columns: 48px 100px 100px 150px 200px 100px 100px;
    }
    .SMSDataTableAlignment {
      width: fit-content;
      grid-template-columns: 48px 100px 100px 150px 200px 100px 100px;
    }
    .DoosraNumbersTableAlignment {
      grid-template-columns: 48px 100px 100px 100px 150px 300px;
    }
    .DoosraNumbersDataTableAlignment {
      grid-template-columns: 48px 100px 100px 100px 150px 300px;
    }
    .UsersTableAlignment {
      grid-template-columns: 48px 60px 125px 125px 200px 100px 100px;
    }
    .UsersDataTableAlignment {
      grid-template-columns: 48px 60px 125px 125px 200px 100px 100px;
    }
    .TabelContainer {
      height: auto;
    }
  }
  
  .rectangle {
    width: 260px;
    min-height: 356px;
    margin: 40px 0px 0px 17px;
    border-radius: 8px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #d1d3d4;
    background-color: ${(props) =>
    props.theme.colors.CallRoutingCardViewBgColor};
  }
  
  .exmptyrectangle {
    flex-direction: column;
    width: 260px;
    display: flex;
    min-height: 356px;
    margin: 40px 0px 0px 17px;
    border-radius: 8px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.04);
    border: dashed 1px #d1d3d4;
    background-color: ${(props) =>
    props.theme.colors.CallRoutingCardViewBgColor};
    justify-content: center;
    align-items: center;
  }
  
  .create-a-group{
    width: 126px;
    height: 19px;
    margin: 0 0 9px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #196cca;
  }
  
  .showme {
    display: none;
  }
  
  .showhim:hover .showme {
      display: block;
      position: absolute;
      background: ${(props) => props.theme.colors.callRoutingMenuBgColor};
      padding: 12px;
      width: 165px;
      order: solid 1px #f5f8fa;
      border-radius: 4px;
      box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.04);
  }
  
  .showMenu {
    display: flex;
    width: 144px;
    height: 28px;
    margin: 0 0 4px;
    padding: 7px 8px 7px;
    border-radius: 8px;
    background-color: ${(props) =>
    props.theme.colors.callRoutingMunuItamBgColor};
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.callRoutingMenuItemTextColor};;
    cursor: pointer;
  }
  
  .showDeleteMenu{
    display: flex;
    width: 144px;
    height: 28px;
    cursor: pointer;
    /* margin: 0 0 4px; */
    padding: 7px 8px 7px;
    border-radius: 8px;
    background-color: #ffe6ea;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ee3048;
  }
  .showMenuText {
    width: 120px;
    height: 13px;
    margin: 0 18px 0 0;
  }
  .column {
    float: left;
    width: fit-container;
    padding: 0 0px;
  }
  
  .row {
    margin: 0 0;
    padding-bottom: 36px;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 0px;
    }
  }
  
  .dot {
    height: 35px;
    width: 35px;
    background-color: #196cca;
    border-radius: 50%;
    display: inline-block;
  }
  
  .menudot {
    height: 25px;
    width: 25px;
    background-color: ${(props) =>
    props.theme.colors.callRoutingMenuDotBgColor};
    border-radius: 50%;
    display: inline-block;
  }

  .CallRoutingBg {
    background: ${(props) => props.theme.colors.tableBgColor};
    height: calc(100vh - 100px);
  }

  .groupName {
    font-size: 14px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    font-weight: 600;
  }
  .groupDescripition {
    font-size: 12px;
    color: ${(props) => props.theme.colors.callRoutingDescritionColor};
    font-weight: 600;
  }

  .dropColumnBg {
    background: ${(props) => props.theme.colors.tableBgColor};
  }
  .dragColumnBg {
    background: #414141;
  }

  .globalCallerTuneContainer {
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    margin-left: 40px;
    margin-top: 40px;
  }

  .callerTuneText {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #196cca;  
  }
  .setCallerTuneText {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }
  .sloganText{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }


  /*   web hook css  */
.Knowledge-Bar {
  width:40%;
  position: -webkit-sticky;
  /* for Safari users */
  position: sticky;
  top: 0px;
  float: right;
  padding: 20px 16px 16px;
  background: ${(props) => props.theme.colors.tableBgColor};
  box-shadow: -2px 0 2px 0 rgba(0, 0, 0, 0.16);
}

.Knowledge-Centre {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #196cca;
}

.Line-Break {
  height: 0;
  margin: 19px 0 0;
  border-top: 1px dotted #939598;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
  height: 1px;
}

.Head-Text {
  margin: 29px 0 16px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
  display: inline-flex;
}

.Webhook-Desc {
  margin: 16px 0 14px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
  display: inline-flex;
}

.Link {
  margin: 14px 0 588px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
}

.Support-Link {
  position: absolute;
  bottom: 80px;
  left:20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
}

.webHookContainer {
  width:70%;
  margin: 25px;
  display: inline-block;
  position: relative;
}

.webHookInput{
  width:80%;
  height: 48px;
  margin: 16px 7px 8px 0px;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #939598;
  display: inline-flex;
  color: #000000;
} 

.check-mark-img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.Validation {
  margin: 1px 7px 24px 0px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.callRoutingDescritionColor};
  display: inline-flex;
}

.req-dropdown {
  width: 594px;
  height: 48px;
  margin: 16px 7px 40px 0px;
  padding: 15px 16px 14px 24px;
  border-radius: 4px;
  border: solid 1px #939598;
  display: inline-flex;
}

.primary-btn {
  width: 289px;
  height: 48px;
  border-radius: 4px;
  background-color: #196cca;
  margin-right: 8px;
  border-color: transparent;
}

.primary-btn:hover {
  background-color: #3e8e41
}

.primary-btn:disabled,
.primary-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.secondary-btn {
  width: 289px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #ed3833;
  margin-left: 8px;
  background-color: #fff;
}

.secondary-btn:hover {
  background-color: #f0f4f0
}

.secondary-btn:disabled,
.secondary-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


.pri-btn-txt {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #fff;
}

.sec-btn-txt {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #ed3833;
}

.sec-btn-txt-disabled {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #ffffff;
}

.primary-btn-2 {
  width: 289px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #196cca;
  margin-right: 8px;
  background:  ${(props) => props.theme.colors.tableBgColor};

}

.primary-btn-2:hover {
background:  ${(props) => props.theme.colors.tableBgColor};

}

.secondary-btn-2 {
  width: 289px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #ed3833;
  margin-left: 8px;
  background:  ${(props) => props.theme.colors.tableBgColor};

}

.secondary-btn-2:hover {
  background:  ${(props) => props.theme.colors.tableBgColor};
}

.pri-btn-txt-2 {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #196cca;
}

.sec-btn-txt-2 {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #ed3833;
}
.text-style-1 {
  color: #196cca;
  margin: 0 4px 0 4px;
}
.drp-dwn-txt {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
}

.webhookPage {
  color: ${(props) => props.theme.colors.topBarAccTextColor};
  background-color: ${(props) => props.theme.colors.topBarBg};
  display: flex;
}

.webhookTitle {
  font-size: 17px;
  width:100%;
  color: ${(props) => props.theme.colors.topBarAccTextColor};
  font-weight: 600;
}

.callCommentButton{
  width: 32px;
  height: 32px;
  margin: 0 0 0 5px;
  padding: 8px;
  border-radius: 3px;
  background-color: #414288;
  border: none;
}

.callingButton{
  width: 32px;
  height: 32px;
  margin: 0 0 0 5px;
  padding: 8px;
  border-radius: 3px;
  background-color: green;
  border: none;
}

.webhookDesc {
  font-size: 14px;
  width:100%;
  color: ${(props) => props.theme.colors.callRoutingDescritionColor};
  font-weight: 600;
}
.Res-Comp-Wrap {
  width: 100%;
  background-color: ${(props) => props.theme.colors.topBarBg};
  padding-bottom: 30px;
}
 .Response-Card {
    width: 100%;
    height: auto;
    margin-top: 30px;
    padding: 12px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.disableInputBGColor};
}
.JSON-Obj{
    width: 100%;
    height: auto;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #07aa3f;  
} 
.DataCard{
  border: solid 1px #d2d3d4;
  border-radius: 4px;
  // max-width:300px;
  padding:12px;
  flex:1;
}
.DataCard h3{
  margin:0;
  font-size: 16px;
  font-weight: 500;
}
.DataCard p{
  margin:0;
  font-size: 30px;
  font-weight: bold;
  color: #ed3833;
}
  }
`;
