import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import HubspotImage from "../../../assets/images/HubspotImage.png";
import checkMark from "../../../assets/images/checkmark-circle.svg";
import {POST, GET} from "../../../services/HttpRequests";

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const CLIENT_ID= process.env.REACT_APP_HUBSPOT_CLIENT_ID;
const CLIENT_SECRET=process.env.REACT_APP_HUBSPOT_CLIENT_SECRET;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const authUrl = process.env.REACT_APP_AUTH_URL;

const HubSpotConnectCard = () => {
  const [connectedToHubspot, setConnectedToHubspot] = useState(false);
  
  const titleCss=  {
    fontSize: "16px",
    fontWeight: "600",
    color: "#726b6b",
    marginTop: "10px",
    marginBottom: "20px"
  }
  const locationObject = useLocation();

  const updateOauthToken = async() => {
    if(locationObject.search){
      const search = locationObject.search
      const code = search.split("=")[1]
      if(code) {
        const authCodeProof = {
          code,
          hubspotClientId: CLIENT_ID,
          hubspotClientSecret: CLIENT_SECRET,
          grantType: 'authorization_code',
          redirectUri: REDIRECT_URI,
          organisationId: localStorage.getItem('doosra-biz-organisation-id')
        }
        await POST(orgUrl + 'v1/organisation/hubspot-oauth', authCodeProof)
          .then((res) => {
            console.log("response - ", res)
            setConnectedToHubspot(true)
          })
          .catch((err) => {
            console.log("error -",err);
          });
      }    
    }
  }
  const getOrgOauthStatus = async() => {
    if(localStorage.getItem('doosra-biz-user-role')=== "ADMIN") {
      const query = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id')
      }
      await GET(orgUrl + 'v1/organisation/hubspot-oauth', query)
        .then((res) => {
          if(res?.data?.response?.OauthDetails?.access_token){
            setConnectedToHubspot(true);
          }
        })
        .catch((err) => {
          console.log("error -",err);
        });
    }
  }

  useEffect(()=> {
     getOrgOauthStatus();
  },[])
  
  useEffect(()=>{
    updateOauthToken();
  },[]);
  
  return (
    <div style={styles.card}>
      <div style={styles.imageContainer}>
        <img
          src={HubspotImage}
          alt="HubSpot CRM"
          style={styles.image}
        />
      </div>
      <h3 style={!connectedToHubspot ? titleCss : styles.title}>HubSpot CRM</h3>
      <div style={{marginBottom: "10px",display: !connectedToHubspot && "none"}}>
        <img
          src={checkMark}
          alt="checkMark"
          style={styles.checkMark}
        />
        <span style={styles.connectedText}>Connected</span>
      </div>
      {!connectedToHubspot ? 
        <a 
          href={authUrl}
          style={styles.button}
        >Connect</a>
      :
        <button 
          style={styles.buttonRed}
          onClick={()=>console.log("Disconnected from hubspot integration")}
        >Disconnect</button> 
      }
    </div>
  );
};

const styles = {
  card: {
    height: "256px",
    width: "256px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "12px",
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  imageContainer: {
    display: "grid",
    height: "128px",
    border: "solid 2px lightgrey",
    borderRadius:"6px",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  image: {
    width: "104px",
    height: "30px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#726b6b",
    marginTop: "5px",
    marginBottom: "0px"
  },
  checkMark: {
    height: "11px",
    width: "11px",
  },
  connectedText: {
    marginLeft: "5px",
    fontSize: "11px",
    marginBottom: "30px"
  },
  button: {
    display: "grid",
    height: "40px",
    width: "212px",
    margin: "auto",
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    backgroundColor: "#28a745",
    border: "none",
    borderRadius: "26px",
    cursor: "pointer",
    alignContent: "space-evenly"
  },
  buttonRed: {
    height: "40px",
    width: "212px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#F8153B",
    backgroundColor: "#fff",
    border: "1.5px solid #F8153B",
    borderRadius: "26px",
    cursor: "pointer",
  },
};

export default HubSpotConnectCard;