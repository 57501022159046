import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');

export default function ExportVoiceBroadcastPopup(props) {
  const [list, setList] = useState([]);
  const [broadcastName, setBroadcastName] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 'id', displayName: 'id' },
    { id: 'start_time', displayName: 'start_time' },
    { id: 'end_time', displayName: 'end_time' },
    { id: 'name', displayName: 'name' },
    { id: 'mobile_number', displayName: 'mobile_number' },
    { id: 'campaign_name', displayName: 'campaign_name' },
    { id: 'caller_id', displayName: 'caller_id' },
    { id: 'call_duration', displayName: 'call_duration' },
    { id: 'dtmf', displayName: 'dtmf' },
    { id: 'call_status', displayName: 'call_status' },
  ];

  const openPopup = () => {
    setIsPopupOpen(true);
    GET(`${broadcastUrl}v1/voice-broadcast`, {
      id: props.id,
      pageNo: 1,
      pageSize: 50000,
      organisationId,
    })
      .then((res) => {
        return res.data.response.broadcast;
      })
      .then((res) => {
        setList(res.call_details);
        setBroadcastName(res.name);
        console.log('-----', res.name);
        setDataFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <button
        className="Otherbutton"
        style={{ backgroundColor: '#07aa3f', color: 'white' }}
        onClick={openPopup}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download call logs</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 50,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`${broadcastName}_${new Date().toLocaleDateString()}`}
                separator=","
                columns={columns}
                datas={
                  list.length
                    ? list.map((detail) => {
                        return {
                          id: detail?._id,
                          start_time: detail?.call_started_at
                            ? new Date(detail.call_started_at)
                                .toLocaleString()
                                .replace(',', '')
                            : '-',
                          end_time: detail?.call_ended_at
                            ? new Date(detail.call_ended_at)
                                .toLocaleString()
                                .replace(',', '')
                            : '-',
                            name: detail?.name,
                          mobile_number: detail?.number,
                          campaign_name: broadcastName || '-',
                          caller_id: detail?.did || '-',
                          call_duration: detail?.duration || '-',
                          dtmf: detail?.dtmfKey || '-',
                          call_status: detail?.status || '-',
                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}
