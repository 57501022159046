import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET } from '../../../services/HttpRequests';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import { commonStyle } from '../../../assets/css/css';

const numberMaskingUrl = process.env.REACT_APP_NUMBER_MASKING_API;

class NumberMaskingDidsPage extends Component {
  constructor() {
    super();
    this.state = {
      callsCount: 0,
      bindingsCount: 0,
      didsCount: 0,
      numbersList: [],
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      loading: false,
      searchData: {},
    };
  }

  async componentDidMount() {
    this.getDidNumbersList();
    this.getCallsCount();
    this.getBindingsCount();
  }

  getBindingsCount = () => {
    GET(`${numberMaskingUrl}v1/bindings`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ bindingsCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getCallsCount = () => {
    GET(`${numberMaskingUrl}v1/calls`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ callsCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getDidNumbersList = () => {
    this.setState({ loading: true });

    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    GET(`${numberMaskingUrl}v1/did-list`, data)
      .then((res) => res.data.response)
      .then((res) => {
        this.setState({ didsCount: res.payload.count });
        this.setState({ numbersList: res.payload.docs });
      })
      .catch(() => null);
  };

  seachHandler = (data) => {
    data.pageNo = 1;
    // data.pageSize = this.state.pageSize;
    data.organisation_id = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${numberMaskingUrl}v1/did-list`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          count: res.numbers.count,
          numbersList: res.numbers.docs,
          totalPages: Math.ceil(res.numbers.count / this.state.pageSize),
          pageSize: res.pageSize,
          searchData: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { numbersList } = this.state;
    const { isNumberMaskingEnabled, isFreeFormEnabled } = this.props;
    if (!isNumberMaskingEnabled) {
      return (
        <div className={`${this.props.className}`}>
          <Sidebar />
          <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
            <Topbar />
            <div className="ContentContainer">
              <UpgradeServices
                icon="numberMasking"
                heading="Number Masking"
                text="Receive and read SMSes right from your Alohaa dashboard"
                emailSent={this.props.isNumberMaskingEmailSent}
              />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={`${this.props.className}`}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <TabSearchBar
              tabs={[
                !isFreeFormEnabled &&
                {
                  title: 'Bindings',
                  subtitle: `${this.state.bindingsCount}`,
                  onClick: () => this.props.history.push('/number-masking-bindings'),
                },
                {
                  title: 'Call Logs',
                  subtitle: `${this.state.callsCount}`,
                  onClick: () => this.props.history.push('/number-masking-call-logs'),
                },
                {
                  title: 'Virtual Nos',
                  subtitle: `${this.state.didsCount}`,
                  active: true
                },
              ].filter(tab => tab)}
            >
              <SearchBar
                type='number-masking-dids'
                disableTitle
                count={this.state.count}
                reset={this.getDidNumbersList}
                search={this.seachHandler}
                isFreeFormEnabled={isFreeFormEnabled}
              />
            </TabSearchBar>
            <DataTable.Table>
              <DataTable.Head>
                <DataTable.CellHead>ASSIGNED AT</DataTable.CellHead>
                <DataTable.CellHead>MASKING NUMBER</DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>
                {(numbersList || []).map((log) => (
                  <DataTable.Row>
                    <DataTable.Cell>{log.created_at ? moment(log.created_at).format('DD-MM-YYYY') : "-"}</DataTable.Cell>
                    <DataTable.Cell>{`0${log.did.slice(-10)}`}</DataTable.Cell>
                  </DataTable.Row>
                ))}
              </DataTable.Body>
            </DataTable.Table>
            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.getDidNumbersList(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  isNumberMaskingEmailSent: state.topbar.isNumberMaskingEmailSent,
  isFreeFormEnabled: state.topbar.isFreeFormEnabled,
});

export default commonStyle(
  connect(mapStateToProps, {})(NumberMaskingDidsPage)
);
