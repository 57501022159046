import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import EyeVisiible from "../../../assets/images/EyeVisible.svg"
import EyeNotVisiible from "../../../assets/images/EyeNotVisible.svg"
import { GET, POST, PUT } from '../../../services/HttpRequests';
import { useEffect, useState } from 'react';
import "../component/Tooltip.css";
import { toast } from 'react-toastify';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CampaignOptions from '../component/CampaignOptions';
import { ReportIcon } from '../../../assets/Icons/Icons';
import CampaignSearchBar from '../component/CampaignSearchBar';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { isSuperAdminWithPermission } from '../../../utility/utility';
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

export function Campaigns(props) {

  function CampaignList(props) {
    const history = useHistory();
    const toggleLeadListVisibility = (leadListId, isHidden) => {
      let data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        campaignId: leadListId,
        isHidden,
      };

      POST(
        cloudCallCenterUrl + 'campaigns/toggle-campaign-visibility',
        data
      )
        .then((res) => {
          fetchCampaignListHandler();
          return true;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          return false;
        });
    }
    return (
      <DataTable.Table>
        <DataTable.Head>
          <DataTable.CellHead width="20">#</DataTable.CellHead>
          <DataTable.CellHead width="110">CAMPAIGN NAME</DataTable.CellHead>
          <DataTable.CellHead width="110">DESCRIPTION</DataTable.CellHead>
          <DataTable.CellHead width="70">START DATE</DataTable.CellHead>
          <DataTable.CellHead width="70">END DATE</DataTable.CellHead>
          <DataTable.CellHead width="70">START TIME</DataTable.CellHead>
          <DataTable.CellHead width="70">END TIME</DataTable.CellHead>
          <DataTable.CellHead width="100">CAMPAIGN TYPE</DataTable.CellHead>
          <DataTable.CellHead width="40">LEADS</DataTable.CellHead>
          <DataTable.CellHead width="40">AGENTS</DataTable.CellHead>
          <DataTable.CellHead width="70">CALLS</DataTable.CellHead>
          <DataTable.CellHead width="110">CONNECTED CALLS</DataTable.CellHead>
          <DataTable.CellHead width="110">WAITING CALLS</DataTable.CellHead>
          <DataTable.CellHead width="70" style={{ textAlign: 'end' }}>
            ACTIONS
          </DataTable.CellHead>
        </DataTable.Head>
        <DataTable.Body>
          {props?.campaignsList?.length >= 0 &&
            props?.campaignsList.map((item, index) => (
              <DataTable.Row key={item._id}>
                <DataTable.Cell>{((currentPage - 1) * pageSize) + index + 1}</DataTable.Cell>
                <DataTable.Cell
                  style={{ cursor: 'pointer', textDecoration: "underline" }}
                  onClick={() => {
                    history.push(`/cc-campaign?id=${item._id}`);
                  }}
                >
                  {item?.campaignName}
                </DataTable.Cell>
                <DataTable.Cell>{item?.campaignDescription}</DataTable.Cell>
                <DataTable.Cell>{item?.campaignStartDate.slice(0, 10)}</DataTable.Cell>
                <DataTable.Cell>{item?.campaignEndDate.slice(0, 10)}</DataTable.Cell>
                <DataTable.Cell>{moment(item?.campaignStartTime.slice(0, 5), 'HH:mm').format('hh:mm A')}</DataTable.Cell>
                <DataTable.Cell>{moment(item?.campaignEndTime.slice(0, 5), 'HH:mm').format('hh:mm A')}</DataTable.Cell>
                <DataTable.Cell style={{ textTransform: "capitalize" }}>
                  {item?.campaignType || "dynamic"}
                </DataTable.Cell>
                <DataTable.Cell>{item?.totalcampainLeads}</DataTable.Cell>
                <DataTable.Cell>{item?.totalAgents}</DataTable.Cell>
                <DataTable.Cell>{item?.campaignTotalCalls}</DataTable.Cell>
                <DataTable.Cell>{item?.campaignAnsweredCalls}</DataTable.Cell>
                <DataTable.Cell>{item?.waitingRingingMetrics?.waitingCount || 0}</DataTable.Cell>
                <DataTable.Cell
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '8px',
                  }}
                >
                  <div className="tooltip-container">
                    <div className="tooltip-button">
                      {item?.isHidden ?
                        !isSuperAdminWithPermission('EDIT') ? (
                          <div
                            style={{
                              border: "none",
                              height: 32,
                              width: 32,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <img src={EyeNotVisiible} alt=""></img>
                          </div>
                        ) : (
                          <button
                            style={{
                              border: "none",
                              height: 32,
                              width: 32,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <img
                              onClick={() => toggleLeadListVisibility(item._id, false)}
                              src={EyeNotVisiible}
                              alt=""
                            ></img>
                          </button>
                        )
                        :
                        !isSuperAdminWithPermission('EDIT') ? (
                          <div
                            style={{
                              border: "none",
                              height: 32,
                              width: 32,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <img src={EyeVisiible} alt=""></img>
                          </div>
                        ) : (
                          <button
                            style={{
                              border: "none",
                              height: 32,
                              width: 32,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"

                            }}
                          >
                            <img
                              onClick={() => toggleLeadListVisibility(item._id, true)}
                              src={EyeVisiible}
                              alt=""
                            ></img>
                          </button>
                        )
                      }
                    </div>

                    <div className="tooltip-text left" data-tip-position="left">
                      {item?.isHidden ? "Click to unhide " : "Click to hide"}  campaign <br />to agents
                      < span className="arrow-right"></span>
                    </div>
                  </div>
                  <div className="tooltip-container">
                    <div className="tooltip-button">
                      <ReportIcon
                        onClick={() => {
                          history.push(`campaign-call-logs?id=${item._id}`);
                        }}
                      />
                    </div>
                    <div className="tooltip-text left" data-tip-position="left">
                      History
                      < span className="arrow-right"></span>
                    </div>
                  </div>
                  <CampaignOptions id={item._id} status={item.campaignStatus} fetchCampaignListHandler={fetchCampaignListHandler} />
                </DataTable.Cell >
              </DataTable.Row >
            ))
          }
        </DataTable.Body >
      </DataTable.Table >
    );
  }

  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [campaignsList, setCamapignsList] = useState([]);
  const [dispositions, setDispositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState("ACTIVE");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState(null);
  const [filterCampaignStatus, setFilterCampaignStatus] = useState('ACTIVE');
  const [totalPages, setTotalPages] = useState(1);
  const tabs = [];
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Overview Dashboard',
      onClick: () => props.history.push('/dashboard'),
      active: false,
    });
  }

  tabs.push({
    title: 'Campaigns List',
    onClick: () => props.history.push('/cc-campaigns'),
    active: true,
  });
  if (userRole === "ADMIN") {
    tabs.push({
      title: 'Agents',
      onClick: () => props.history.push('/agents'),
      active: false,
    });
    // tabs.push({
    //   title: 'Agent Reports',
    //   onClick: () => props.history.push('/recovery-x-agent-report'),
    //   active: false,
    // });
  }

  tabs.push({
    title: 'Calls',
    onClick: () => props.history.push('/calls'),
    active: false,
  });

  if (userRole === "ADMIN") {
    tabs.push({
      title: 'BlackList',
      onClick: () => props.history.push('/blacklist'),
      active: false,
    });
  }

  const getDispositions = () => {
    // setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(
      cloudCallCenterUrl + 'disposition',
      data
    )
      .then((res) => {
        setDispositions(res.data.response.dispositions)
        // setLoading(false);
        return true;
      })
      .catch((err) => {
        // setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  }

  function updateCampaignsStatuses() {
    if (isSuperAdminWithPermission("EDIT")) {
      setLoading(true);
      PUT(cloudCallCenterUrl + 'campaigns/update-campaigns-status')
        .then((res) => {
          console.log(res.data.response);
          fetchCampaignListHandler();
          // setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          setLoading(false);
        });
    } else {
      fetchCampaignListHandler();
    }
  }

  const fetchCampaignListHandler = (filter = null, status = "ACTIVE", value) => {
    console.log("the filter in the fetch is", filter, value);
    let temp = value || currentPage;
    if (current !== status) {
      temp = 1;
    }
    setCurrent(status);
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignStatus: status,
      pageNo: temp,
      pageSize: pageSize
    };
    setFilterCampaignStatus(status);
    setFilter(filter);
    if (filter) {
      data.searchKey = filter.searchKey;
      data.searchValue = filter.searchValue;
      data.fromDate = filter.fromDate;
      data.toDate = filter.toDate;
    }
    GET(cloudCallCenterUrl + 'campaign/get-campaigns', data)
      .then((res) => {
        setCamapignsList(res?.data?.response?.campaignData);
        setCurrentPage(res?.data?.response?.pageNo);
        setTotalPages(res?.data?.response?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchCampaignsWithFilterHandler = (filter) => {
    fetchCampaignListHandler(filter, current);
  }
  useEffect(() => {
    updateCampaignsStatuses();
    fetchCampaignListHandler();
    getDispositions();
  }, []);

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar tabs={tabs}>
            <SearchBar
              type="recovery-x-campaign"
              subType='cloudCall'
              disableTitle
              refreshList={() => getDispositions()}
              dispositions={dispositions}
            />
          </TabSearchBar>
          <CampaignSearchBar
            search={fetchCampaignsWithFilterHandler}
            reset={fetchCampaignListHandler}
            current={current}
            fetchCampaignListHandler={fetchCampaignListHandler}
          />
          {loading && <PreLoader />}
          {!loading && <CampaignList campaignsList={campaignsList} />}
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => fetchCampaignListHandler(filter, filterCampaignStatus, value)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(Campaigns));
