import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../../common/component/table/DataTable';
import CallForwardToggles, { Toggle } from '../component/CallForwardToggles';
import { isSuperAdminWithPermission } from '../../../utility/utility';

class CallSettingsPopup extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      error: '',
    };
    this.initialState = this.state;
  }

  openPopup = () => {
    this.initialState = { ...this.initialState, ...this.state };
    this.setState(this.initialState);
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState({ ...this.initialState });
    this.setState({ popupOpen: false });
  };

  render() {
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Call Settings</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <DataTable.Table
              style={{ width: '100%', marginTop: 15, marginBottom: 5 }}
            >
              <DataTable.Body>
                {this.props.isOrgOutboundCallEnabled && (
                  <DataTable.Row
                    key={Math.random()}
                    style={{ borderBottom: '2px solid transparent' }}
                  >
                    <DataTable.CellHead
                      style={{
                        width: '75%',
                        paddingLeft: '5%',
                        fontSize: '100%',
                      }}
                    >
                      Outgoing Calls
                    </DataTable.CellHead>
                    <DataTable.Cell>
                      <Toggle
                        disabled={!isSuperAdminWithPermission('EDIT')}
                        value={this.props.user.isOutboundCallEnabled}
                        onChange={this.props.changeOutboundCallStatus(
                          this.props.user
                        )}
                      />
                    </DataTable.Cell>
                  </DataTable.Row>
                )}
                {this.props.callForwardingStatus && (
                  <CallForwardToggles
                    masterCallForwarding={this.props.callForwardingStatus}
                    userData={this.props.user}
                    forwardingStatus={
                      this.props.user.organisation.is_call_forwarding_allowed
                    }
                    forwardingState={
                      this.props.user.organisation.is_call_forwarding
                    }
                    callForwardingStatusHandler={(val) =>
                      this.props.callForwardingStatusHandler(val)
                    }
                    callForwardingStateHandler={(val) =>
                      this.props.callForwardingStateHandler(val)
                    }
                    changeOutboundCallStatus={(val) =>
                      this.props.changeOutboundCallStatus(val)
                    }
                  />
                )}
                {
                  // <DataTable.Row
                  //   key={Math.random()}
                  //   style={{ borderBottom: '2px solid transparent' }}
                  // >
                  //   <DataTable.CellHead
                  //     style={{
                  //       width: '75%',
                  //       paddingLeft: '5%',
                  //       fontSize: '100%',
                  //     }}
                  //   >
                  //     SMS Deletion
                  //   </DataTable.CellHead>
                  //   <DataTable.Cell>
                  //     <Toggle
                  //       disabled={!isSuperAdminWithPermission('EDIT')}
                  //       value={this.props.user.is_sms_deletion_enabled}
                  //       onChange={this.props.changeSMSDeletionStatus(
                  //         this.props.user
                  //       )}
                  //     />
                  //   </DataTable.Cell>
                  // </DataTable.Row>
                }
              </DataTable.Body>
            </DataTable.Table>
          </div>
        </div>
      </Popup>
    );

    return (
      <React.Fragment>
        <button onClick={this.openPopup} className="BlueButton">
          <FontAwesomeIcon icon={faCog} style={{ fontSize: 17 }} />
        </button>
        {popper}
      </React.Fragment>
    );
  }
}

export default CallSettingsPopup;
