const initialState = {
  isSideBarOpen: false,
  menuName: "",
  id: 0,
  data: null,
};

function callFlowReducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_SIDEBAR_OPEN':
      return {
        ...state,
        isSideBarOpen: action.payload.isSideBarOpen,
        menuName: action.payload.menuName,
        id: action.payload.id,
        data: action.payload.data
      };
    default:
      return state;      
  }
};

export default callFlowReducer;