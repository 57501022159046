export function isFullScreenRoute(route) {
    const sanitizedRoute = route.trim().replace(/\/$/, '');
    const fullScreenRoutes = [
        "/ship-rocket/subscription/retry",
        "/ship-rocket/subscription/approval",
        "/auth/ship-rocket/callback",
        "/auth/google/callback",
        "/auth/microsoft/callback",
    ];
    return fullScreenRoutes.includes(sanitizedRoute);
}
