import React from "react";
import BackArrow from '../../assets/images/backArrowBlue.png';


const CallFlowSubHeader = ({ props, callFlowName, handleGoLive, handleSaveAsDraft, isAlohaaSideBarOpen, handleBack }) => {
  return (
    <div className="CallFlowSubHeader">
        <div style={{ marginLeft:  isAlohaaSideBarOpen ? "220px" : "60px" }}>
          <button className="BackButton" 
            onClick={() => handleBack()}
          >
            <img style={{ height: "24px", width: "24px", color: "#6B4ACE" }} src={BackArrow} />
          </button>
            <text>{callFlowName}</text>
        </div>
        <div>
        <button className="GoLiveButton" onClick={() => handleGoLive()}>
        <text className="GoLiveText">
            Go Live
            </text>
        </button>
        <button className="SaveAsDraftButton" onClick={() => handleSaveAsDraft()}>
            <text className="SaveText">
            Save as draft
            </text>
        </button>
        </div>
    </div>
  )
};

export default CallFlowSubHeader;