import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { RiErrorWarningFill } from 'react-icons/ri';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { GET, POST } from '../../../services/HttpRequests';
import PasswordCheck from '../../../common/component/passwordCheck/PasswordCheckComponent';
import { useAuth } from '../../../providers/AuthProvider';

import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import Wall from '../../../assets/images/loginPageWall.png';
import loaderIcon from '../../../assets/json/loader.json';
import Lottie from 'react-lottie';
const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const userUrl = process.env.REACT_APP_USER_API;
const planUrl = process.env.REACT_APP_PLAN_API;

export function ThirdPartySignUpPage (props) {
  const { saveSession } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [shipRocketErrorMessage, setShipRocketErrorMessage] = useState("");
  const [shipRocketTokenStatus, setShipRocketTokenStatus] = useState("NONE");
  const [organisationName, setOrganisationName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // if (planCodeParam) {
    //   localStorage.setItem('alohaa_plan_code', planCodeParam);
    //   localStorage.setItem('alohaa_plan_is_trial', isTrialParam);
    // }

    console.log("doosra-business-sessionToken", localStorage.getItem('doosra-business-sessionToken'))
    validateShipRocketToken();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== ''
    ) {
      props.history.push('/');
    }
  }, []);

  const validateShipRocketToken = async () => {
    console.log("validateShipRocketToken")
    const shipRocketToken = getShipRocketToken();
    GET(`${process.env.REACT_APP_ORGANIZATION_API}v1/external/ship-rocket/account/validate`, { token: shipRocketToken })
      .then(async (res) => {
        setIsLoading(false);
        console.log("Ship Rocket Account", res.data.response);
        const accountDetails = res.data.response?.account ?? {};
        if (res.data.response?.isUserRegistered) {
          props.history.push(`/?username=${accountDetails?.email}`);
        } else {
          setError('');
          setShipRocketTokenStatus("VALID");
          setOrganisationName(accountDetails.organisationName);
          setPhoneNumber(accountDetails.phoneNumber);
          setEmail(accountDetails.email);
        }
      })
      .catch((err) => {
        setShipRocketErrorMessage(err.response?.data?.error?.reason ?? "");
        setShipRocketTokenStatus("INVALID");
        console.log("Ship Rocket SSO Error", err.response);
        console.log(err);
        setError(err?.response?.data?.error?.reason);
        setIsLoading(false);
      });
  }

  const goToLogin = async (userId, organisations) => {
    try {
      setIsLoading(true);
      // if (step === 3) {
      // const response = await POST(`${userUrl}v1/auth/signup-login`, {
      const response = await POST(`${process.env.REACT_APP_USER_API}v1/auth/signup-login`, {
        userId,
      });
      setError('');
      resetData();
      // localStorage.setItem('is_free_trial_enabled', true);
      // localStorage.setItem('free_trial_start_date', true);
      // localStorage.setItem('free_trial_end_date', true);
      saveSession(response.data.response, false);
      window.location.reload();
      // }
    } catch (err) {
      setError(err?.response?.data?.error?.reason);
      setIsLoading(false);
      console.error('Error during login:', err);
    }
  };

  const resetData = () => {
    // setNewPassword('');
    // setConfirmPassword('');
    // setNewPasswordVisible(false);
    // setConfirmPasswordVisible(false);
    // setCheckPassword(false);
  };

  const getShipRocketToken = () => {
    const queryParams = new URLSearchParams(props.location.search);
    return queryParams.get('token');
  }

  const submitCredentialsHandler = async (event) => {
    if (event) {
      event.preventDefault();
    }
    // return goToLogin("9966137403795221")
    setError('');
    const shipRocketToken = getShipRocketToken();

    const reqBody = {
      'token': shipRocketToken,
    }
    setIsLoading(true);
    await POST(`${process.env.REACT_APP_ORGANIZATION_API}v1/external/ship-rocket/sso`, reqBody)
      .then(async (res) => {
        console.log("Ship Rocket SSO", res.data.response);
        setError('');
        resetData();
        await goToLogin(
          res.data.response?.userId,
        );
      })
      .catch((err) => {
        console.log("Ship Rocket SSO Error");
        console.log(err)
        setError(err?.response?.data?.error?.reason);
        setIsLoading(false);
      });
  };

  const errorContainer = () => {
    return (
      <div className="ErrorContainer ErrorContainerMargin">
        {error ? (
          <React.Fragment>
            <div style={{ fontSize: 14 }}>
              <RiErrorWarningFill />
              &nbsp;
              {error}{' '}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  };

  if (shipRocketTokenStatus === "NONE") {
    return (
      <MainContainer>
        <div>
          <div>
            <div>
              <div className="AuthHeadContainer">
                <p className="AuthSubHeader">Fetching Your Account...</p>
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    );
  }

  if (shipRocketTokenStatus === "INVALID") {
    return (
      <MainContainer>
        <div>
          <div>
            <div>
              <div className="AuthHeadContainer">
                <p className="AuthSubHeader">
                  {
                    shipRocketErrorMessage
                      ? shipRocketErrorMessage
                      : "Failed to fetch account details. Please again."
                  }
                </p>
                <a
          href={process.env.REACT_APP_SHIP_ROCKET_APP_URI}
          className="AuthButton AuthButtonInvert GetInTouch"
          style={{ padding: '14px' }}
        >
          Try Again
        </a>

              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <div>
        <div>
          <div>
            <div className="AuthHeadContainer">
              <p className="AuthSubHeader">Create an account</p>
              <p>Based on information pulled from ShipRocket, your Alohaa account is registered with the following details:</p>
              <p>Email ID: <b>{email}</b> & Phone Number: <b>{phoneNumber}</b></p>
              <p>Click on create account to set up a new organization under the name <b>{organisationName}</b></p>
            </div>
          </div>
          <div>
            <form onSubmit={submitCredentialsHandler}>
            {errorContainer()}
            <button
                className={`AuthButton ${isLoading ? 'ButtonLoading' : ''}`}
                type="submit"
                disabled={isLoading}
              >
                <span className="ButtonText">{isLoading ? '' : 'CREATE ACCOUNT'}</span>
                {isLoading && <span className="ButtonSpinner"></span>}
              </button>
            </form>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

function MainContainer ({ children }) {
  return (
    <div className="login-main">
      <div className="section-1">
        <div className="login-container">
          <>
            <img className="login-container-logo" alt="" src={Logo} />
            {/* <ProgressStep currentStep={step} /> */}
          </>
          {children}
        </div >
      </div >
      <img alt="img" style={{ height: '100vh' }} src={Wall} />
    </div >
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});
export default commonStyle(connect(mapStateToProps, {})(ThirdPartySignUpPage));
