/* eslint-disable */
import React, { useState } from 'react';
import './styles.css';
import '../../../assets/css/Popups.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import historyIcon from '../../../assets/images/callHistoryRecoveryCall.svg';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import DataTable from '../../../common/component/table/DataTable';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import AudioPlayer from './AudioPlayer';
import { useHistory } from 'react-router-dom';

export default function PreviewCampaignAgentsPopup(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const PopupContainer = {
    width: '380px',
  };
  const scrollEffect = {
    overflowY: 'auto',
  };
  const datatableStyles = {
    width: '50%',
    paddingLeft: '23%',
  };
  let displayData;
  if (loading) displayData = <PreLoader />;
  if (!loading && props.agentsData.length > 0) {
    displayData = props.agentsData.map((agent, index) => (
      <DataTable.Row>
        <DataTable.Cell style={{ ...datatableStyles, paddingRight: '10%' }}>
          {index + 1}
        </DataTable.Cell>
        <DataTable.Cell>{agent || '-'}</DataTable.Cell>
      </DataTable.Row>
    ));
  }
  let popper = (
    <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="PopupContainer" style={{ width: '380px' }}>
        <div className="PopupHeaderContainer" style={{ ...PopupContainer }}>
          <p>Preview Agents</p>
          <img
            style={{ left: '90%' }}
            alt=""
            className="PopupClose"
            src={Close}
            onClick={(e) => {
              console.log(e);
              setPopupOpen(false);
            }}
          />
        </div>
        <div className="PopupBody" style={{ ...PopupContainer }}>
          <DataTable.Table style={{ ...PopupContainer }}>
            <DataTable.Head>
              <DataTable.CellHead style={{ ...datatableStyles }}>
                #
              </DataTable.CellHead>
              <DataTable.CellHead>AGENT NAME</DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>{displayData}</DataTable.Body>
          </DataTable.Table>
        </div>
      </div>
    </Popup>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <span>
        <span
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            setPopupOpen(true);
          }}
        >
          Preview
        </span>
        {popper}
      </span>
    </div>
  );
}
