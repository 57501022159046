/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import '../../cloudCallCenter/component/styles.css';
import { OUTGOING_CALL_LOGS_MAPPER } from '../../../common/constants';
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import phoneWhite from '../../../assets/images/ioscall.png';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import AudioPlayer from '../../cloudCallCenter/component/AudioPlayer';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import { setIsOpen, setDestinationNumber, setCallId, setContactId, setCampaignId } from '../../../actions/dailPadActions';
import AddIndividualCallDispositionsPopup from '../../cloudCallCenter/component/AddIndividualCallDispositionsPopup';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import ModifyHeadersPopUp from '../../../common/component/TableHeaders/TableHeaders';
import moment from 'moment';
import { toast } from 'react-toastify';
import CallReportSearchBar from '../component/CallReportSearchBar';
import { setCallLogsTableHeaders } from '../../../actions/recoveryXActions';
import { CallIcon } from '../../../assets/Icons/Icons';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const userUrl = process.env.REACT_APP_USER_API;

function CampaignReportTable(props) {
  return (
    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
      <DataTable.Table style={{ width: '100%' }}>
        <DataTable.Head>
          <DataTable.CellHead width="20">#</DataTable.CellHead>
          {props?.headers?.length > 0 &&
            props?.headers?.map((header) => (
              <DataTable.CellHead width="50">
                {header.toUpperCase()}
              </DataTable.CellHead>
            ))}
          {props.modify !== 'false' ? (
            <DataTable.CellHead width="100" style={{ textAlign: 'end' }}>
              ACTIONS
            </DataTable.CellHead>
          ) : (
            ''
          )}
        </DataTable.Head>
        <DataTable.Body>
          {props?.data?.length > 0 &&
            props?.data?.map((row, index) => (
              <DataTable.Row id={index}>
                <DataTable.Cell width="20">{1 + index}</DataTable.Cell>
                {row &&
                  props?.headers?.length > 0 &&
                  props?.headers?.map((header) => (
                    <DataTable.Cell width="50">
                      {row[header] || '-'}
                    </DataTable.Cell>
                  ))}
                {props.modify !== 'false' && (
                  <DataTable.Cell width="100" style={{ textAlign: 'end' }}>
                    <div className="tooltip-container">

                      <button
                        onClick={() => {
                          console.log('agentStatus', props.agentStatus);
                          if (localStorage.getItem('is_dialer_widget_enabled') === 'true') {
                            if (props.agentStatus === 'Online') {
                              props.setIsOpen({ isOpen: true });
                              props.setDestinationNumber({ destinationNumber: row.number });
                              props.setCallId({ callId: row._id });
                              props.setContactId({
                                contactId: ""
                              });
                              props.setCampaignId({ campaignId: props.selectedCampaign });
                            } else {
                              if (props.agentStatus === "Busy") {
                                toast.error("There is a active call");
                              }
                              else {
                                toast.error('Please go to Online');
                              }
                            }
                          }
                        }}
                        className="callingButton tooltip-button"
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CallIcon
                          style={{
                            backgroundColor: '',
                            height: '16px',
                            color: 'white',
                          }}
                        />
                      </button>

                      <div className="tooltip-text left" data-tip-position="left">
                        Click to call
                        <span className="arrow-right"></span>
                      </div>
                    </div>
                  </DataTable.Cell>
                )}
              </DataTable.Row>
            ))}
        </DataTable.Body>
      </DataTable.Table>
    </div>
  );
}

export function AgentCampaignLeads(props) {
  const statusMapper = {
    answered: 'Answered',
    not_answered: 'Not Answered',
  };
  let displayData;
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [totalCalls, setTotalCalls] = useState(0);
  const [exportFilters, setExportFilters] = useState({});
  const [users, setUsers] = useState([]);
  const [campaignsList, setCamapignsList] = useState('');
  const [filteredCampaigns, setFilteredCampaigns] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [filteredHeaders, setFilteredHeaders] = useState(['name', 'number', 'secondaryNumber']);

  const getLeadsCount = (count) => {
    setCount(count);
  };
  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallCenterUrl + 'disposition', data)
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const getCampaigns = () => {
    const data = {
      fetchOnlyCampaignsList: true,
      nonDeleted: true,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    if (userRole === "USER") {
      data.userId = localStorage.getItem('doosra-biz-user-id')
    }
    GET(cloudCallCenterUrl + 'campaign/get-campaigns', data)
      .then((res) => {
        setCamapignsList(res?.data?.response?.campaignData);
        setFilteredCampaigns(res?.data?.response?.campaignData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    // fetchOrganisationReport();
    getCampaigns();
    getDispositions();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function formatTime(seconds) {
    if (seconds === 0) {
      return "-";
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes.toString().padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    else {
      formattedTime = `${minutes.toString().padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }

  const fetchOrganisationReport = (pageNo = 0, filter = {}) => {
    setLoading(true);
    let data = { pageNo: 0, pageSize: 10000 };
    if (filter.searchKey && filter.searchCondition) {
      data.searchKey = filter.searchKey;
      data.searchValue = filter.searchCondition;
    }
    if (filter.campaignId) {
      data.campaignId = filter.campaignId;
    }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.agentId = localStorage.getItem('doosra-biz-agent-id');
    GET(cloudCallCenterUrl + 'campaigns/get-user-campaign-leads', data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setSelectedCampaign(filter.campaignId);
        setTableData(res.userCampaignLeads);
        setFilteredHeaders(res.headers);
        setExportFilters(data);
        setTotalPages(res.totalPages || 1);
        setTotalCalls(res.totalCalls || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTableData([]);
        setLoading(false);
      });
  };

  const tabs = [];

  if (userRole === 'ADMIN') {
    tabs.push({
      title: 'Campaigns',
      onClick: () => props.history.push('/campaigns-v2'),
      active: false,
    });
  }
  tabs.push({
    title: 'Calls',
    // subtitle: `${totalCalls}`,
    onClick: () => props.history.push('/calls-v2'),
    active: false,
  });
  tabs.push({
    title: 'Leads',
    onClick: () => props.history.push('/agent-leads'),
    active: true,
  });

  const tempfunction = (data = {}) => {
    setSearchKey(data.searchKey);
    setSearchValue(data.searchCondition);
    console.log(data);
    fetchOrganisationReport(0, data);
  };
  const resetFunction = (data = {}) => {
    setSearchKey('');
    setSearchValue('');
    setFromDate('');
    setToDate('');
    setCurrentPage(1);

    fetchOrganisationReport(1, data, true);
  };

  const filterCampaigns = (name) => {
    const regexp = new RegExp(name, 'i');
    const updatedCampaigns = campaignsList.filter((campaign) => {
      const shouldFilter = regexp.test(campaign.campaignName);
      return shouldFilter;
    });
    setFilteredCampaigns(updatedCampaigns);
  };

  return (
    <div className={`${props.className}`}>
      <Sidebar />
      <div className={props.sidebarClosed ? 'Content' : 'ContentFull'}>
        <Topbar />
        <div className="ContentContainer">
          <TabSearchBar type={'campaigns'} tabs={tabs}>
            <SearchBar
              type="recovery-call-logs"
              optional="campaigns"
              exportFilters={exportFilters}
              disableTitle
              count={count}
              getLeadsCount={(e) => getLeadsCount(e)}
              search={(e) => tempfunction(e)}
              reset={(e) => resetFunction(e)}
            />
          </TabSearchBar>
          <CallReportSearchBar
            type="agent-campaign-leads"
            disableTitle
            count={count}
            userData={users}
            dispositions={dispositions}
            campaignsList={filteredCampaigns}
            filterCampaigns={filterCampaigns}
            getLeadsCount={(e) => getLeadsCount(e)}
            search={(e) => tempfunction(e)}
            reset={(e) => resetFunction(e)}
          />

          {loading ? <PreLoader /> :
            (
              tableData?.length > 0 ? <CampaignReportTable
                modify={true}
                headers={filteredHeaders}
                selectedCampaign={selectedCampaign}
                data={tableData}
                {...props}
              /> : <div style={{
                height: '300px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: 500
              }}>
                No Leads
              </div>
            )
          }
          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => {
              setCurrentPage(value);
              fetchOrganisationReport(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callLogsTableHeaders: state.recovery.callLogsTableHeaders,
  agentStatus: state.dailpad.agentStatus,
});
export default commonStyle(
  connect(mapStateToProps, {
    setCallLogsTableHeaders,
    setIsOpen,
    setDestinationNumber,
    setCallId,
    setContactId,
    setCampaignId
  })(AgentCampaignLeads)
);
