import React, { Component } from 'react';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import Refresh from '../../../assets/images/refreshWhite.png';
import { GET } from '../../../services/HttpRequests';
import { debounce } from '../../../utility/utility';
import Popup from '../../../common/component/popups/popups';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_REASSIGN_NUMBER_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
import './Tooltip.css';
const userUrl = process.env.REACT_APP_USER_API;
const autoAttendantUrl = process.env.REACT_APP_AUTO_ATTENDED_API;
class Reassign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      users: [],
      searchValue: '',
      groups: props.groups,
      reassignPopup: this.props?.numberDetails?.members?.length ? true : false,
      autoAttendantAgents: [],
      showGroups: props.groups && props.groups.length > 0 ? true : false,
      showAgents: false,
      showAutoAttendants: false,
    };
  }

  deBouncedHandle = debounce(async () => {
    let result = [];
    let filteredAutoAttendant = [];
    if (this.state.searchValue) {
      result = this.state.groups.filter((group) =>
        group.name.toLowerCase().includes(this.state.searchValue.toLowerCase())
      );
      filteredAutoAttendant = this.state.autoAttendantAgents.filter((group) =>
        group.name.toLowerCase().includes(this.state.searchValue.toLowerCase())
      );
    } else {
      result = this.props.groups;
      filteredAutoAttendant = this.state.autoAttendantAgents;
    }
    try {
      let orgId = localStorage.getItem('doosra-biz-organisation-id');
      const res = await GET(userUrl + `v1/user/search`, {
        search: this.state.searchValue,
        organisationId: orgId,
      });
      const users = res.data.response.users;
      this.setState({
        users: users,
        groups: result,
        autoAttendantAgents: filteredAutoAttendant,
        showGroups: this?.state?.searchValue ? true : false,
        showAgents: this?.state?.searchValue ? true : false,
        showAutoAttendants: this?.state?.searchValue ? true : false,
      });
    } catch (err) {
      console.log(err);
    }
  }, 500);

  openPopup = async () => {
    if (this.props.nonIncomingDid) {
      return;
    }
    logPostHogEvent(ADMIN_REASSIGN_NUMBER_BUTTON);
    const { users } = this.state;
    if (users.length !== 0) {
      this.setState({ popupOpen: true });
      return;
    }

    try {
      const orgId = localStorage.getItem('doosra-biz-organisation-id');
      const res = await GET(userUrl + `v1/user/search`, {
        organisationId: orgId,
      });
      const usersData = res.data.response.users;
      const autoAttendantCall = await GET(
        autoAttendantUrl + `v1/auto-attendant`,
        {
          organisationId: orgId,
        }
      );
      const autoAttendantAgents =
        autoAttendantCall.data.response.autoAttendants;
      this.setState({
        popupOpen: true,
        users: usersData,
        autoAttendantAgents: autoAttendantAgents,
      });
    } catch (err) {
      console.log(err);
    }
  };

  closePopup = () => {
    this.setState({
      popupOpen: false,
      users: [],
      searchValue: '',
      reassignPopup: false,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.deBouncedHandle();
  };

  reassignUserAndClosePopup = (id, type) => {
    if (type === 'user') {
      this.props.reassignUser(id);
    } else if (type === 'group') {
      this.props.reassignGroup(id);
    } else if (type === 'autoAttendant') {
      this.props.reassignAutoAttendant(id);
    }
    this.closePopup();
  };

  onConfirmReassign = () => {
    this.setState({
      reassignPopup: false,
    });
  };

  toggleGroupList = () => {
    this.setState({ showGroups: !this.state.showGroups });
  }

  toggleAgentList = () => {
    this.setState({ showAgents: !this.state.showAgents });
  }

  toggleAutoAttendantList = () => {
    this.setState({ showAutoAttendants: !this.state.showAutoAttendants });
  };

  render() {
    const { reassignPopup } = this.state;

    let usersList = this.state.users.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#42a1e2',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          <button
            className="ButtonMidSize"
            onClick={() => this.reassignUserAndClosePopup(item.user_id, 'user')}
          >
            ASSIGN
          </button>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));

    let autoAttendantList = this.state.autoAttendantAgents.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#42a1e2',
              margin: 'auto 0',
            }}
          >
            {item.name}
          </p>
          <button
            className="ButtonMidSize"
            onClick={() =>
              this.reassignUserAndClosePopup(item._id, 'autoAttendant')
            }
          >
            ASSIGN
          </button>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));

    let groupList = this.state.groups.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#42a1e2',
              margin: 'auto 0',
            }}
          >
            {item.name}
          </p>
          <button
            className="ButtonMidSize"
            onClick={() => this.reassignUserAndClosePopup(item._id, 'group')}
          >
            ASSIGN
          </button>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));

    let popper = reassignPopup ? (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Reassign?</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            {this.props.numberDetails.members.length &&
              this.props.numberDetails.user_id ? (
              <>
                <p className="PopupBodyTitle" style={{ padding: '0 50px' }}>
                  {`The agent associated with this number is part of a call routing group(s). Do you still want to proceed with reassigning this number?`}
                </p>
                <p className="PopupNote" style={{ padding: '0 50px' }}>
                  {`If reassigned, the associated agent shall be removed from the routing group(s) and this might affect the call routing flow.`}
                </p>
              </>
            ) : (
              <>
                <p className="PopupBodyTitle" style={{ padding: '0 50px' }}>
                  {`Are you sure you want to unassign the ${this.props.numberDetails.routing_group_id
                    ? 'group'
                    : 'agent'
                    } from this number?`}
                </p>
                <p className="PopupNote" style={{ padding: '0 50px' }}>
                  You can still receive and view OTPs, SMSs from this number in
                  the dashboard even after it has been unassigned.
                </p>
              </>
            )}
            <button
              className="ButtonFullWidth BgYellow"
              onClick={this.onConfirmReassign}
            >
              REASSIGN
            </button>
          </div>
        </div>
      </Popup>
    ) : (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Reassign to {this.props.NumToAssign}</p>
            <img className="PopupClose" src={Close} onClick={this.closePopup} />
          </div>
          <div className="PopupBody">
            <div style={{ display: 'flex', textAlign: 'center' }}>
              <input
                placeholder="Search Agent/Group"
                className="PopupSearchInput"
                style={{ width: '100%' }}
                id="searchValue"
                onChange={this.onChange}
                value={this.state.searchValue}
              />
            </div>
            <div className="PopupColumnHeaders">
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingLeft: '20px',
                }}
              >
                AVAILABLE GROUPS
                <span onClick={this.toggleGroupList} className="PopupListShowHideText">{this.state.showGroups ? 'Hide' : 'Show'}</span>
              </p>
              <p
                style={{
                  margin: '10px',
                  textAlign: 'right',
                  paddingRight: '10px',
                }}
              >
                ACTION
              </p>
            </div>
            <div style={{ maxHeight: '150px', overflow: 'auto' }}>
              {this.state.showGroups && groupList}
            </div>
          </div>
          <div className="PopupBody">
            <div className="PopupColumnHeaders">
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingLeft: '20px',
                }}
              >
                AVAILABLE AGENTS
                <span onClick={this.toggleAgentList} className="PopupListShowHideText">{this.state.showAgents ? 'Hide' : 'Show'}</span>
              </p>
              <p
                style={{
                  margin: '10px',
                  textAlign: 'right',
                  paddingRight: '10px',
                }}
              >
                ACTION
              </p>
            </div>
            <div style={{ maxHeight: '150px', overflow: 'auto' }}>
              {this.state.showAgents && usersList}
            </div>
          </div>
          <div className="PopupBody">
            <div className="PopupColumnHeaders">
              <p
                style={{
                  margin: '10px',
                  textAlign: 'left',
                  paddingLeft: '20px',
                }}
              >
                AUTO ATTENDANT
                <span onClick={this.toggleAutoAttendantList} className="PopupListShowHideText">{this.state.showAutoAttendants ? 'Hide' : 'Show'}</span>
              </p>
              <p
                style={{
                  margin: '10px',
                  textAlign: 'right',
                  paddingRight: '10px',
                }}
              >
                ACTION
              </p>
            </div>
            <div style={{ maxHeight: '150px', overflow: 'auto' }}>
              {this.state.showAutoAttendants && autoAttendantList}
            </div>
          </div>
        </div>
      </Popup>
    );
    const isIvrNumber = this?.props?.numberDetails?.ivr_id || false;
    return (
      <span>
        {/* {!this?.props?.numberDetails?.ivr_id && */}
        <div className="tooltip-container">
          <div className="tooltip-button">
            <button onClick={this.openPopup} className={this.props.nonIncomingDid || isIvrNumber || !isSuperAdminWithPermission('EDIT') ? "DisableButton" : "ReassignButton"} disabled={isIvrNumber || !isSuperAdminWithPermission('EDIT')}>
              <img alt="" src={Refresh} style={{ paddingRight: '10px' }} />
              RE-ASSIGN
            </button>
          </div>
          {this.props.nonIncomingDid && <div className="tooltip-text left" data-tip-position="left">
            For outgoing calls only
            < span className="arrow-right"></span>
          </div>}
        </div>
        {/* } */}
        {popper}
      </span>
    );
  }
}

export default Reassign;
