import React, { useCallback } from "react";
import { Handle, Position } from "reactflow";
import Cancel from "../../assets/images/cancel.png";
import Audio from "../../assets/images/audio_file.png";
import { SET_SIDEBAR_OPEN } from "../../actions/callFlowActions";
import { useDispatch } from 'react-redux';
import Settings from "../../assets/images/settings.png"

const handleStyle = { left: 10 };

const PlayAudio = ({ id, data, onDelete }) => {
  const dispatch = useDispatch();
 return (
    <>
      <Handle type="target" position={Position.Top} />
      <div style={{ minHeight: '104px', minWidth: "248px", backgroundColor: "#F3FBF5",  borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>

        <div style={{ minHeight: '52px', minWidth: "170px", backgroundColor: '#2357890D', display: "flex", justifyContent: "space-between",  alignItems: "center" }}>
            <div style={{marginLeft: 10 }}>
            <img style={{ width: "20px", height: "20px",  }} src={Audio} /> 
            <text style={{ fontSize: "20px", color: "#235789", marginLeft: 10, fontWeight: "600" }}>Play audio</text>
            </div>
            <div>
              <img onClick={() => {
                dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: true, menuName: "AUDIO", id, data: data?.label  }})
              }} style={{ width: "20px", height: "20px", marginRight: 10 }} src={Settings} />
              <img onClick={() => onDelete(id)} style={{ width: "20px", height: "20px", marginRight: 10 }} src={Cancel} />
            </div>
        </div>
        <div onClick={() => {
          dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: true, menuName: "AUDIO", id, data: data?.label  }})
        }} style={{ minHeight: '52px', minWidth: "248px", backgroundColor: 'white', justifyContent: 'center', display: 'flex', alignItems: "center" }}> 
            <text style={{ fontSize: "16px", fontWeight: '500', color: data?.label ? "black" : "#9B9B9B" }}>{ data?.label || "Select an audio file"}</text>
        </div>
        
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
    </>
 )

};

export default PlayAudio;