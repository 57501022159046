import React from 'react';
import { CallIcon, OutgoingCallIcon } from '../../../assets/Icons/Icons';
import Popup from './../popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { useState } from 'react';
import '../../../assets/css/NumberDialer.css';
import { GET, POST } from '../../../services/HttpRequests';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { initCall } from '../../../actions/sidebarActions';
import { toast } from 'react-toastify';
import Alert from '../../../assets/images/notification-14158.png';
import { logPostHogEvent } from "../../posthog/posthog";
import { PLACE_AN_OUTGOING_CALL_POPUP_BUTTON, OUTGOING_CALL_BUTTON } from "../../posthog/events";
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;

function NumberDialer({ initCallPopup, dial, onlyIcon, phone_number,
  isOutgoingCallsDowntime, receiverNo, contactId, openPopup, closePopup }) {

  const orgId = localStorage.getItem('doosra-biz-organisation-id');
  const data = initCallPopup || {};
  const [numbers, setNumbers] = useState([]);
  const [open, setOpen] = useState(openPopup);
  const [receiverNumber, setReceiverNumber] = useState(receiverNo);
  const isFreeTrail = localStorage.getItem('is_free_trial_enabled');

  const setValue = (field) => (e) => dial({ ...data, [field]: e.target.value });
  const openDialer = () => {
    logPostHogEvent(PLACE_AN_OUTGOING_CALL_POPUP_BUTTON);
    dial({ number: localStorage.getItem('admin-contact-number') });
    setOpen(true);
  }
  const closeDialer = () => {
    dial(undefined);
    setOpen(false);
    if (closePopup)
      closePopup(false);
  }
  const initCall = () => {
    logPostHogEvent(OUTGOING_CALL_BUTTON);
    console.log({
      ...initCallPopup,
      organisationId: orgId,
      contactId
    })
    POST(`${outgoingCallsUrl}v1/organisations/outbound-call/init`, {
      ...initCallPopup,
      organisationId: orgId,
      contactId
    })
      .then((res) =>
        toast.success(`Your call is being connected to ${initCallPopup.number}`)
      )
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.error?.reason);
      })
      .finally((res) => dial());
  };
  useEffect(() => {
    console.log("useEffect", receiverNumber,);
    if (receiverNumber) {
      dial({ number: receiverNumber });
    }
    GET(orgUrl + `v1/organisation/did-numbers`, {
      pageNo: 0,
      pageSize: 1000,
      organisation_id: orgId,
    })
      .then((res) => res.data.response)
      .then((res) => setNumbers(res.items))
      .catch((err) => console.log(err));
    data.number = localStorage.getItem('admin-contact-number');
  }, []);

  return (
    <>
      <button className="NumberDialerButton" onClick={openDialer}>
        {onlyIcon ? null : (
          <span className="NumberDialerText">Place an outgoing call</span>
        )}
        <OutgoingCallIcon className="NumberDialerIcon" />
      </button>
      <Popup open={open} onClose={closeDialer}>
        <div className="PopupContainer">
          <div className="PopupBody">
            <img className="PopupClose" src={Close} onClick={closeDialer} />
            <div className="Dialer-container">
              <div className="Dialer-title">
                <div className="Dialer-icon">
                  <CallIcon />
                </div>
                <div className="Dialer-title-text">Make an outgoing call</div>
              </div>

            </div>
            <div className="Dialer-inputs">
              {receiverNo ? (
                <input
                  id="name"
                  type="text"
                  disabled={true}
                  className="PopupInput"
                  value={data["number"]}
                  onChange={setValue("number")}
                />) : (<input
                  id="name"
                  type="number"
                  className="PopupInput"
                  value={data["number"] || ""}
                  onChange={setValue("number")}
                />)}
              <div style={{ height: 16 }} />
              <select
                id="name"
                className="PopupInput"
                onChange={setValue("didId")}
                value={data["didId"] || ""}
              >
                <option>Choose an outgoing number</option>
                {
                  numbers.map(
                    number => <option key={number._id} value={number._id}>{number.virtualNumber}</option>
                  )
                }
              </select>
            </div>
            <div className="Dialer-action">
              <button className={`ButtonFullWidth BgGreen`} onClick={initCall}>
                <CallIcon style={{ height: "18px", width: "40px" }} />
                Call
              </button>
            </div>
            <div className="Dialer-info-text" style={{ display: 'flex' }}>
              {isOutgoingCallsDowntime ?
                <>  <img
                  alt=""
                  src={Alert}
                  style={{
                    paddingRight: "5px",
                    width: '25px',
                    height: '18px',
                  }}
                />
                  Call services are facing temporary downtime. We value your understanding and are actively working to restore normal service promptly.
                </>
                :
                `You will receive a call to your registered number ${phone_number}, answer that call to begin`
              }
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}

const mapStateToProps = (state) => ({
  initCallPopup: state.sidebar.initCallPopup,
  phone_number: state.topbar.phone_number,
  onlyIcon: !state.sidebar.sidebarClosed,
  isOutgoingCallsDowntime: state.topbar.isOutgoingCallsDowntime,
});

export default connect(mapStateToProps, { dial: initCall })(NumberDialer);
