import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PostHogProvider} from 'posthog-js/react';

const posthogHost = "https://app.posthog.com";
const posthogKey = "phc_wD7I8JIA680ebRDQuOHy6NLLJB5eUIDmIOUGHGsKaCe";

const options = {
  api_host: posthogHost,
}


ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={posthogKey}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
