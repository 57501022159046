export const PROD_INHOUSE_PBX_SERVERS = ["pbx1.notring.com", "pbx2.notring.com", "pbx3.notring.com", "pbx4.alohaa.ai"];
export const RECOVERY_INHOUSE_PBX_SERVERS = ["pbx1.notring.com", "pbx2.notring.com"];
export const DEV_INHOUSE_PBX_SERVERS = ["pbx1.dev.notring.com"];
export const TURN_SERVER_CONFIG = {
    "pbx1.dev.notring.com": { urls: ["turn:36.255.252.90:3478"], username: "doosra", credential: "dmlqdHVybnNlcnZlcgo=" },
    "pbx1.notring.com": { urls: ["turn:36.255.254.242:3478"], username: "doosra", credential: "dmlqcHJvZHR1cm5kYwo" },
    "pbx2.notring.com": { urls: ["turn:103.25.164.66:3478"], username: "doosra", credential: "YWhtZHByb2R0dXJuZGMK" },
    "pbx3.notring.com": { urls: ["turn:103.210.72.194:3478"], username: "doosra", credential: "cHJvZHR1cm5kY3ZpajMK" },
    "pbx4.alohaa.ai": { urls: ["turn:36.255.253.162:3478"], username: "doosra", credential:"dmlqcHJvZHR1cm5kYwopbx4" }
};
// export const TURN_SERVER_CONFIG = {
//     "pbx1.dev.notring.com": {urls: ["turn:3.110.129.172:80"],username: "doosra",  credential:"ZGV2LXR1cm4tZXhwCg"},
//     "pbx1.notring.com": {urls: ["turn:3.110.129.172:80"],username: "doosra",  credential:"ZGV2LXR1cm4tZXhwCg"},
//     "pbx2.notring.com": {urls: ["turn:3.110.129.172:80"],username: "doosra",  credential:"ZGV2LXR1cm4tZXhwCg"}
// };
// export const TURN_SERVER_CONFIG = {urls: ["turn:3.110.129.172:80"],username: "doosra",  credential:"ZGV2LXR1cm4tZXhwCg"};
