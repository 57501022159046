import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';
import '../../../assets/css/Content.css';
import { GET, PUT } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import UnbindPopup from '../component/unbindPopUp';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const numberMaskingUrl = process.env.REACT_APP_NUMBER_MASKING_API;



class NumberMaskingBindingsPage extends Component {
  constructor() {
    super();
    this.state = {
      count: 0,
      didsCount: 0,
      bindingsCount: 0,
      callsCount: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      bindingList: [],
      loading: false,
      searchData: {},
    };
  }

  componentDidMount() {
    this.getBindings();
    this.getDidsCount();
    this.getCallsCount();
  }

  redirectToCallLogs = (isFreeFormEnabled) => {
    if (isFreeFormEnabled) {
      return <Redirect
        to={{
          pathname: '/number-masking-call-logs',
        }}
      />
    }
  }
  getBindings = async () => {
    this.setState({ loading: true });
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };

    try {
      const res = await this.getBindingsData(numberMaskingUrl, data);
      this.updateBindingListState(res);
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  seachHandler = async (data) => {
    data.pageNo = 1;
    data.pageSize = this.state.pageSize;
    data.organisation_id = localStorage.getItem('doosra-biz-organisation-id');

    try {
      const res = await this.getBindingsData(numberMaskingUrl, data);
      this.updateBindingListState(res, data);
    } catch (err) {
      if (err) {
        this.setState({ loading: false });
      }
    }
  };


  fetchPageData = async (value) => {
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    data = {
      ...this.state.searchData,
      ...data,
    };

    try {
      const res = await this.getBindingsData(numberMaskingUrl, data);
      this.updateBindingListState(res, data);
      this.setState({ currentPage: value });
    } catch (err) {
      console.log(err);
    }
  };

  unbindHandler = (item) => {
    const data = {
      caller_number: item.caller_primary_number,
      receiver_number: item.receiver_primary_number,
      binding_type: this.getBindingType(item),
      pin_type: this.getPinType(item),
      organisation_id: localStorage.getItem('doosra-biz-organisation-id')
    };
    PUT(`${numberMaskingUrl}v1/organisation/unbind`, {}, data)
      .then((res) => {
        if (res.data.success === true) {
          toast.success(`Unbind successful`);
          this.fetchPageData(1);
        }
      })
      .catch((err) => err?.response?.data?.success === false ? toast.error(`unbind failed because of ${err?.response?.data?.error?.reason}`) : "")
  }

  getBindingsData = async (url, data) => {
    const response = await GET(`${url}v1/bindings`, data);
    return response.data.response;
  };

  updateBindingListState = (res, searchData = {}) => {
    let count = Math.ceil(res.payload.count / this.state.pageSize);
    if (count === 0) count = 1;

    this.setState({
      bindingsCount: res.payload.count,
      bindingList: res.payload.docs,
      totalPages: count,
      pageSize: res.pageSize,
      loading: false,
      searchData,
    });
  };

  getDidsCount = () => {
    GET(`${numberMaskingUrl}v1/did-list`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ didsCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getCallsCount = () => {
    GET(`${numberMaskingUrl}v1/calls`, {
      pageNo: 0,
      pageSize: 1,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ callsCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getBindingType = (binding) => {
    const bindingTypes = {
      "is_closed_binding": "BOTH_SIDES_CLOSED",
      "is_side_a_open": "SIDE_A_OPEN",
      "is_side_b_open": "SIDE_B_OPEN"
    };
    const result = Object.keys(bindingTypes).filter((i) => binding[i] === true);
    let res = "";
    if (result.length > 1) {
      res = "BOTH_SIDES_OPEN"
    } else {
      res = bindingTypes[result[0]]
    }
    return res;
  }

  getPinType = (binding) => {
    const bindingTypes = { "side_a_pin_enter": "SIDE_A_ENTERS", "side_b_pin_enter": "SIDE_B_ENTERS" };
    const result = Object.keys(bindingTypes).filter((i) => binding[i] === true);
    let res = "";
    if (result.length === 0) {
      res = "NO_PIN"
    } else if (result.length === 1) {
      res = bindingTypes[result[0]]
    } else {
      res = "BOTH_SIDE_ENTERS"
    }
    return res;
  }

  render() {
    const { bindingList, count, loading, currentPage, totalPages } = this.state;
    const userRole = localStorage.getItem('doosra-biz-user-role');
    const { callForwardingStatus, isNumberMaskingEnabled, isFreeFormEnabled } = this.props;

    const tabs = this.createTabs(userRole, callForwardingStatus, isFreeFormEnabled);

    if (!isNumberMaskingEnabled) {
      return (
        <div className={`${this.props.className}`}>
          <Sidebar />
          <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
            <Topbar />
            <div className="ContentContainer">
              <UpgradeServices
                icon="numberMasking"
                heading="Number Masking"
                text="Receive and read SMSes right from your Alohaa dashboard"
                emailSent={this.props.isNumberMaskingEmailSent}
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`${this.props.className}`}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            < TabSearchBar tabs={tabs} >
              <SearchBar
                type='number-masking-bindings'
                disableTitle
                isFreeFormEnabled={isFreeFormEnabled}
                count={count}
                reset={this.getBindings}
                search={this.seachHandler}
                reloadBindings={this.fetchPageData}
              />
            </TabSearchBar>
            {this.redirectToCallLogs(isFreeFormEnabled)}
            {!isFreeFormEnabled &&
              <div style={{ overflowX: "auto" }}>
                <DataTable.Table style={{ width: "1500px" }}>
                  {loading && <PreLoader />}
                  <DataTable.Head>{this.renderTableHeaders()}</DataTable.Head>
                  <DataTable.Body>
                    {this.renderTableRows(bindingList, userRole)}
                  </DataTable.Body>
                </DataTable.Table>
              </div>
            }

            <Paginator
              currentPage={currentPage}
              lastPage={totalPages}
              getInfo={this.fetchPageData}
            />
          </div>
        </div>
      </div >
    );
  }

  createTabs(userRole, callForwardingStatus, isFreeFormEnabled) {
    const tabs = [];
    console.log("the bindings count is", this.state.bindingsCount);
    if (!isFreeFormEnabled) {
      tabs.push({
        title: 'Bindings',
        subtitle: `${this.state.bindingsCount}`,
        active: true
      });
    }
    tabs.push({
      title: 'Calls',
      subtitle: `${this.state.callsCount}`,
      onClick: () => this.props.history.push('/number-masking-call-logs'),
      active: isFreeFormEnabled,
    });
    tabs.push({
      title: 'Virtual Nos',
      subtitle: `${this.state.didsCount}`,
      onClick: () => this.props.history.push('/number-masking-did'),
    });
    return tabs;
  }

  renderTableHeaders() {
    return (
      <>
        <DataTable.CellHead >CALLER NUMBER</DataTable.CellHead>
        <DataTable.CellHead >RECEIVER NUMBER</DataTable.CellHead>
        <DataTable.CellHead >EXPIRATION</DataTable.CellHead>
        <DataTable.CellHead >EXPIRE STATUS</DataTable.CellHead>
        <DataTable.CellHead >BINDING TYPE</DataTable.CellHead>
        <DataTable.CellHead >PIN TYPE</DataTable.CellHead>
        <DataTable.CellHead >PASSCODE</DataTable.CellHead>
        <DataTable.CellHead >BINDING STATUS</DataTable.CellHead>
        <DataTable.CellHead >TWO WAY BINDING</DataTable.CellHead>
        <DataTable.CellHead >MASKED NUMBER</DataTable.CellHead>
        <DataTable.CellHead >CREATION DATE</DataTable.CellHead>
        <DataTable.CellHead >CREATION TIME</DataTable.CellHead>
        <DataTable.CellHead >ACTIONS</DataTable.CellHead>
      </>
    );
  }

  renderTableRows(bindingList, userRole) {
    return (bindingList || []).map((item) => (
      <DataTable.Row>
        <DataTable.Cell>
          {item?.caller_primary_number}
        </DataTable.Cell>
        <DataTable.Cell>
          {item?.receiver_primary_number}
        </DataTable.Cell>
        <DataTable.Cell>
          {item?.valid_till && moment(item?.valid_till).format('DD-MM-YYYY HH:mm:ss')}
        </DataTable.Cell>
        <DataTable.Cell>
          {item?.expiry_status}
        </DataTable.Cell>
        <DataTable.Cell>
          {this.getBindingType(item)}
        </DataTable.Cell>
        <DataTable.Cell>{this?.getPinType(item)}</DataTable.Cell>
        <DataTable.Cell>{item?.passcode}</DataTable.Cell>
        <DataTable.Cell>{item?.is_enabled ? "Binded" : "Unbinded"}</DataTable.Cell>
        <DataTable.Cell>{`${item?.is_two_way}`}</DataTable.Cell>
        <DataTable.Cell style={{ "color": "blue" }}>{`0${item?.did?.slice(-10)}`}</DataTable.Cell>
        <DataTable.Cell>{item?.created_at && moment(item?.created_at).format('DD-MM-YYYY')}</DataTable.Cell>
        <DataTable.Cell>{item?.created_at && moment(item?.created_at).format('HH:mm:ss')}</DataTable.Cell>
        <DataTable.Cell>
          <UnbindPopup
            key={item?._id}
            isEnabled={item?.is_enabled}
            unbindHandler={this.unbindHandler}
            item={item}
          />
        </DataTable.Cell>
      </DataTable.Row >
    ));
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  isNumberMaskingEmailSent: state.topbar.isNumberMaskingEmailSent,
  isFreeFormEnabled: state.topbar.isFreeFormEnabled,
});

export default commonStyle(
  connect(mapStateToProps, {})(NumberMaskingBindingsPage)
);
